import { RegPrefix, IRegion, EndPointType } from '@/bridge/types/RegionTypes';

export const DEFAULT_METRIC_PUBLISH_ENDPOINT =
  'https://skylight-client-ds-beta-iad.iad.proxy.amazon.com';

export const DEFAULT_CRASH_PUBLISH_ENDPOINT =
  'https://det-ta-g7g.amazon.com/DETLogServlet';
export const DefaultMarketplaceId = 'ATVPDKIKX0DER';

export class RegionConstants {
  static readonly PREFIX_TO_REGION_MAP = new Map<RegPrefix, IRegion>([
    [
      'sltest',
      {
        marketplaceId: DefaultMarketplaceId,
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'test.iad',
        code: 'iad',
        awsRegionCode: 'us-east-1',
        webClientEndpoint:
          'https://us-east-1.webclient-beta.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service-beta-iad.iad.proxy.amazon.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service-beta-iad.iad.proxy.amazon.com',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service-gamma.us-west-2.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service-gamma.us-west-2.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds-beta-iad.iad.proxy.amazon.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds-beta-iad.iad.proxy.amazon.com',
          },
        ],
        localeKey: 'ws-region-name-iad',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-iad.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-workspaces.us-east-1.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: true,
        isSoloSupported: true,
      },
    ],
    [
      'slbeta',
      {
        marketplaceId: DefaultMarketplaceId,
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'beta.iad',
        code: 'iad',
        awsRegionCode: 'us-east-1',
        webClientEndpoint:
          'https://us-east-1.webclient-beta.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service-beta-iad.iad.proxy.amazon.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service-beta-iad.iad.proxy.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service-beta.us-east-1.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service-beta.us-east-1.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds-beta-iad.iad.proxy.amazon.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds-beta-iad.iad.proxy.api.aws',
          },
        ],
        localeKey: 'ws-region-name-iad',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-iad.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-beta-workspaces.us-east-1.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: true,
        isSoloSupported: true,
      },
    ],
    [
      'slbeta_pl',
      {
        marketplaceId: DefaultMarketplaceId,
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'beta.iad',
        code: 'iad',
        awsRegionCode: 'us-east-1',
        webClientEndpoint:
          'https://us-east-1.webclient-beta.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service-beta-iad.iad.proxy.amazon.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service-beta-iad.iad.proxy.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service-beta.us-east-1.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service-beta.us-east-1.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds-beta-iad.iad.proxy.amazon.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds-beta-iad.iad.proxy.api.aws',
          },
        ],
        localeKey: 'ws-region-name-iad',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-iad.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-beta-workspaces.us-east-1.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: true,
        isSoloSupported: true,
      },
    ],
    [
      'frbeta',
      {
        marketplaceId: DefaultMarketplaceId,
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'fips.beta.iad',
        code: 'iad',
        awsRegionCode: 'us-east-1',
        webClientEndpoint:
          'https://us-east-1.webclient-beta.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-fips-service-beta-iad.iad.proxy.amazon.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service-beta-iad.iad.proxy.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service-beta.us-east-1.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service-beta.us-east-1.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds-beta-iad.iad.proxy.amazon.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds-beta-iad.iad.proxy.api.aws',
          },
        ],
        localeKey: 'ws-region-name-iad',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-iad.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-beta-workspaces.us-east-1.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: true,
        isSoloSupported: true,
      },
    ],
    [
      'sliad',
      {
        marketplaceId: DefaultMarketplaceId,
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'prod.iad',
        code: 'iad',
        awsRegionCode: 'us-east-1',
        webClientEndpoint: 'https://us-east-1.webclient.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service.us-east-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service.us-east-1.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service.us-east-1.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service.us-east-1.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds.us-east-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds.us-east-1.api.aws',
          },
        ],
        localeKey: 'ws-region-name-iad',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-iad.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-workspaces.us-east-1.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: true,
        isSoloSupported: true,
      },
    ],
    [
      'friad',
      {
        marketplaceId: DefaultMarketplaceId,
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'fips.prod.iad',
        code: 'iad',
        awsRegionCode: 'us-east-1',
        webClientEndpoint: 'https://us-east-1.webclient.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service-fips.us-east-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service-fips.us-east-1.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service.us-east-1.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service.us-east-1.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds.us-east-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds.us-east-1.api.aws',
          },
        ],
        localeKey: 'ws-region-name-iad',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-iad.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-workspaces.us-east-1.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: true,
        isSoloSupported: true,
      },
    ],
    [
      'wsgamma',
      {
        marketplaceId: DefaultMarketplaceId,
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'gamma.pdx',
        code: 'pdx',
        awsRegionCode: 'us-west-2',
        webClientEndpoint:
          'https://us-west-2.webclient-gamma.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service-gamma.us-west-2.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service-gamma.us-west-2.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service-gamma.us-west-2.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service-gamma.us-west-2.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds-gamma.us-west-2.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds-gamma.us-west-2.api.aws',
          },
        ],
        localeKey: 'ws-region-name-pdx',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-pdx.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-gamma-workspaces.us-west-2.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: true,
        isSoloSupported: true,
      },
    ],
    [
      'wsgamma_pl',
      {
        marketplaceId: DefaultMarketplaceId,
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'gamma.pdx',
        code: 'pdx',
        awsRegionCode: 'us-west-2',
        webClientEndpoint:
          'https://us-west-2.webclient-gamma.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service-gamma.us-west-2.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service-gamma.us-west-2.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service-gamma.us-west-2.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service-gamma.us-west-2.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds-gamma.us-west-2.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds-gamma.us-west-2.api.aws',
          },
        ],
        localeKey: 'ws-region-name-pdx',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-pdx.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-gamma-workspaces.us-west-2.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: true,
        isSoloSupported: true,
      },
    ],
    [
      'frgamma',
      {
        marketplaceId: DefaultMarketplaceId,
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'fips.gamma.pdx',
        code: 'pdx',
        awsRegionCode: 'us-west-2',
        webClientEndpoint:
          'https://us-west-2.webclient-gamma.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service-fips-gamma.us-west-2.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service-fips-gamma.us-west-2.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service-gamma.us-west-2.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service-gamma.us-west-2.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds-gamma.us-west-2.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds-gamma.us-west-2.api.aws',
          },
        ],
        localeKey: 'ws-region-name-pdx',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-pdx.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-gamma-workspaces.us-west-2.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: true,
        isSoloSupported: true,
      },
    ],
    [
      'wspdx',
      {
        marketplaceId: DefaultMarketplaceId,
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'prod.pdx',
        code: 'pdx',
        awsRegionCode: 'us-west-2',
        webClientEndpoint: 'https://us-west-2.webclient.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service.us-west-2.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service.us-west-2.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service.us-west-2.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service.us-west-2.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds.us-west-2.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds.us-west-2.api.aws',
          },
        ],
        localeKey: 'ws-region-name-pdx',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-pdx.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-workspaces.us-west-2.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: true,
        isSoloSupported: true,
      },
    ],
    [
      'wspdx_pl',
      {
        marketplaceId: DefaultMarketplaceId,
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'prod.pdx',
        code: 'pdx',
        awsRegionCode: 'us-west-2',
        webClientEndpoint: 'https://us-west-2.webclient.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service.us-west-2.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service.us-west-2.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service.us-west-2.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service.us-west-2.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds.us-west-2.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds.us-west-2.api.aws',
          },
        ],
        localeKey: 'ws-region-name-pdx',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-pdx.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-workspaces.us-west-2.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: true,
        isSoloSupported: true,
      },
    ],
    [
      'frpdx',
      {
        marketplaceId: DefaultMarketplaceId,
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'fips.prod.pdx',
        code: 'pdx',
        awsRegionCode: 'us-west-2',
        webClientEndpoint: 'https://us-west-2.webclient.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service-fips.us-west-2.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service-fips.us-west-2.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service.us-west-2.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service.us-west-2.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds.us-west-2.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds.us-west-2.api.aws',
          },
        ],
        localeKey: 'ws-region-name-pdx',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-pdx.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-workspaces.us-west-2.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: true,
        isSoloSupported: true,
      },
    ],
    [
      'wsdub',
      {
        marketplaceId: 'A2NODRKZP88ZB9',
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'prod.dub',
        code: 'dub',
        awsRegionCode: 'eu-west-1',
        webClientEndpoint: 'https://eu-west-1.webclient.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service.eu-west-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service.eu-west-1.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service.eu-west-1.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service.eu-west-1.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds.eu-west-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds.eu-west-1.api.aws',
          },
        ],
        localeKey: 'ws-region-name-dub',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-dub.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-workspaces.eu-west-1.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: true,
        isSoloSupported: true,
      },
    ],
    [
      'wsdub_pl',
      {
        marketplaceId: 'A2NODRKZP88ZB9',
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'prod.dub',
        code: 'dub',
        awsRegionCode: 'eu-west-1',
        webClientEndpoint: 'https://eu-west-1.webclient.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service.eu-west-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service.eu-west-1.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service.eu-west-1.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service.eu-west-1.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds.eu-west-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds.eu-west-1.api.aws',
          },
        ],
        localeKey: 'ws-region-name-dub',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-dub.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-workspaces.eu-west-1.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: true,
        isSoloSupported: true,
      },
    ],
    [
      'wssyd',
      {
        marketplaceId: 'A39IBJ37TRP1C6',
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'prod.syd',
        code: 'syd',
        awsRegionCode: 'ap-southeast-2',
        webClientEndpoint:
          'https://ap-southeast-2.webclient.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service.ap-southeast-2.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service.ap-southeast-2.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service.ap-southeast-2.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service.ap-southeast-2.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds.ap-southeast-2.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds.ap-southeast-2.api.aws',
          },
        ],
        localeKey: 'ws-region-name-syd',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-syd.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-workspaces.ap-southeast-2.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: true,
        isSoloSupported: true,
      },
    ],
    [
      'wsnrt',
      {
        marketplaceId: 'A1VC38T7YXB528',
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'prod.nrt',
        code: 'nrt',
        awsRegionCode: 'ap-northeast-1',
        webClientEndpoint:
          'https://ap-northeast-1.webclient.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service.ap-northeast-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service.ap-northeast-1.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service.ap-northeast-1.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service.ap-northeast-1.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds.ap-northeast-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds.ap-northeast-1.api.aws',
          },
        ],
        localeKey: 'ws-region-name-nrt',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-nrt.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-workspaces.ap-northeast-1.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: true,
        isSoloSupported: true,
      },
    ],
    [
      'wssin',
      {
        marketplaceId: 'A19VAU5U5O7RUS',
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'prod.sin',
        code: 'sin',
        awsRegionCode: 'ap-southeast-1',
        webClientEndpoint:
          'https://ap-southeast-1.webclient.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service.ap-southeast-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service.ap-southeast-1.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service.ap-southeast-1.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service.ap-southeast-1.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds.ap-southeast-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds.ap-southeast-1.api.aws',
          },
        ],
        localeKey: 'ws-region-name-sin',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-sin.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-workspaces.ap-southeast-1.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: true,
        isSoloSupported: true,
      },
    ],
    [
      'wsfra',
      {
        marketplaceId: 'A13V1IB3VIYZZH',
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'prod.fra',
        code: 'fra',
        awsRegionCode: 'eu-central-1',
        webClientEndpoint:
          'https://eu-central-1.webclient.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service.eu-central-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service.eu-central-1.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service.eu-central-1.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service.eu-central-1.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds.eu-central-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds.eu-central-1.api.aws',
          },
        ],
        localeKey: 'ws-region-name-fra',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-fra.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-workspaces.eu-central-1.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: true,
        isSoloSupported: true,
      },
    ],
    [
      'wslhr',
      {
        marketplaceId: 'A1F83G8C2ARO7P',
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'prod.lhr',
        code: 'lhr',
        awsRegionCode: 'eu-west-2',
        webClientEndpoint: 'https://eu-west-2.webclient.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service.eu-west-2.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service.eu-west-2.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service.eu-west-2.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service.eu-west-2.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds.eu-west-2.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds.eu-west-2.api.aws',
          },
        ],
        localeKey: 'ws-region-name-lhr',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-lhr.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-workspaces.eu-west-2.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: true,
        isSoloSupported: true,
      },
    ],
    [
      'wsyul',
      {
        marketplaceId: 'A2EUQ1WTGCTBG2',
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'prod.yul',
        code: 'yul',
        awsRegionCode: 'ca-central-1',
        webClientEndpoint:
          'https://ca-central-1.webclient.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service.ca-central-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service.ca-central-1.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service.ca-central-1.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service.ca-central-1.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds.ca-central-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds.ca-central-1.api.aws',
          },
        ],
        localeKey: 'ws-region-name-yul',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-yul.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-workspaces.ca-central-1.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: true,
        isSoloSupported: true,
      },
    ],
    [
      'wsgru',
      {
        marketplaceId: 'A2Q3Y263D00KWC',
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'prod.gru',
        code: 'gru',
        awsRegionCode: 'sa-east-1',
        webClientEndpoint: 'https://sa-east-1.webclient.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service.sa-east-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service.sa-east-1.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service.sa-east-1.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service.sa-east-1.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds.sa-east-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds.sa-east-1.api.aws',
          },
        ],
        localeKey: 'ws-region-name-gru',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-gru.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-workspaces.sa-east-1.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: true,
        isSoloSupported: true,
      },
    ],
    [
      'wsicn',
      {
        marketplaceId: 'A2D32KE73PNS33',
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'prod.icn',
        code: 'icn',
        awsRegionCode: 'ap-northeast-2',
        webClientEndpoint:
          'https://ap-northeast-2.webclient.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service.ap-northeast-2.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service.ap-northeast-2.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service.ap-northeast-2.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service.ap-northeast-2.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds.ap-northeast-2.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds.ap-northeast-2.api.aws',
          },
        ],
        localeKey: 'ws-region-name-icn',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-icn.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-workspaces.ap-northeast-2.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: true,
        isSoloSupported: true,
      },
    ],
    [
      'wscpt',
      {
        marketplaceId: 'AE08WJ6YKNBMC',
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'prod.cpt',
        code: 'cpt',
        awsRegionCode: 'af-south-1',
        webClientEndpoint: 'https://af-south-1.webclient.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service.af-south-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service.af-south-1.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service.af-south-1.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service.af-south-1.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds.af-south-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds.af-south-1.api.aws',
          },
        ],
        localeKey: 'ws-region-name-cpt',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-cpt.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-workspaces.af-south-1.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: true,
        isSoloSupported: true,
      },
    ],
    [
      'wspdt',
      {
        marketplaceId: DefaultMarketplaceId,
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'prod.pdt',
        code: 'pdt',
        awsRegionCode: 'us-gov-west-1',
        webClientEndpoint:
          'https://us-gov-west-1.webclient.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service.us-gov-west-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service.us-gov-west-1.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service.us-gov-west-1.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service.us-gov-west-1.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds.us-gov-west-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds.us-gov-west-1.api.aws',
          },
        ],
        localeKey: 'ws-region-name-pdt',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-pdt.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-workspaces.us-gov-west-1.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: false,
        isSoloSupported: true,
      },
    ],
    [
      'frpdt',
      {
        marketplaceId: DefaultMarketplaceId,
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'fips.prod.pdt',
        code: 'pdt',
        awsRegionCode: 'us-gov-west-1',
        webClientEndpoint:
          'https://us-gov-west-1.webclient.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service-fips.us-gov-west-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service-fips.us-gov-west-1.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service.us-gov-west-1.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service.us-gov-west-1.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds.us-gov-west-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds.us-gov-west-1.api.aws',
          },
        ],
        localeKey: 'ws-region-name-pdt',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-pdt.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-workspaces.us-gov-west-1.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: false,
        isSoloSupported: true,
      },
    ],
    [
      'wsbom',
      {
        marketplaceId: 'A21TJRUUN4KGV',
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'prod.bom',
        code: 'bom',
        awsRegionCode: 'ap-south-1',
        webClientEndpoint: 'https://ap-south-1.webclient.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service.ap-south-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service.ap-south-1.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service.ap-south-1.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service.ap-south-1.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds.ap-south-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds.ap-south-1.api.aws',
          },
        ],
        localeKey: 'ws-region-name-bom',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-bom.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-workspaces.ap-south-1.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: false,
        isSoloSupported: true,
      },
    ],
    [
      'wsosu',
      {
        marketplaceId: DefaultMarketplaceId,
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'prod.osu',
        code: 'osu',
        awsRegionCode: 'us-gov-east-1',
        webClientEndpoint:
          'https://us-gov-east-1.webclient.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service.us-gov-east-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service.us-gov-east-1.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service.us-gov-east-1.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service.us-gov-east-1.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds.us-gov-east-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds.us-gov-east-1.api.aws',
          },
        ],
        localeKey: 'ws-region-name-osu',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-osu.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-workspaces.us-gov-east-1.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: false,
        isSoloSupported: true,
      },
    ],
    [
      'frosu',
      {
        marketplaceId: DefaultMarketplaceId,
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'fips.prod.osu',
        code: 'osu',
        awsRegionCode: 'us-gov-east-1',
        webClientEndpoint:
          'https://us-gov-east-1.webclient.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service-fips.us-gov-east-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service-fips.us-gov-east-1.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service.us-gov-east-1.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service.us-gov-east-1.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds.us-gov-east-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds.us-gov-east-1.api.aws',
          },
        ],
        localeKey: 'ws-region-name-osu',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-osu.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-workspaces.us-gov-east-1.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: false,
        isSoloSupported: true,
      },
    ],
    [
      'wstlv',
      {
        marketplaceId: DefaultMarketplaceId,
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'prod.tlv',
        code: 'tlv',
        awsRegionCode: 'il-central-1',
        webClientEndpoint:
          'https://il-central-1.webclient.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service.il-central-1.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service.il-central-1.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service.il-central-1.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service.il-central-1.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds.il-central-1.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds.il-central-1.api.aws',
          },
        ],
        localeKey: 'ws-region-name-tlv',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-tlv.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-workspaces.il-central-1.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: false,
        isSoloSupported: true,
      },
    ],
    [
      'wscdg',
      {
        marketplaceId: DefaultMarketplaceId,
        crashReportServiceEndpoint: DEFAULT_CRASH_PUBLISH_ENDPOINT,
        endpoint: 'prod.cdg',
        code: 'cdg',
        awsRegionCode: 'eu-west-3',
        webClientEndpoint: 'https://eu-west-3.webclient.amazonworkspaces.com',
        wsBrokerEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-broker-service.eu-west-3.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-broker-service.eu-west-3.api.aws',
          },
        ],
        cobrandingEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://ws-client-service.eu-west-3.amazonaws.com/',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://ws-client-service.eu-west-3.api.aws',
          },
        ],
        metricEndpoint: [
          {
            type: EndPointType.IPv4,
            url: 'https://skylight-client-ds.eu-west-3.amazonaws.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'https://skylight-client-ds.eu-west-3.api.aws',
          },
        ],
        localeKey: 'ws-region-name-cdg',
        healthCheckHostname: [
          {
            type: EndPointType.IPv4,
            url: 'drp-cdg.amazonworkspaces.com',
          },
          {
            type: EndPointType.IPv6,
            url: 'drp-workspaces.eu-west-3.api.aws',
          },
        ],
        supportSAML: true,
        supportCBA: true,
        isWebPcoIPSupported: false,
        isSoloSupported: true,
      },
    ],
  ]);

  static readonly SOLO_UNSUPPORTED_REGIONS = [];

  static readonly SOLO_UNSUPPORTED_REGION_CODES = [];

  static readonly REG_CODE_BASED_REDIRECTION_BETA_SUPPORTED_REGIONS = [
    'dub',
    'iad',
    'pdx',
    'pdt',
    'osu',
  ];

  static readonly REG_CODE_BASED_REDIRECTION_GAMMA_SUPPORTED_REGIONS = [
    'iad',
    'pdt',
    'osu',
  ];

  static readonly WEB_CLIENT_BETA_HOSTNAME_SUB_STR =
    'webclient-beta.amazonworkspaces.com';

  static readonly WEB_CLIENT_BETA_GOV_HOSTNAME =
    'https://us-east-1-v2.webclient-beta.amazonworkspaces.com';

  static readonly WEB_CLIENT_GAMMA_HOSTNAME_SUB_STR =
    'webclient-gamma.amazonworkspaces.com';

  static readonly WEB_CLIENT_GAMMA_GOV_HOSTNAME =
    'https://us-gov-west-1-v2.webclient-gamma.amazonworkspaces.com';

  static readonly WEB_CLIENT_PROD_HOSTNAME_SUB_STR =
    'webclient.amazonworkspaces.com';

  static readonly IS_PROD_ENDPOINT = (currentUrl: string) => {
    return currentUrl.toLowerCase().includes('webclient.amazonworkspaces');
  };

  static readonly IS_GAMMA_ENDPOINT = (currentUrl: string) => {
    return currentUrl
      .toLowerCase()
      .includes('webclient-gamma.amazonworkspaces');
  };

  static readonly IS_BETA_ENDPOINT = (currentUrl: string) => {
    return currentUrl.toLowerCase().includes('webclient-beta.amazonworkspaces');
  };

  static readonly IS_LOCAL_TEST_ENDPOINT = (currentUrl: string) => {
    return (
      currentUrl.toLowerCase().includes('skylight.local') ||
      currentUrl.toLowerCase().includes('localhost')
    );
  };
}
