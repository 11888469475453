import { IToken } from '@/core/wsbroker/types';

export enum RunningModes {
  AUTO_STOP = 'AUTO_STOP',
  ALWAYS_ON = 'ALWAYS_ON',
}
export enum SelfServiceResultTypes {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
}
export interface SelfServiceActionResult {
  status: SelfServiceResultTypes;
}
export enum SelfServiceActionTypes {
  REBOOT = 'REBOOT',
  REBUILD = 'REBUILD',
  INCREASE_VOLUME_SIZE = 'INCREASE_VOLUME_SIZE',
  CHANGE_COMPUTE_TYPE = 'CHANGE_COMPUTE_TYPE',
  MODIFY_RUNNING_MODE_PROPERTIES = 'MODIFY_RUNNING_MODE_PROPERTIES',
}
export enum OtherActionTypes {
  CONNECT = 'CONNECT',
}
export type ActionTypes = SelfServiceActionTypes | OtherActionTypes;
export enum ActionClientInputs {
  ClientIp = 'ClientIp',
  ClientMacAddress = 'ClientMacAddress',
  Timezone = 'Timezone',
  ClientVersion = 'ClientVersion',
  ClientFingerPrint = 'ClientFingerPrint',
  ClientModel = 'ClientModel',
}
export interface BaseActionRequest {
  Version: string;
  SessionContext: {
    SessionId: string;
    AuthToken?: IToken;
  };
  AuthToken: {
    Value: string;
  };
  Resource: {
    ResourceType: string;
    ResourceId: string;
  };
}
export interface RebootWorkSpaceRequest extends BaseActionRequest {
  Action: {
    ActionType: SelfServiceActionTypes.REBOOT;
  };
}
export interface RebuildWorkSpaceRequest extends BaseActionRequest {
  Action: {
    ActionType: SelfServiceActionTypes.REBUILD;
  };
}
export interface ModifyRunningModeRequest extends BaseActionRequest {
  Action: {
    ActionType: SelfServiceActionTypes.MODIFY_RUNNING_MODE_PROPERTIES;
    ActionUserInput: {
      NewRunningMode: RunningModes;
    };
  };
}
interface DowngradeComputeTypeUserInput {
  NewComputeDowngradeType: string;
}
interface UpgradeComputeTypeUserInput {
  NewComputeUpgradeType: string;
}
export interface ChangeComputeTypeRequest extends BaseActionRequest {
  Action: {
    ActionType: SelfServiceActionTypes.CHANGE_COMPUTE_TYPE;
    ActionUserInput:
      | DowngradeComputeTypeUserInput
      | UpgradeComputeTypeUserInput;
  };
}
export interface IncreaseVolumeSizeRequest {
  Action: {
    ActionType: SelfServiceActionTypes.INCREASE_VOLUME_SIZE;
    ActionUserInput: {
      NewRootVolume?: string;
      NewUserVolume?: string;
    };
  };
}
export type IPerformResourceActionRequest =
  | RebootWorkSpaceRequest
  | RebuildWorkSpaceRequest
  | ModifyRunningModeRequest
  | ChangeComputeTypeRequest
  | IncreaseVolumeSizeRequest;
export interface IPerformResourceActionResponse {
  ActionResult: {
    ResultType: SelfServiceResultTypes;
  };
}

export enum DriveToIncrease {
  C = 'C',
  D = 'D',
}
