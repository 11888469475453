import { I18nResource } from '@/i18n/types';

const translation: I18nResource = {
  'ws-welcome': 'Bem-vindo(a) ao Amazon WorkSpaces.',
  'ws-options': 'Escolha uma das opções abaixo:',
  'ws-login': 'Fazer login',
  'ws-remember-me': 'Mantenha-me conectado',
  'ws-new-reg-code': 'Alterar código de cadastro',
  'ws-back-login': 'Voltar à página de login',
  'ws-fallback-login': 'Faça login com credenciais',
  'ws-auth-reconnect': 'Reconectar',
  'ws-disconnected-error':
    'Algo deu errado. Entre em contato com o administrador do WorkSpaces para obter ajuda',
  'ws-get-started':
    'Para começar, insira o código de registro fornecido a você pelo administrador.',
  'ws-register': 'Registrar-se',
  'ws-pin': 'Fixar',
  'ws-disconnect': 'Desconectar',
  'ws-disconnected': 'Desconectado',
  'ws-send-cad': 'Enviar CTRL-ALT-DEL',
  'ws-enter-full-screen': 'Entrar em tela cheia',
  'ws-exit-full-screen': 'Sair de tela cheia',
  'ws-error': 'Erro',
  'ws-console': 'Console dos WorkSpaces',
  'ws-privacy': 'Privacidade',
  'ws-site-terms': 'Termos do site',
  'ws-cookie-pref': 'Preferências de cookies',
  'ws-docs': 'Documentação',
  'ws-reserved': 'ou suas afiliadas. Todos os direitos reservados.',
  'ws-neg-error': 'Ocorreu um erro ao iniciar.',
  'ws-reg-error': 'Ocorreu um erro ao se registrar.',
  'ws-reg-format-error': 'Formato de código de registro inválido.',
  'ws-reg-invalid-error': 'Código de registro inválido.',
  'ws-reg-unsupported-by-platform':
    'O código de cadastro fornecido não é compatível com esta versão do cliente. Entre em contato com seu administrador para obter mais detalhes. ',
  'ws-reg-unknown-error': 'Código de registro desconhecido.',
  'ws-unknown-error': 'Erro desconhecido',
  'ws-loading-error':
    'Ocorreu um erro ao carregar a página de autenticação. Certifique-se de que esse SO/plataforma esteja habilitado no diretório que contém os WorkSpaces.',
  'ws-get-auth-info-error':
    'Ocorreu um erro ao carregar a página de autenticação devido a um código de registro inválido.',
  'ws-auth-error': 'Ocorreu um erro durante a autenticação.',
  'ws-auth-type-not-supported-error': 'O tipo de autenticação não é compatível',
  'ws-user-auth-failure':
    'Você pode ter uma inatividade prolongada ou uma nova senha. Tente novamente.',
  'ws-device-auth-failure':
    'Este dispositivo não tem autorização para acessar seu WorkSpace.',
  'ws-get-resources-error': 'Ocorreu um erro ao obter Recursos.',
  'ws-resource-not-found':
    'Nenhum WorkSpace encontrado com seu nome de usuário. Entre em contato com seu administrador para solicitar um.',
  'ws-not-supported-error':
    'A conexão da Web com o WorkSpace selecionado não é suportada.',
  'ws-suspended-error': 'O WorkSpace selecionado parece estar suspenso.',
  'ws-resource-maintenance-error':
    'Seu Espaço de trabalho está em manutenção. Isso pode levar algumas horas. Entre em contato com seu administrador para obter detalhes.',
  'ws-resource-terminated-error':
    'Seu Espaço de trabalho foi encerrado pelo administrador. Entre em contato com seu administrador para obter detalhes.',
  'ws-resource-unavailable-error':
    'Nenhum WorkSpace encontrado com seu nome de usuário. Entre em contato com seu administrador para solicitar um.',
  'ws-resource-unhealthy-error':
    'Não podemos nos conectar ao seu Espaço de trabalho no momento. Isso pode ser causado pelas atualizações atuais. Tente novamente mais tarde. Se isso acontecer novamente, entre em contato com o suporte de TI.',
  'ws-resource-imaging-error':
    'Ocorreu um erro ao iniciar seu Espaço de trabalho. Tente se reconectar mais uma vez em alguns instantes. Se o problema persistir, entre em contato com o administrador para obter ajuda.',
  'ws-try-again-error': 'Tente novamente mais tarde.',
  'ws-starting-error':
    'Não é possível conectar-se ao seu WorkSpace no momento.  Isso pode acontecer se o WorkSpace estiver iniciando.',
  'ws-try-again-few-error': 'Tente novamente em alguns minutos.',
  'ws-connecting-error': 'Ocorreu um erro ao se conectar ao seu WorkSpace.',
  'ws-service-unavailable':
    'Não foi possível iniciar seu WorkSpace. Tente novamente. Se precisar de ajuda, entre em contato com seu administrador.',
  'ws-workspace-unavailable':
    'O WorkSpace está indisponível no momento. Tente novamente mais tarde. Se precisar de ajuda, entre em contato com seu administrador.',
  'ws-workspace-connection-closed':
    'A conexão do WorkSpace foi encerrada devido à inatividade. Conecte-se novamente.',
  'ws-cm-session-timeout': 'A sessão atingiu o tempo limite.',
  'ws-not-supported-browser-error':
    'No momento, este dispositivo/navegador não é compatível com o acesso à web do WorkSpaces.',
  'ws-wsp-not-supported-error':
    'The DCV WorkSpace you are connecting to is using an outdated agent version. Please reboot your WorkSpace or contact your administrator to update the software version.',
  'ws-pcoip-unsupported-in-region':
    'O WorkSpace pertence a uma região que atualmente não é compatível com streaming usando o protocolo PCoIP nesta plataforma. Em vez disso, use o cliente WorkSpaces no Windows/MacOS/Linux para fazer streaming. Entre em contato com seu administrador para obter mais detalhes',
  'ws-pcoip-unsupported':
    'O streaming de WorkSpaces PCoIP não é compatível com esta plataforma. Instale este cliente no Ubuntu 20 para fazer streaming de WorkSpaces PCoIP',
  'ws-protocol-not-supported-browser-error':
    'Seu navegador, {{browser}}, não é compatível com {{protocol}}',
  'ws-protocol-not-supported-browser-version-error':
    'A versão do seu {{browser}}, {{version}} é inferior à nossa versão de suporte mais baixa: {{lowestSupportVersion}} para {{protocol}}.',
  'ws-try-chrome-error':
    'Tente novamente no Chrome (v55 ou posterior) ou Firefox (v48 ou posterior) no Windows, OSX ou Linux.',
  'ws-unhealthy-error': 'O WorkSpace selecionado está em um estado insalubre.',
  'ws-try-reboot-error': 'Tente reinicializar seu WorkSpace primeiro.',
  'ws-not-auth-device-error':
    'Este dispositivo não está autorizado a acessar o WorkSpace.',
  'ws-contact-admin-error':
    'Se o problema persistir, entre em contato com o administrador do WorkSpaces.',
  'ws-resumed':
    'Seu WorkSpace está sendo retomado. Ficará disponível para você em um momento.',
  'ws-not-optimized':
    'O cliente web não está totalmente otimizado para seu hardware. Para uma melhor experiência de streaming, use',
  'ws-native': 'os clientes nativos do WorkSpaces.',
  'ws-reg-code': 'Código de registro',
  'ws-reg-codes': 'Códigos de cadastro',
  'ws-os-error':
    'Este sistema operacional/plataforma não tem autorização para acessar seu WorkSpace.',
  'ws-connecting': 'Conectando…',
  'ws-authenticating': 'Autenticando...',
  'ws-getting-resource': 'Obtendo Recursos...',
  'ws-allocating-resource': 'Alocando Recursos...',
  'ws-sal-text': 'Faça login para autorizar o acesso',
  'ws-play-header': 'Login realizado com sucesso',
  'ws-play-description':
    'Login realizado com sucesso. Continue para o seu WorkSpace.',
  'ws-play-link': 'Clique para continuar',
  'ws-my-workspace': 'Meu WorkSpace',
  'ws-my-workspace-about': 'Resumo',
  'ws-my-workspace-restart': 'Reiniciar',
  'ws-my-workspace-change-compute-type': 'Alterar o tipo de computação',
  'ws-my-workspace-increase-disk-size': 'Aumentar o tamanho do disco',
  'ws-my-workspace-switch-running-mode': 'Alterar o modo de execução',
  'ws-my-workspace-rebuild': 'Recriar',
  'ws-my-workspace-change-compute-type-title': 'Tipo de computação',
  'ws-my-workspace-increase-disk-size-title': 'Tamanho do disco',
  'ws-my-workspace-switch-running-mode-title': 'Modo de execução',
  'ws-my-workspace-restart-error-message':
    'Seu WorkSpace falhou ao reiniciar. Tente novamente mais tarde.',
  'ws-my-workspace-rebuild-error-message':
    'Falha ao recriar seu WorkSpace. Tente novamente mais tarde.',
  'ws-my-workspace-change-compute-type-error-message':
    'Seu WorkSpace falhou ao alterar o tipo de computação. Tente novamente mais tarde.',
  'ws-my-workspace-change-compute-type-time-window-error-message':
    'Você só pode alterar seu tipo de computação uma vez em uma janela de 6 horas para aumento de escala e 30 dias para reduzir.',
  'ws-my-workspace-increase-disk-size-error-message':
    'Não foi possível aumentar o tamanho do volume do seu WorkSpace. Tente novamente mais tarde.',
  'ws-my-workspace-switch-running-mode-error-message':
    'Seu WorkSpace falhou ao alternar o modo de execução. Tente novamente mais tarde.',
  'ws-my-workspace-increase-disk-size-success-message':
    'Você aumentou com sucesso o Tamanho do disco.',
  'ws-my-workspace-switch-running-mode-success-message':
    'Você alterou com sucesso o modo de execução',
  'ws-my-workspace-restart-disconnect-confirm':
    'Entendo que reiniciar o WorkSpace vai desconectar os usuários da sessão atual.',
  'ws-my-workspace-rebuild-disconnect-confirm':
    'Entendo que a recriação apagará meus dados, os substituirá pelo meu backup mais recente e desconectará todos os usuários da sessão atual. Não é possível desfazer essa operação.',
  'ws-my-workspace-change-compute-type-disconnect-confirm':
    'Entendo que alterar o tipo de computação vai desconectar os usuários da sessão atual.',
  'ws-my-workspace-about-registration-code': 'Código de registro',
  'ws-my-workspace-about-region': 'Região',
  'ws-my-workspace-about-workspace-name': 'Nome do WorkSpace',
  'ws-my-workspace-about-id': 'ID',
  'ws-my-workspace-about-compute-type': 'Tipo de computação',
  'ws-my-workspace-about-status': 'Status',
  'ws-my-workspace-about-workspace-ip-address': 'Endereço IP do WorkSpace',
  'ws-my-workspace-about-protocol': 'Protocolo',
  'ws-my-workspace-restart-confirm':
    'Tem certeza de que deseja reiniciar seu WorkSpace?',
  'ws-my-workspace-restart-confirm-description':
    'Para evitar perda de dados, salve todos os documentos abertos e arquivos do aplicativo antes de reinicializar o WorkSpace.',
  'ws-my-workspace-change-compute-type-current-key': 'Tipo de computação atual',
  'ws-my-workspace-change-compute-type-in-use': 'em uso',
  'ws-my-workspace-change-compute-type-description':
    'Observe que alterar o tipo de computação afeta o custo do seu WorkSpace.',
  'ws-my-workspace-change-compute-type-aria-label':
    'Selecione o tipo de computação',
  'ws-my-workspace-increase-disk-size-current-key': 'Tamanho atual do disco',
  'ws-my-workspace-increase-disk-size-description':
    'Observe que alterar o tamanho do disco afeta o custo do seu WorkSpace.',
  'ws-my-workspace-increase-disk-size-info':
    'Aumente o tamanho da sua unidade {{driveToIncrease}}: de {{currentVolumeSizeInGib}} GIB para {{newVolumeSizeInGib}} GIB',
  'ws-my-workspace-increase-disk-size-max-reached':
    'Você atingiu o tamanho máximo do disco.',
  'ws-my-workspace-switch-running-mode-current-key': 'Modo de execução atual',
  'ws-my-workspace-switch-running-mode-auto-stop': 'Parada automática',
  'ws-my-workspace-switch-running-mode-auto-stop-description':
    'Cobrado por hora. O WorkSpace é iniciado automaticamente quando você faz login.',
  'ws-my-workspace-switch-running-mode-always-on': 'Sempre Ligado',
  'ws-my-workspace-switch-running-mode-always-on-description':
    'Cobrado mensalmente. Acesso instantâneo a um WorkSpace que está sempre em execução.',
  'ws-my-workspace-switch-running-mode-description':
    'Observe que alterar o modo de execução afeta o custo do seu WorkSpace.',
  'ws-my-workspace-rebuild-description':
    'Tem certeza de que deseja recriar seu WorkSpace? Recomendamos reiniciar seu WorkSpace primeiro para ver se isso resolve possíveis problemas. Recriar seu WorkSpace faz o seguinte',
  'ws-my-workspace-rebuild-data-lost-warning-label':
    'Substitui o que está atualmente em sua unidade D: pelos dados do seu backup mais recente.',
  'ws-my-workspace-rebuild-data-lost-warning-content':
    'Seus dados atuais serão perdidos.',
  'ws-my-workspace-rebuild-user-drive-recreate-warning-label':
    'Recriar seu WorkSpace leva até uma hora.',
  'ws-my-workspace-rebuild-user-drive-recreate-warning-content':
    'Não é possível usar ou fazer alterações em seu WorkSpace até que a recriação seja concluída.',
  'ws-region-name-iad': 'Leste dos EUA (Norte da Virgínia)',
  'ws-region-name-pdx': 'Oeste dos EUA (Oregon)',
  'ws-region-name-dub': 'UE (Irlanda)',
  'ws-region-name-syd': 'Ásia-Pacífico (Sydney)',
  'ws-region-name-nrt': 'Ásia-Pacífico (Tóquio)',
  'ws-region-name-sin': 'Ásia-Pacífico (Cingapura)',
  'ws-region-name-fra': 'UE (Frankfurt)',
  'ws-region-name-lhr': 'UE (Londres)',
  'ws-region-name-yul': 'Canadá (Central)',
  'ws-region-name-gru': 'América do Sul (São Paulo)',
  'ws-region-name-icn': 'Ásia-Pacífico (Seul)',
  'ws-region-name-cpt': 'África (Cidade do Cabo)',
  'ws-region-name-pdt': 'AWS GovCloud (Oeste dos EUA)',
  'ws-region-name-bom': 'Ásia-Pacífico (Mumbai)',
  'ws-region-name-osu': 'AWS GovCloud (Leste dos EUA)',
  'ws-region-name-tlv': 'Israel (Tel Aviv)',
  'ws-region-name-cdg': 'Europa (Paris)',
  'ws-unauthorized-action-error':
    'Você não está autorizado a executar esta ação. Entre em contato com seu administrador.',
  'ws-support': 'Suporte',
  'ws-logo-alt': 'Logotipo do Amazon WorkSpaces',
  'ws-disconnect-icon-alt': 'Um ícone de nuvem pontilhada para desconexão',
  'ws-menubar-logo-alt': 'Logotipo do Amazon WorkSpaces na barra de menu',
  'ws-menubar-full-screen-toggle-icon-alt':
    'Ícone para alternar para tela cheia na barra de menu',
  'ws-menubar-send-cad-icon-alt':
    'Ícone para enviar CTRL-ALT-DEL na barra de menu',
  'ws-menubar-disconnect-icon-alt':
    'Ícone do botão de desconexão na barra de menu',
  'ws-menubar-pin-icon-alt': 'Ícone para fixar ou desafixar a barra de menu',
  'ws-connect-status-label': 'Status do WorkSpace',
  'ws-connect-status-negotiation': 'Inicializando',
  'ws-connect-status-registration': 'Registrando o WorkSpaces',
  'ws-connect-status-hello': 'Recuperando recursos de autenticação',
  'ws-connect-status-customization': 'Recuperando detalhes da marca',
  'ws-connect-status-warp-drive':
    'Redirecionando para o Portal de Autenticação',
  'ws-connect-status-warp-drive-complete':
    'Redirecionamento para o Portal de Autenticação concluído',
  'ws-connect-status-saml':
    'Redirecionando para o provedor de autenticação externo',
  'ws-connect-status-authenticate': 'Autenticando',
  'ws-connect-status-get_resource': 'Obtendo recurso',
  'ws-connect-status-allocate-resource': 'Alocando recurso',
  'ws-connect-status-connect': 'Conectando',
  'ws-connect-status-resume': 'Inicializando WorkSpaces',
  'ws-connect-status-resume-pending': 'Iniciar o Espaço de trabalho',
  'ws-connect-status-streaming': 'Sessão de streaming',
  'ws-connect-status-disconnect': 'Sessão desconectada',
  'ws-connect-status-stream-context-received':
    'Gerando Requisitos de streaming...',
  'ws-footer-aws-link-label': 'Link para a página principal da AWS',
  'ws-language-label': 'Idioma',
  'ws-brand-description':
    'Serviço remoto para computadores, totalmente gerenciado',
  'ws-registration-page-title': 'Introdução',
  'ws-login-description': 'Faça login para acessar seu WorkSpace.',
  'ws-solo-saml-login-desc':
    'Você será direcionado para o seu navegador e voltará aqui para concluir o login.',
  'ws-progress-bar': 'Uma barra de progresso de carregamento',
  'ws-close-label': 'Fechar modal',
  'ws-spinner-no-error-message':
    'Nenhum erro encontrado. Redirecionando de volta para a página de registro',
  'ws-spinner-login-error-message': 'Erro ao fazer login.',
  'ws-spinner-redirecting-message': 'Redirecionando...',
  'ws-spinner-connecting-message': 'Conectando',
  'ws-loglevel-label-info': 'Registro padrão (predefinido)',
  'ws-loglevel-label-debug': 'Registro avançado',
  'ws-log-settings-title': 'Registro de diagnóstico',
  'ws-device-id': 'ID do dispositivo',
  'ws-log-setting-preference': 'Registro de diagnóstico',
  'ws-log-preference-title': 'Nível de registro',
  'ws-log-preference-description':
    'Escolha um nível de preferência de registro com base na quantidade de dados que você deseja fornecer em seus registros de sessão.',
  'ws-info-loglevel-description':
    'Gera arquivos de registro que contêm informações de diagnóstico sobre seu dispositivo, conexões com a Internet e métricas de desempenho de streaming.',
  'ws-debug-loglevel-description':
    'Gera arquivos de registro que contêm as mesmas informações que arquivos de registro padrão, com detalhes adicionais no nível de depuração e dados detalhados de desempenho. Observação: o registro avançado só é suportado no WorkSpaces DCV.',
  'ws-loguploader-description':
    'Ative o registro de diagnóstico para permitir que o cliente do WorkSpaces carregue automaticamente os registros do WorkSpaces. As informações nos registros podem ajudar a solucionar problemas. Esses registros conterão informações sobre seu dispositivo e sua conexão com a Internet.',
  'ws-loguploader-enabled': 'Ativado',
  'ws-loguploader-disabled': 'Desativado',
  'ws-modal-close': 'Fechar',
  'ws-loguploader-copy': 'Copiar',
  'ws-settings': 'Configurações',
  'ws-close-settings-button-label': 'Fechar modal',
  'ws-close-settings-buuton-name': 'Fechar',
  'ws-save-reg-code': 'Salve os códigos de cadastro',
  'ws-save-reg-code-toggle-description':
    '   Ative ou desative o WorkSpaces para salvar seus códigos de cadastro.',
  'ws-enabled-save': 'Ativado',
  'ws-disabled-save': 'Desativado',
  'ws-rename-description':
    'Renomear seu WorkSpace. O nome substituirá o código de cadastro como identificador.',
  'ws-custom-name-input-title': 'Nome no WorkSpaces',
  'ws-custom-name-input-placeholder': 'Nome no WorkSpaces',
  'ws-input-char-limit-description': 'Máximo de 20 caracteres',
  'ws-remember-me-settings':
    'Mantenha minha conexão até eu sair ou minha sessão expirar',
  'ws-settings-save-button': 'Salvar',
  'ws-saved-reg-codes-title': 'Códigos de cadastro salvos',
  'ws-default-title': '(padrão)',
  'ws-reg-code-settings-dropdown-label': 'Configurações do código de cadastro',
  'ws-reg-code-delete-name': 'Excluir',
  'ws-set-as-default-name': 'Definir como padrão',
  'ws-disable-save-modal-message':
    'Ao desativar o WorkSpaces para salvar seus códigos de cadastro, todos os códigos de cadastro atuais serão excluídos e nenhum código de cadastro futuro será salvo. Tem certeza de que deseja desativar o códigos de cadastro salvos?',
  'ws-disable-save-modal-title': 'Desativar códigos de cadastro salvos',
  'ws-disable-save-modal-aria-label': 'Fechar desabilita modal salvo',
  'ws-disable-alret-message':
    'Permita que o WorkSpaces salve seus códigos de cadastro para evitar a necessidade de digitar o código de cadastro sempre que quiser fazer login no WorkSpace.',
  'ws-delete-reg-code-modal-text': 'Tem certeza de que deseja excluir',
  'ws-delete-reg-code-modal-aria-text':
    'Fechar exclui código de cadastro modal',
  'ws-reg-code-input-placeholder': 'Insira o código de cadastro',
  'ws-reg-code-alias-error':
    'Já existe um WorkSpace com esse nome. Insira outro nome.',
  'ws-autosuggest-no-mtaches-found': 'Nenhuma correspondência encontrada',
  'ws-ok-button': 'OK',
  'ws-delete-button': 'Excluir',
  'ws-disable-button': 'Desativar',
  'ws-cancel-button': 'Cancelar',
  'ws-warning': 'Aviso',
  'ws-alert-text-keep-me-logged-in':
    ' “Mantenha-me conectado” será ativado após seu próximo login',
  'ws-no-reg-code-saved-description':
    'Nenhum código de cadastro salvo. Depois de fazer login com um código de cadastro, este fica salvo aqui.',
  'ws-healthcheck-tcp-fail':
    'Falha na conexão por portas TCP. Verifique suas configurações e tente novamente.',
  'ws-healthcheck-udp-fail':
    'Falha na conexão por portas UDP. Verifique suas configurações e tente novamente.',
  'ws-healthcheck-tcp-partial':
    'Conexão pela porta UDP {{connectedPort}} estabelecida. Não foi possível estabelecer uma conexão de saída com a porta UDP {{failedPort}}.',
  'ws-healthcheck-udp-partial':
    'Conexão pela porta UDP {{connectedPort}} estabelecida. Não foi possível estabelecer uma conexão de saída com a porta UDP {{failedPort}}.',
  'ws-healthcheck-tcp-roundtrip':
    'O tempo de ida e volta para TCP é {{tcpRoundTripTime}} ms. A velocidade da sua rede está baixa, pode haver atrasos.',
  'ws-healthcheck-udp-roundtrip':
    'O tempo de ida e volta para UDP é {{udpRoundTripTime}} ms. A velocidade da sua rede está baixa, pode haver atrasos.',
  'ws-healthcheck-connection-failed':
    'Falha na conexão HTTPS ao se conectar ao serviço WorkSpaces em sua região, {{healthcheckRegionName}}. Entre em contato com seu administrador de TI',
  'ws-unsupported-pcoip-for-saml':
    'O WorkSpaces PCoIP não é compatível com SAML no cliente web. Baixe os aplicativos nativos do WorkSpaces e faça login.',
  'ws-unsupported-pcoip-for-idc':
    'O WorkSpaces PCoIP não é compatível com IDC no cliente web. Baixe os aplicativos nativos do WorkSpaces e faça login.',
  'ws-streaming-failed-generic-error':
    'Erro inesperado ocorrido durante o streaming. Tente novamente mais tarde.',
  'ws-warp-drive-load-timeout':
    'Não é possível redirecionar para o portal de Autenticação. Falha na operação por tempo esgotado. Tente novamente após algum tempo. Se o problema persistir, entre em contato com seu administrador de TI',
  'ws-warp-drive-unavailable':
    'Não é possível redirecionar para o portal de Autenticação. Verifique suas configurações de rede e firewall e tente novamente. Se o problema persistir, entre em contato com seu administrador de TI',
  'ws-warp-drive-unknown-error':
    'Não é possível redirecionar para o portal de Autenticação. Ocorreu um erro desconhecido. Tente novamente após algum tempo. Se o problema persistir, entre em contato com seu administrador de TI',
  'ws-solo-system-browser-error':
    'Não foi possível iniciar seu navegador da web para redirecionar para o portal de autenticação. Entre em contato com o administrador de TI para verificar se seu navegador instalado é compatível com o WorkSpaces URI Access.',
  'ws-registration-page-main-title': 'Introdução - Amazon WorkSpaces',
  'ws-login-page-main-title': 'Fazer login - Amazon WorkSpaces',
  'ws-disconnect-page-main-title': 'Desconectado - Amazon WorkSpaces',
  'ws-name': 'Amazon WorkSpaces',
  'ws-web-maxibon-streaming-failed-auth-denied':
    'Erro inesperado ocorrido durante o streaming. A conexão de streaming do WorkSpaces foi fechada pelo servidor porque a autorização foi negada para a sessão atual',
  'ws-web-maxibon-streaming-failed-auth-revoked':
    'Erro inesperado ocorrido durante o streaming. A conexão de streaming do WorkSpaces foi fechada pelo servidor, pois a autorização não é mais válida para a sessão atual',
  'ws-web-maxibon-streaming-failed-access-rejected':
    'Erro inesperado ocorrido durante o streaming. Tente novamente mais tarde.',
  'ws-web-maxibon-streaming-failed-idle-timeout':
    'Você foi desconectado devido à inatividade.',
  'ws-web-maxibon-streaming-failed-owner-disconnect':
    'A conexão de streaming do WorkSpace foi fechada pelo servidor.',
  'ws-web-maxibon-streaming-failed-user-disconnect':
    'A conexão de streaming do WorkSpace foi fechada porque o WorkSpace está sendo transmitido em outra sessão do cliente.',
  'ws-web-maxibon-streaming-failed-streaming-evicted':
    'A conexão de streaming do WorkSpace foi fechada devido ao início de uma nova conexão.',
  'ws-oem-overlay-title': 'Iniciando...',
  'ws-oem-overlay-description': 'Continuar com a autenticação',
  'ws-web-maxibon-streaming-failed-streaming-unsupported-credential':
    'Ocorreu um problema ao autorizar seu Espaço de trabalho. Faça login com suas credenciais corporativas',
  'ws-web-maxibon-streaming-failed-streaming-fallback-login-required':
    'Ocorreu um erro inesperado ao iniciar seu Espaço de trabalho. Faça login com suas credenciais corporativas.',
  'ws-authenticate-invalid-fallback-method':
    'O método de fallback é inválido. A tentativa de fallback para fazer login com suas credenciais corporativas falhou.',
  'ws-error-alert-icon': 'Ícone de alerta de erro',
  'ws-save-reg-codes-enabled': 'Salvar códigos de cadastro, Ativado',
  'ws-save-reg-code-disabled': 'Salvar códigos de cadastro, Desativado',
  'ws-pool-insufficient-capacity':
    'Nenhum recurso de streaming está disponível para sua sessão. Tente novamente em alguns minutos.',
  'ws-session-cleanup-in-progress':
    'Sua sessão anterior ainda não foi encerrada. Escolha TENTAR NOVAMENTE após alguns minutos para tentar se conectar novamente.',
  'ws-region-unsupported-by-stack':
    'O código de registro usado não é compatível com este terminal do Workspaces Web Client. Use o terminal de produção para transmitir recursos de produção.',
  'ws-pcoip-access-denied-description':
    'Para continuar se conectando a esse WorkSpace, baixe o aplicativo cliente WorkSpaces ou entre em contato com o administrador para atualizar o WorkSpace.',
  'ws-pcoip-access-denied-title':
    'Você não pode mais se conectar a este WorkSpace PCoIP usando Web Access.',
  'ws-pcoip-access-denied-header': 'Connection method not supported',
};

export default translation;
