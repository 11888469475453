import { I18nResource } from '@/i18n/types';

const translation: I18nResource = {
  'ws-welcome': 'Amazon WorkSpaceへようこそ。',
  'ws-options': '以下のオプションのいずれかを選択してください:',
  'ws-login': 'サインイン',
  'ws-remember-me': 'ログイン状態を保つ',
  'ws-new-reg-code': '登録コードを変更する',
  'ws-back-login': 'サインインページに戻る',
  'ws-fallback-login': '認証情報でサインインする',
  'ws-auth-reconnect': '再接続する',
  'ws-disconnected-error':
    '問題が発生しました。WorkSpaces管理者にサポートを依頼してください',
  'ws-get-started': 'まず、管理者から提供された登録コードを入力します。',
  'ws-register': '登録',
  'ws-pin': '固定',
  'ws-disconnect': '接続を解除',
  'ws-disconnected': '切断済み',
  'ws-send-cad': 'Ctrl-Alt-Delを送信する',
  'ws-enter-full-screen': '全画面表示にする',
  'ws-exit-full-screen': '全画面表示を終了する',
  'ws-error': 'エラー',
  'ws-console': 'WorkSpacesコンソール',
  'ws-privacy': 'プライバシー',
  'ws-site-terms': 'サイト規約',
  'ws-cookie-pref': 'Cookieカスタマイズ設定',
  'ws-docs': '資料',
  'ws-reserved': 'またはその関連会社の商標です。全著作権所有。',
  'ws-neg-error': '開始中にエラーが発生しました。',
  'ws-reg-error': '登録中にエラーが発生しました。',
  'ws-reg-format-error': '登録コードの形式が無効です。',
  'ws-reg-invalid-error': '登録コードが無効です。',
  'ws-reg-unsupported-by-platform':
    '提供された登録コードは、このバージョンのクライアントではサポートされていません。詳細については、管理者にお問い合わせください。',
  'ws-reg-unknown-error': '登録コードが不明です。',
  'ws-unknown-error': '不明なエラー',
  'ws-loading-error':
    '認証ページの読み込み中にエラーが発生しました。このOS/プラットフォームがWorkSpaceを含むディレクトリで有効になっていることを確認してください。',
  'ws-get-auth-info-error':
    '登録コードが無効なため、認証ページの読み込み中にエラーが発生しました。',
  'ws-auth-error': '認証中にエラーが発生しました。',
  'ws-auth-type-not-supported-error': '認証タイプはサポートされていません',
  'ws-user-auth-failure':
    '長期間使用していないか、新しいパスワードが設定されている可能性があります。もう一度お試しください。',
  'ws-device-auth-failure':
    'このデバイスは、Workspaceへのアクセスが許可されていません。',
  'ws-get-resources-error': 'リソースの取得中にエラーが発生しました。',
  'ws-resource-not-found':
    'ご利用のユーザー名のWorkSpaceは見つかりませんでした。管理者に連絡し、依頼してください。',
  'ws-not-supported-error':
    '選択したWorkSpaceへのウェブ接続はサポートされていません。',
  'ws-suspended-error': '選択したWorkSpaceは一時停止されているようです。',
  'ws-resource-maintenance-error':
    'WorkSpaceはメンテナンス中です。これには数時間かかる場合があります。詳細については、管理者にお問い合わせください。',
  'ws-resource-terminated-error':
    'WorkSpaceは管理者によって終了されました。詳細については、管理者にお問い合わせください。',
  'ws-resource-unavailable-error':
    'ご利用のユーザー名のWorkSpaceは見つかりませんでした。管理者に連絡し、依頼してください。',
  'ws-resource-unhealthy-error':
    '現在、お客様のWorkSpaceに接続することができません。これは現在の更新によるものである可能性があります。しばらくしてから、もう一度お試しください。再び問題が発生した場合は、ITサポートにご連絡ください。',
  'ws-resource-imaging-error':
    'WorkSpaceを起動中にエラーが発生しました。しばらくしてから再接続してください。この問題が解消しない場合は、管理者にお問い合わせください。',
  'ws-try-again-error': 'しばらくしてから、もう一度お試しください。',
  'ws-starting-error':
    '現在、お客様のWorkSpaceに接続することができません。 これは、WorkSpaceが起動している場合に発生する可能性があります。',
  'ws-try-again-few-error': '数分後にもう一度お試しください。',
  'ws-connecting-error': 'WorkSpaceへの接続中にエラーが発生しました。',
  'ws-service-unavailable':
    'WorkSpaceを起動できませんでした。もう一度お試しください。サポートが必要な場合は、管理者にお問い合わせください。',
  'ws-workspace-unavailable':
    '現在、WorkSpaceはご利用いただけません。しばらくしてからもう一度お試しください。サポートが必要な場合は、管理者にお問い合わせください。',
  'ws-workspace-connection-closed':
    'WorkSpace接続は非アクティブのため切断されました。再接続してください。',
  'ws-cm-session-timeout': 'セッションがタイムアウトしました。',
  'ws-not-supported-browser-error':
    'このデバイスまたはブラウザは、現在WorkSpacesのウェブアクセスには対応していません。',
  'ws-wsp-not-supported-error':
    'お客様が接続中のDCV WorkSpaceは、古いバージョンのエージェントを使用しています。 WorkSpaceを再起動するか、管理者に連絡してソフトウェアバージョンを更新してください。',
  'ws-pcoip-unsupported-in-region':
    'WorkSpaceは、現在このプラットフォームでPCoIPプロトコルを使用したストリーミング再生をサポートしていないリージョンに属しています。代わりに、Windows/macOS/LinuxのWorkSpacesクライアントを使用してストリーミング再生します。詳細については、管理者にお問い合わせください',
  'ws-pcoip-unsupported':
    'PCoIP WorkSpaceストリーミング再生は、このプラットフォームではサポートされていません。このクライアントをUbuntu 2.0にインストールして、PCoIP WorkSpaceをストリーミング再生します。',
  'ws-protocol-not-supported-browser-error':
    'お使いのブラウザ {{browser}}は{{protocol}}に対応していません',
  'ws-protocol-not-supported-browser-version-error':
    'お客様の{{browser}}のバージョン{{version}}は、Amazonの{{protocol}}の最低サポートバージョン{{lowestSupportVersion}}よりも低くなっています。',
  'ws-try-chrome-error':
    'Windows、OSX、またはLinuxのChrome(v55以降)またはFirefox(v48以降)からもう一度お試しください。',
  'ws-unhealthy-error': '選択したWorkSpaceは健全ではない状態です。',
  'ws-try-reboot-error': '最初にWorkSpaceを再起動してみてください。',
  'ws-not-auth-device-error':
    'このデバイスにはWorkSpaceへのアクセスが許可されていません。',
  'ws-contact-admin-error':
    '問題が解決しない場合は、WorkSpaces 管理者に連絡してください。',
  'ws-resumed':
    'WorkSpacesは再開中です。すぐにご利用いただけるようになります。',
  'ws-not-optimized':
    'Webクライアントは、ご使用のハードウェアには完全に最適化されていません。より良いストリーミングを体験するために、ご使用ください',
  'ws-native': 'WorkSpacesのネイティブクライアント。',
  'ws-reg-code': '登録コード',
  'ws-reg-codes': '登録コード',
  'ws-os-error':
    'このOS/プラットフォームは、Workspaceへのアクセスが許可されていません。',
  'ws-connecting': '接続しています...',
  'ws-authenticating': '認証中...',
  'ws-getting-resource': 'リソースを取得中...',
  'ws-allocating-resource': 'リソースの割り当て中...',
  'ws-sal-text': 'サインインしてアクセスを許可する',
  'ws-play-header': 'サインインに成功しました',
  'ws-play-description':
    'サインインに成功しました。WorkSpaceに進んでください。',
  'ws-play-link': 'クリックして次に進む',
  'ws-my-workspace': 'マイWorkSpace',
  'ws-my-workspace-about': '概要',
  'ws-my-workspace-restart': '再開する',
  'ws-my-workspace-change-compute-type': 'コンピューティングタイプを変更',
  'ws-my-workspace-increase-disk-size': 'ディスクサイズを拡張する',
  'ws-my-workspace-switch-running-mode': '実行モードを変更',
  'ws-my-workspace-rebuild': '再構築',
  'ws-my-workspace-change-compute-type-title': 'コンピューティングタイプ',
  'ws-my-workspace-increase-disk-size-title': 'ディスクサイズ',
  'ws-my-workspace-switch-running-mode-title': '実行モード',
  'ws-my-workspace-restart-error-message':
    'WorkSpaceを再起動できませんでした。しばらくしてから、もう一度お試しください。',
  'ws-my-workspace-rebuild-error-message':
    'WorkSpaceを再構築できませんでした。しばらくしてから、もう一度お試しください。',
  'ws-my-workspace-change-compute-type-error-message':
    'WorkSpaceがコンピューティングタイプを変更できませんでした。しばらくしてから、もう一度お試しください。',
  'ws-my-workspace-change-compute-type-time-window-error-message':
    'コンピューティングタイプを変更できるのは、拡張の場合は6時間に1回、縮小の場合は30日間に1回のみです。',
  'ws-my-workspace-increase-disk-size-error-message':
    'WorkSpaceがボリュームサイズを拡張できませんでした。しばらくしてから、もう一度お試しください。',
  'ws-my-workspace-switch-running-mode-error-message':
    'WorkSpaceが実行モードの切り替えに失敗しました。しばらくしてから、もう一度お試しください。',
  'ws-my-workspace-increase-disk-size-success-message':
    'ディスクサイズが正常に拡張されました。',
  'ws-my-workspace-switch-running-mode-success-message':
    '実行モードが正常に切り替わりました',
  'ws-my-workspace-restart-disconnect-confirm':
    'WorkSpaceを再起動すると、ユーザーが現在のセッションから切断されることを理解しています。',
  'ws-my-workspace-rebuild-disconnect-confirm':
    '再構築するとマイデータが消去され、最新のバックアップに置き換えられ、すべてのユーザーが現在のセッションから切断されることを理解しています。この操作は元に戻せません。',
  'ws-my-workspace-change-compute-type-disconnect-confirm':
    'コンピューティングタイプを変更すると、ユーザーが現在のセッションから切断されることを理解しています。',
  'ws-my-workspace-about-registration-code': '登録コード',
  'ws-my-workspace-about-region': 'リージョン',
  'ws-my-workspace-about-workspace-name': 'WorkSpace名',
  'ws-my-workspace-about-id': 'ID',
  'ws-my-workspace-about-compute-type': 'コンピューティングタイプ',
  'ws-my-workspace-about-status': 'ステータス',
  'ws-my-workspace-about-workspace-ip-address': 'WorkSpace IPアドレス',
  'ws-my-workspace-about-protocol': 'プロトコル',
  'ws-my-workspace-restart-confirm': 'WorkSpaceを再起動してもよろしいですか?',
  'ws-my-workspace-restart-confirm-description':
    'データが失われないように、WorkSpaceを再起動する前に、開いているすべてのドキュメントとアプリケーションファイルを保存してください。',
  'ws-my-workspace-change-compute-type-current-key':
    '現在のコンピューティングタイプ',
  'ws-my-workspace-change-compute-type-in-use': '使用中',
  'ws-my-workspace-change-compute-type-description':
    'コンピューティングモードを変更すると、WorkSpaceのコストに影響しますので、ご注意ください。',
  'ws-my-workspace-change-compute-type-aria-label':
    'コンピューティングタイプを選択',
  'ws-my-workspace-increase-disk-size-current-key': '現在のディスクサイズ',
  'ws-my-workspace-increase-disk-size-description':
    'ディスクサイズを変更すると、WorkSpaceのコストに影響しますので、ご注意ください。',
  'ws-my-workspace-increase-disk-size-info':
    '{{driveToIncrease}}:ドライブのサイズを{{currentVolumeSizeInGib}}GIBから{{newVolumeSizeInGib}}GIBに拡張する',
  'ws-my-workspace-increase-disk-size-max-reached':
    '最大ディスクサイズに達しました。',
  'ws-my-workspace-switch-running-mode-current-key': '現在の実行モード',
  'ws-my-workspace-switch-running-mode-auto-stop': '自動停止',
  'ws-my-workspace-switch-running-mode-auto-stop-description':
    '時間単位で請求されます。WorkSpaceは、ログインすると自動的に起動します。',
  'ws-my-workspace-switch-running-mode-always-on': '常時オン',
  'ws-my-workspace-switch-running-mode-always-on-description':
    '毎月払い。常に実行しているWorkSpaceに即時アクセスできます。',
  'ws-my-workspace-switch-running-mode-description':
    '実行モードを変更すると、WorkSpaceのコストに影響しますので、ご注意ください。',
  'ws-my-workspace-rebuild-description':
    'WorkSpaceを再構築してもよろしいですか? 最初にWorkSpaceを再起動して、潜在的な問題が解決するかどうか確認することをお勧めします。WorkSpaceを再構築すると、次の処理が行われます',
  'ws-my-workspace-rebuild-data-lost-warning-label':
    'D:ドライブに現在保存されている内容を、最新のバックアップのデータに置き換えます。',
  'ws-my-workspace-rebuild-data-lost-warning-content':
    '現在のデータは失われます。',
  'ws-my-workspace-rebuild-user-drive-recreate-warning-label':
    'WorkSpaceの再構築には最大1時間かかります。',
  'ws-my-workspace-rebuild-user-drive-recreate-warning-content':
    '再構築が完了するまでWorkSpaceを使用したり変更したりすることはできません。',
  'ws-region-name-iad': '米国東部(バージニア北部)',
  'ws-region-name-pdx': '米国西部(オレゴン)',
  'ws-region-name-dub': 'EU(アイルランド)',
  'ws-region-name-syd': 'アジア太平洋(シドニー)',
  'ws-region-name-nrt': 'アジア太平洋(東京)',
  'ws-region-name-sin': 'アジア太平洋(シンガポール)',
  'ws-region-name-fra': 'EU(フランクフルト)',
  'ws-region-name-lhr': 'EU(ロンドン)',
  'ws-region-name-yul': 'カナダ(中部)',
  'ws-region-name-gru': '南米(サンパウロ)',
  'ws-region-name-icn': 'アジア太平洋(ソウル)',
  'ws-region-name-cpt': 'アフリカ(ケープタウン)',
  'ws-region-name-pdt': 'AWS GovCloud(米国西部)',
  'ws-region-name-bom': 'アジア太平洋(ムンバイ)',
  'ws-region-name-osu': 'AWS GovCloud(米国東部)',
  'ws-region-name-tlv': 'イスラエル (テルアビブ)',
  'ws-region-name-cdg': 'ヨーロッパ（パリ）',
  'ws-unauthorized-action-error':
    'この編集を実行する権限がありません。管理者にお問い合わせください。',
  'ws-support': 'サポート',
  'ws-logo-alt': 'Amazon WorkSpacesロゴ',
  'ws-disconnect-icon-alt': '接続解除用の点付きのクラウドアイコン',
  'ws-menubar-logo-alt': 'メニューバーのAmazon WorkSpacesロゴ',
  'ws-menubar-full-screen-toggle-icon-alt':
    'メニューバーの全画面表示切り替え用アイコン',
  'ws-menubar-send-cad-icon-alt':
    'メニューバーのCtrl-Alt-Delを送信するアイコン',
  'ws-menubar-disconnect-icon-alt':
    'メニューバーの「接続解除」ボタンのアイコン',
  'ws-menubar-pin-icon-alt': 'メニューバーの固定または固定解除のアイコン',
  'ws-connect-status-label': 'WorkSpaceステータス',
  'ws-connect-status-negotiation': '初期化中です',
  'ws-connect-status-registration': 'WorkSpacesの登録中',
  'ws-connect-status-hello': '認証機能を取得中',
  'ws-connect-status-customization': 'ブランド詳細の取得中',
  'ws-connect-status-warp-drive': '認証ポータルへのリダイレクト',
  'ws-connect-status-warp-drive-complete':
    '認証ポータルのリダイレクトが完了しました',
  'ws-connect-status-saml': '外部認証プロバイダーへのリダイレクト',
  'ws-connect-status-authenticate': '認証中',
  'ws-connect-status-get_resource': 'リソースを取得中',
  'ws-connect-status-allocate-resource': 'リソースの割り当て中',
  'ws-connect-status-connect': '接続中',
  'ws-connect-status-resume': 'ワークスペースの初期化中',
  'ws-connect-status-resume-pending': 'WorkSpaceを起動する',
  'ws-connect-status-streaming': 'ストリーミングセッション',
  'ws-connect-status-disconnect': 'セッションが切断されました',
  'ws-connect-status-stream-context-received': 'ストリーミング要件を生成中...',
  'ws-footer-aws-link-label': 'AWSメインページへのリンク',
  'ws-language-label': '言語',
  'ws-brand-description': '完全管理型リモートデスクトップサービス',
  'ws-registration-page-title': '今すぐ始める',
  'ws-login-description': 'サインインしてWorkSpaceにアクセスします。',
  'ws-solo-saml-login-desc':
    'ブラウザを開き、ここに戻ってサインインを完了します。',
  'ws-progress-bar': '読み込み進行状況バー',
  'ws-close-label': 'モーダルを閉じる',
  'ws-spinner-no-error-message':
    'エラーは見つかりませんでした。登録ページに戻るようにリダイレクトする',
  'ws-spinner-login-error-message': 'サインインエラー。',
  'ws-spinner-redirecting-message': 'リダイレクト中...',
  'ws-spinner-connecting-message': '接続中',
  'ws-loglevel-label-info': '標準ロギング(デフォルト)',
  'ws-loglevel-label-debug': '詳細ロギング',
  'ws-log-settings-title': '診断ロギング',
  'ws-device-id': 'デバイスID',
  'ws-log-setting-preference': '診断ロギング',
  'ws-log-preference-title': 'ログレベル',
  'ws-log-preference-description':
    'セッションログに提供するデータ量に基づいて、ロギングの優先レベルを選択します。',
  'ws-info-loglevel-description':
    'デバイス、インターネット接続、ストリーミングパフォーマンス指標に関する診断情報を含むログファイルを生成します。',
  'ws-debug-loglevel-description':
    '標準ロギングファイルと同じ情報を含むログファイルを、デバッグレベルの詳細と詳細なパフォーマンスデータが追加された状態で生成します。 注:高度なロギングはDCVワークスペースでのみサポートされています。',
  'ws-loguploader-description':
    '診断ロギングを有効にすると、WorkSpacesクライアントがWorkSpacesログを自動的にアップロードできるようになります。ログの情報は、問題のトラブルシューティングに役立ちます。これらのログには、デバイスとインターネット接続に関する情報が含まれます。',
  'ws-loguploader-enabled': '有効',
  'ws-loguploader-disabled': '無効になりました',
  'ws-modal-close': '閉じる',
  'ws-loguploader-copy': 'コピー',
  'ws-settings': '設定',
  'ws-close-settings-button-label': 'モーダルを閉じる',
  'ws-close-settings-buuton-name': '閉じる',
  'ws-save-reg-code': '登録コードを保存する',
  'ws-save-reg-code-toggle-description':
    '   WorkSpaces を有効または無効にして、登録コードを保存します。',
  'ws-enabled-save': '有効',
  'ws-disabled-save': '無効になりました',
  'ws-rename-description':
    'WorkSpaceの名前を変更します。登録コードの代わりに名前が識別子になります。',
  'ws-custom-name-input-title': 'WorkSpaceの名前',
  'ws-custom-name-input-placeholder': 'WorkSpaceの名前',
  'ws-input-char-limit-description': '最大20文字',
  'ws-remember-me-settings':
    '終了するまで、またはセッションの有効期限が切れるまでログイン状態を保つ',
  'ws-settings-save-button': '保存',
  'ws-saved-reg-codes-title': '保存済みの登録コード',
  'ws-default-title': '(デフォルト)',
  'ws-reg-code-settings-dropdown-label': '登録コードの設定',
  'ws-reg-code-delete-name': '削除',
  'ws-set-as-default-name': 'デフォルトに設定',
  'ws-disable-save-modal-message':
    'WorkSpaces による登録コードの保存を無効化すると、現在の登録コードはすべて削除され、今後の登録コードは保存されません。登録コードの保存を無効化してもよろしいですか?',
  'ws-disable-save-modal-title': '登録コードの保存を無効化する',
  'ws-disable-save-modal-aria-label':
    '保存が無効化されたモーダルウィンドウを閉じる',
  'ws-disable-alret-message':
    'WorkSpaces で登録コードを保存できるようにすると、WorkSpace にサインインするたびに登録コードを入力する必要がなくなります。',
  'ws-delete-reg-code-modal-text': '本当に削除してもよろしいですか',
  'ws-delete-reg-code-modal-aria-text':
    '登録コードの削除モーダルウィンドウを閉じる',
  'ws-reg-code-input-placeholder': '登録コードを入力',
  'ws-reg-code-alias-error':
    '同じ名前のWorkSpaceが既に存在します。別の名前を入力してください。',
  'ws-autosuggest-no-mtaches-found': '一致する結果はありません',
  'ws-ok-button': 'OK',
  'ws-delete-button': '削除',
  'ws-disable-button': '無効化する',
  'ws-cancel-button': 'キャンセル',
  'ws-warning': '警告',
  'ws-alert-text-keep-me-logged-in':
    ' 「ログイン状態を保つ」は、次回のログイン後に有効になります',
  'ws-no-reg-code-saved-description':
    '登録コードは保存されていません。登録コードでログインすると、ここに保存されます。',
  'ws-healthcheck-tcp-fail':
    'TCPポート経由の接続に失敗しました。設定を確認して、もう一度お試しください。',
  'ws-healthcheck-udp-fail':
    'UDPポート経由の接続に失敗しました。設定を確認して、もう一度お試しください。',
  'ws-healthcheck-tcp-partial':
    'UDP{{connectedPort}}ポート経由の接続が確立されました。UDPポート{{failedPort}}へのアウトバウンド接続を確立できませんでした。',
  'ws-healthcheck-udp-partial':
    'UDP{{connectedPort}}ポート経由の接続が確立されました。UDPポート{{failedPort}}へのアウトバウンド接続を確立できませんでした。',
  'ws-healthcheck-tcp-roundtrip':
    'TCPのラウンドトリップタイムは{{tcpRoundTripTime}}ms です。ネットワークの速度が遅いため、遅延が予想されます。',
  'ws-healthcheck-udp-roundtrip':
    'UDPのラウンドトリップタイムは{{udpRoundTripTime}}msです。ネットワークの速度が遅いため、遅延が予想されます。',
  'ws-healthcheck-connection-failed':
    'HTTPS接続は、お住まいの地域{{healthcheckRegionName}}のWorkSpacesサービスに接続できませんでした。IT管理者にお問い合わせください',
  'ws-unsupported-pcoip-for-saml':
    'WorkSpaces PCoIPはウェブクライアントのSAMLではサポートされていません。ネイティブWorkSpacesアプリケーションをダウンロードし、サインインしてください。',
  'ws-unsupported-pcoip-for-idc':
    'WorkSpaces PCoIPはウェブクライアントのIDCではサポートされていません。ネイティブWorkSpacesアプリケーションをダウンロードし、サインインしてください。',
  'ws-streaming-failed-generic-error':
    'ストリーミング中に予期しないエラーが発生しました。しばらくしてからもう一度お試しください。',
  'ws-warp-drive-load-timeout':
    '認証ポータルにリダイレクトできません。タイムアウトにより操作に失敗しました。後ほど再度お試しください。問題が解決しない場合は、IT管理者にお問い合わせください',
  'ws-warp-drive-unavailable':
    '認証ポータルにリダイレクトできません。ネットワークとファイアウォールの設定を確認して再度お試しください。問題が解決しない場合は、IT管理者にお問い合わせください',
  'ws-warp-drive-unknown-error':
    '認証ポータルにリダイレクトできません。予期しないエラーが発生しました。後ほど再度お試しください。問題が解決しない場合は、IT管理者にお問い合わせください',
  'ws-solo-system-browser-error':
    'ウェブブラウザを起動して認証ポータルにリダイレクトできません。IT管理者に問い合わせて、WorkSpaces URI Access対応のブラウザがインストールされていることをご確認ください。',
  'ws-registration-page-main-title': '利用開始 - Amazon WorkSpaces',
  'ws-login-page-main-title': 'サインイン - Amazon WorkSpaces',
  'ws-disconnect-page-main-title': '接続解除 - Amazon WorkSpaces',
  'ws-name': 'Amazon WorkSpaces',
  'ws-web-maxibon-streaming-failed-auth-denied':
    'ストリーミング中に予期しないエラーが発生しました。現在のセッションの認証が拒否されたため、WorkSpaces ストリーミング接続はサーバーによって閉じられました',
  'ws-web-maxibon-streaming-failed-auth-revoked':
    'ストリーミング中に予期しないエラーが発生しました。現在のセッションでは認証が無効になったため、WorkSpaces ストリーミング接続はサーバーによって閉じられました',
  'ws-web-maxibon-streaming-failed-access-rejected':
    'ストリーミング中に予期しないエラーが発生しました。しばらくしてからもう一度お試しください。',
  'ws-web-maxibon-streaming-failed-idle-timeout':
    '操作がないため接続が切断されました。',
  'ws-web-maxibon-streaming-failed-owner-disconnect':
    'WorkSpace ストリーミング接続がサーバーによって閉じられました。',
  'ws-web-maxibon-streaming-failed-user-disconnect':
    'WorkSpace が別のクライアントセッションでストリーミングされているため、WorkSpace ストリーミング接続は閉じられました。',
  'ws-web-maxibon-streaming-failed-streaming-evicted':
    '新しい接続が開始されたため、WorkSpace ストリーミング接続は終了しました。',
  'ws-oem-overlay-title': '起動中...',
  'ws-oem-overlay-description': '認証を続行',
  'ws-web-maxibon-streaming-failed-streaming-unsupported-credential':
    'WorkSpaceの認証中に問題が発生しました。あなたの法人認証情報を使用してサインイン',
  'ws-web-maxibon-streaming-failed-streaming-fallback-login-required':
    'Workspaceの起動中に予期しないエラーが発生しました。あなたの法人認証情報を使用してサインインしてください。',
  'ws-authenticate-invalid-fallback-method':
    'フォールバックメソッドは無効です。会社の認証情報を使ったサインインへのフォールバックを試みましたが、失敗しました。',
  'ws-error-alert-icon': 'エラーアラートアイコン',
  'ws-save-reg-codes-enabled': '登録コードの保存、有効化済み',
  'ws-save-reg-code-disabled': '登録コードの保存、無効化済み',
  'ws-pool-insufficient-capacity':
    'セッションで利用できるストリーミングリソースはありません。数分後にもう一度お試しください。',
  'ws-session-cleanup-in-progress':
    '前回のセッションはまだ終了していません。数分後に「再試行」を選択して、接続し直してください。',
  'ws-region-unsupported-by-stack':
    '使用された登録コードは、このWorkspaces Web Clientエンドポイントではサポートされていません。プロダクションエンドポイントを使用してプロダクションリソースをストリーミングします。',
  'ws-pcoip-access-denied-description':
    'このWorkSpaceへの接続を続けるには、WorkSpacesクライアントアプリケーションをダウンロードするか、管理者に連絡してWorkSpaceを更新してください。',
  'ws-pcoip-access-denied-title':
    '今後、ウェブアクセスを使用してこのPCoIP WorkSpaceに接続することはできなくなります。',
  'ws-pcoip-access-denied-header': 'Connection method not supported',
};

export default translation;
