import * as React from 'react';
import { Button, Alert, Modal } from '@cloudscape-design/components';
import CentralizedLayout from '@/layout/CentralizedLayout';
import { ClientErrorCode } from '../../bridge/types/ErrorTypes';

interface BlockPlatformModalProps {
  header: string;
  alertTitle: string;
  alertDescription: string;
  onClose: () => void;
}

export const shouldShowBlockPlatformModal = (error?: any) => {
  if (!error) return false;
  return (
    error?.clientCode === ClientErrorCode.AllocateResourcePcoipWebAcessDenied
  );
};

const BlockPlatformModal: React.FC<BlockPlatformModalProps> = ({
  header,
  alertDescription,
  alertTitle,
  onClose,
}) => {
  return (
    <CentralizedLayout>
      <Modal
        data-testid="end-user-modal"
        closeAriaLabel="Close"
        visible={true}
        onDismiss={({ detail }) => {
          detail.reason === 'closeButton' && onClose();
        }}
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              ariaLabel="Download latest client"
              href="https://clients.amazonworkspaces.com/"
              iconAlign="right"
              iconName="external"
              target="_blank"
            >
              Download latest client
            </Button>
          </div>
        }
        header={header}
      >
        <Alert statusIconAriaLabel="Error" type="error">
          {alertTitle}
        </Alert>
        <div style={{ paddingTop: '10px' }}>{alertDescription}</div>
      </Modal>
    </CentralizedLayout>
  );
};

export default BlockPlatformModal;
