import { useSessionStore } from '@stores/session';
import { useEffect, useRef, useState } from 'react';
import { WsError } from '@core/error/WsError';
import { useSearchParams } from 'react-router-dom';
import { CoreFactory } from '@bridge/factory/CoreFactory';
import { SessionProtocols } from '@bridge/types/SessionTypes';
import { I18nError } from '@/lib/I18nError';
import { WebInSessionError } from '@/bridge/types/WebInSessionError';
import { MetricResult, Operation } from '@/bridge/types/MetricTypes';

const metrics = CoreFactory.getMetrics();
const device = CoreFactory.getDevice();

export const useDisconnectHandler = () => {
  const [searchParams] = useSearchParams();
  const [i18Error, setI18Error] = useState<I18nError>();
  const region = useSessionStore((state) => state.region);
  const resourceProtocol = useSessionStore((state) => state.resourceProtocol);
  const isMetricPublishStarted = useRef<boolean>(false);
  const disconnectErrorTypeFromInSession = searchParams.get(
    WebInSessionError.DISCONNECT_ERROR_TYPE_SEARCH_NAME
  );
  const disconnectStatusCodeFromInSession = searchParams.get(
    WebInSessionError.DISCONNECT_STATUS_CODE_SEARCH_NAME
  );

  /**
   * When allocate resource failed with FALLBACK_LOGIN_REQUIRED exception, we'll redirect user to disconnect page
   * with FALLBACK_LOGIN_REQUIRED error code for a consistent user experience. However the resource protocol won't be
   * available in this situation becuase allocate resource API request failed and can't response the protocol data.
   * Plus, PCoIP/STxHD won't be able to reach the allocate resource step with SAML, so it will never raise the
   * FALLBACK_LOGIN_REQUIRED exception.
   * In this way if the disconnectStatusCodeFromInSession is FALLBACK_LOGIN_REQUIRED, and the resource protocol is empty,
   * we can confirm it's from Maxibon
   */
  const isSAMLFallbackError =
    disconnectErrorTypeFromInSession ===
      WebInSessionError.WspErrorTypes.CONNECTION &&
    disconnectStatusCodeFromInSession ===
      WebInSessionError.WspConnectionErrorCodes.FALLBACK_LOGIN_REQUIRED &&
    !resourceProtocol;
  const sessionProtocol = isSAMLFallbackError
    ? SessionProtocols.MAXIBON
    : resourceProtocol;

  const publishDisconnectMetric = () => {
    metrics.emitDisconnectMetrics(
      disconnectStatusCodeFromInSession,
      sessionProtocol as SessionProtocols
    );
  };

  useEffect(() => {
    if (region && sessionProtocol && !isMetricPublishStarted.current) {
      isMetricPublishStarted.current = true;
      const wsError = WsError.buildDisconnectError(
        disconnectErrorTypeFromInSession,
        disconnectStatusCodeFromInSession,
        sessionProtocol,
        device.getPlatform()
      );
      if (
        disconnectErrorTypeFromInSession ===
        WebInSessionError.WspErrorTypes.CONNECTION
      ) {
        metrics.emit(Operation.Streaming, MetricResult.Error, wsError);
      } else {
        publishDisconnectMetric();
      }
      wsError && setI18Error(I18nError.getI18nError(wsError));
    }
  }, [region, disconnectStatusCodeFromInSession, sessionProtocol, setI18Error]);

  return {
    disconnectI18Error: i18Error,
  };
};
