import { ILogger } from '@bridge/ILogger';
import { IMetrics } from '@bridge/IMetrics';
import { MetricResult, Operation } from '@bridge/types/MetricTypes';
import {
  Action,
  OnSuccessRTCCallback,
  RTCChannelMessage,
} from '@bridge/types/SoloRTCChannelTypes';
import { ActionHandlerFactory } from '@core/rtcChannel/handlers/ActionHandlerFactory';
import { ISessionManager } from '@bridge/ISessionManager';
import { WsError } from '@core/error/WsError';
import { WsErrorCodes } from '@core/error/WsErrorCodes';
import { WsErrorTypes } from '@core/error/WsErrorTypes';
import { ClientErrorCode } from '@bridge/types/ErrorTypes';

export class SoloNativeRTCHandler {
  private readonly logger: ILogger;
  private readonly metrics: IMetrics;
  private readonly handlerFactory: ActionHandlerFactory;

  constructor(
    logger: ILogger,
    metrics: IMetrics,
    sessionManager: ISessionManager
  ) {
    this.logger = logger;
    this.metrics = metrics;
    this.handlerFactory = new ActionHandlerFactory(
      logger,
      metrics,
      sessionManager
    );
  }

  handleRTCIncomingMessage(
    e: string,
    rtcActionToCallbackMap: Map<Action, OnSuccessRTCCallback | undefined>
  ) {
    try {
      const escapedStackTrace = e.replace(/\n/g, '\\n');
      this.validate(escapedStackTrace);
      const msg = JSON.parse(`${escapedStackTrace}`) as RTCChannelMessage;
      const handler = this.handlerFactory.getHandler(msg.Action);
      const onSuccessRTCCallback = rtcActionToCallbackMap.get(msg.Action);
      if (handler) {
        handler.handleMessage(msg, onSuccessRTCCallback);
      } else {
        throw new WsError(
          WsErrorTypes.ERROR_TYPE_UNKNOWN,
          WsErrorCodes.ERROR_INVALID_INPUT,
          ClientErrorCode.RTCChannelUnsupportedChannel
        );
      }
    } catch (error: any) {
      const errorString = ` Message: ${JSON.stringify(error?.message)} Code: ${
        error?.clientErrorCode
      }`;
      if (errorString.length < 2000) {
        this.logger.error(
          `Error parsing incoming RTC message. Error: ${errorString}`
        );
      } else {
        this.logger.error(
          'Error parsing incoming RTC message, message is too long to print'
        );
      }
      this.metrics.emit(
        Operation.BrowserRTCOnMessage,
        MetricResult.Fault,
        error
      );
    }
  }

  private validate(e: string) {
    const msg = JSON.parse(e);
    if (msg?.Action === undefined || msg?.ActionType === undefined) {
      this.logger.error(`RTC message received is invalid ${e}`);
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_UNKNOWN,
        WsErrorCodes.ERROR_INVALID_INPUT,
        ClientErrorCode.RTCChannelInvalidInput
      );
    }
  }
}
