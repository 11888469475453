import { I18nResource } from '@/i18n/types';

const translation: I18nResource = {
  'ws-welcome': '欢迎使用亚马逊 WorkSpaces。',
  'ws-options': '请选择以下选项之一：',
  'ws-login': '登录',
  'ws-remember-me': '保持登录状态',
  'ws-new-reg-code': '更改注册码',
  'ws-back-login': '返回登录页面',
  'ws-fallback-login': '使用凭据登录',
  'ws-auth-reconnect': '重新连接',
  'ws-disconnected-error': '出错了。请联系您的 WorkSpaces 管理员寻求帮助',
  'ws-get-started': '若要开始使用，请输入管理员提供给您的注册码。',
  'ws-register': '注册',
  'ws-pin': '固定',
  'ws-disconnect': '断开连接',
  'ws-disconnected': '已断开',
  'ws-send-cad': '发送 CTRL-ALT-DEL',
  'ws-enter-full-screen': '进入全屏模式',
  'ws-exit-full-screen': '退出全屏模式',
  'ws-error': '错误',
  'ws-console': 'WorkSpaces 控制台',
  'ws-privacy': '隐私',
  'ws-site-terms': '站点条款',
  'ws-cookie-pref': 'Cookie 首选项',
  'ws-docs': '文档',
  'ws-reserved': '或其附属公司。保留所有权利。',
  'ws-neg-error': '在初始化过程中发生错误。',
  'ws-reg-error': '注册时出错。',
  'ws-reg-format-error': '注册码格式无效。',
  'ws-reg-invalid-error': '注册码无效。',
  'ws-reg-unsupported-by-platform':
    '此版本的客户端不支持提供的注册码。有关更多详情，请联系您的管理员。',
  'ws-reg-unknown-error': '未知的注册码。',
  'ws-unknown-error': '未知错误',
  'ws-loading-error':
    '加载身份验证页面时出错。请确保在包含 WorkSpaces 的目录中启用了此操作系统/平台。',
  'ws-get-auth-info-error': '由于注册码无效，加载身份验证页面时出错。',
  'ws-auth-error': '进行身份验证时出错。',
  'ws-auth-type-not-supported-error': '不支持身份验证类型',
  'ws-user-auth-failure': '您可能长时间处于不活动状态或使用了新密码。请重试。',
  'ws-device-auth-failure': '此设备未获授权，无法访问您的 WorkSpace。',
  'ws-get-resources-error': '获取资源时出错。',
  'ws-resource-not-found':
    '未在您的用户名下找到 WorkSpace。如需申请，请联系您的管理员。',
  'ws-not-supported-error': '不支持通过 Web 连接到所选的 WorkSpace。',
  'ws-suspended-error': '所选 WorkSpace 似乎已暂停。',
  'ws-resource-maintenance-error':
    '您的 WorkSpace 正在进行维护。这可能需要几个小时才能完成。有关详情，请联系您的管理员。',
  'ws-resource-terminated-error':
    '管理员终止了您的 WorkSpace。有关详情，请联系您的管理员。',
  'ws-resource-unavailable-error':
    '未在您的用户名下找到 WorkSpace。如需申请，请联系您的管理员。',
  'ws-resource-unhealthy-error':
    '我们现在无法连接到您的 WorkSpace。这可能是由当前的更新导致。请稍后再试。如果再次发生这种情况，请联系 IT 技术支持。',
  'ws-resource-imaging-error':
    '启动 WorkSpace 时出错。请稍后再次尝试重新连接。如果问题仍然存在，请联系您的管理员寻求帮助。',
  'ws-try-again-error': '请稍后再试。',
  'ws-starting-error':
    '我们现在无法连接到您的 WorkSpace。 如果您的 WorkSpace 正在启动，则可能会发生这种情况。',
  'ws-try-again-few-error': '请在几分钟后重试。',
  'ws-connecting-error': '连接到您的 WorkSpace 时出错。',
  'ws-service-unavailable':
    '我们无法启动您的 WorkSpace。请重试。如果您需要帮助，请联系您的管理员。',
  'ws-workspace-unavailable':
    'WorkSpace 目前不可用。请稍后再试。如果您需要帮助，请联系您的管理员。',
  'ws-workspace-connection-closed':
    '已关闭处于不活动状态的 WorkSpace 连接。请重新连接。',
  'ws-cm-session-timeout': '会话超时。',
  'ws-not-supported-browser-error':
    '目前不支持此设备/浏览器通过 Web 访问 WorkSpaces。',
  'ws-wsp-not-supported-error':
    '您正在连接的 DCV Workspace 使用的代理版本已过时。 请重新启动您的 Workspace 或联系您的管理员以更新软件版本。',
  'ws-pcoip-unsupported-in-region':
    '该 Workspace 所属的区域目前不支持在此平台上使用 PCoIP 协议进行在线播放。请改为使用 Windows/macOS/Linux 上的 WorkSpaces 客户端进行播放。有关更多详情，请联系您的管理员',
  'ws-pcoip-unsupported':
    '此平台不支持 PcoIP Workspace 在线播放。在 Ubuntu 20 上安装此客户端以播放 PCoIP Workspace',
  'ws-protocol-not-supported-browser-error':
    '您的浏览器 — {{protocol}} 不支持 {{browser}}',
  'ws-protocol-not-supported-browser-version-error':
    '您的 {{browser}} 版本 — {{version}} 低于我们所支持的最低版本 — 用于 {{protocol}} 的 {{lowestSupportVersion}}。',
  'ws-try-chrome-error':
    '请在 Windows、OSX 或 Linux 上使用 Chrome（v55 或更高版本）或 Firefox（v48 或更高版本）重试。',
  'ws-unhealthy-error': '所选 WorkSpace 状况不佳。',
  'ws-try-reboot-error': '请先尝试重启您的 WorkSpace。',
  'ws-not-auth-device-error': '此设备未获授权，无法访问 WorkSpace。',
  'ws-contact-admin-error': '如果问题仍然存在，请联系您的 WorkSpaces 管理员。',
  'ws-resumed': '您的 WorkSpace 正在恢复中。稍后即可使用。',
  'ws-not-optimized':
    'Web 客户端未针对您的硬件进行全面优化。为了获得更好的流媒体体验，请使用',
  'ws-native': 'WorkSpaces 本机客户端。',
  'ws-reg-code': '注册码',
  'ws-reg-codes': '注册代码',
  'ws-os-error': '此操作系统/平台未获授权，无法访问您的 WorkSpace。',
  'ws-connecting': '正在连接…',
  'ws-authenticating': '正在进行身份验证…',
  'ws-getting-resource': '正在获取资源...',
  'ws-allocating-resource': '正在分配资源...',
  'ws-sal-text': '登录以授权访问',
  'ws-play-header': '登录成功',
  'ws-play-description': '登录成功。继续前往您的工作区。',
  'ws-play-link': '点击以继续',
  'ws-my-workspace': '我的 WorkSpace',
  'ws-my-workspace-about': '总结',
  'ws-my-workspace-restart': '重新开始',
  'ws-my-workspace-change-compute-type': '更改计算类型',
  'ws-my-workspace-increase-disk-size': '增加磁盘容量',
  'ws-my-workspace-switch-running-mode': '更改运行模式',
  'ws-my-workspace-rebuild': '重建',
  'ws-my-workspace-change-compute-type-title': '计算类型',
  'ws-my-workspace-increase-disk-size-title': '磁盘容量',
  'ws-my-workspace-switch-running-mode-title': '运行模式',
  'ws-my-workspace-restart-error-message':
    '您的 WorkSpace 重启失败。请稍后再试。',
  'ws-my-workspace-rebuild-error-message':
    '您的 WorkSpace 重建失败。请稍后再试。',
  'ws-my-workspace-change-compute-type-error-message':
    '您未能更改 WorkSpace 计算类型。请稍后再试。',
  'ws-my-workspace-change-compute-type-time-window-error-message':
    '您只能在 6 小时窗口期内更改一次计算类型以实现向上扩展，在 30 天内更改一次计算类型以实现向下缩减。',
  'ws-my-workspace-increase-disk-size-error-message':
    '增加您的 WorkSpace 磁盘容量失败。请稍后再试。',
  'ws-my-workspace-switch-running-mode-error-message':
    '您的 WorkSpace 未能切换运行模式。请稍后再试。',
  'ws-my-workspace-increase-disk-size-success-message':
    '您成功增加了磁盘容量。',
  'ws-my-workspace-switch-running-mode-success-message': '您成功切换了运行模式',
  'ws-my-workspace-restart-disconnect-confirm':
    '我明白重启 WorkSpace 会断开用户与当前会话的连接。',
  'ws-my-workspace-rebuild-disconnect-confirm':
    '我明白重建会删除我的数据，替换为最近的备份，并且会断开所有用户与当前会话的连接。此操作不可撤消。',
  'ws-my-workspace-change-compute-type-disconnect-confirm':
    '我明白更改计算类型会断开用户与当前会话的连接。',
  'ws-my-workspace-about-registration-code': '注册码',
  'ws-my-workspace-about-region': '地区',
  'ws-my-workspace-about-workspace-name': 'WorkSpace 名称',
  'ws-my-workspace-about-id': 'ID',
  'ws-my-workspace-about-compute-type': '计算类型',
  'ws-my-workspace-about-status': '状态',
  'ws-my-workspace-about-workspace-ip-address': 'WorkSpace IP 地址',
  'ws-my-workspace-about-protocol': '协议',
  'ws-my-workspace-restart-confirm': '您是否确定要重新启动您的 WorkSpace？',
  'ws-my-workspace-restart-confirm-description':
    '为避免数据丢失，在重启 WorkSpace 之前请保存所有打开的文档和应用程序文件。',
  'ws-my-workspace-change-compute-type-current-key': '当前的计算类型',
  'ws-my-workspace-change-compute-type-in-use': '正在使用中',
  'ws-my-workspace-change-compute-type-description':
    '请注意，更改计算类型会影响 WorkSpace 的成本。',
  'ws-my-workspace-change-compute-type-aria-label': '选择计算类型',
  'ws-my-workspace-increase-disk-size-current-key': '当前磁盘容量',
  'ws-my-workspace-increase-disk-size-description':
    '请注意，更改磁盘容量会影响您的 WorkSpace 成本。',
  'ws-my-workspace-increase-disk-size-info':
    '将您的 {{driveToIncrease}}: 驱动器的容量从 {{currentVolumeSizeInGib}} GIB 增加到 {{newVolumeSizeInGib}} GIB',
  'ws-my-workspace-increase-disk-size-max-reached': '您已达到磁盘容量上限。',
  'ws-my-workspace-switch-running-mode-current-key': '当前运行模式',
  'ws-my-workspace-switch-running-mode-auto-stop': '自动停止',
  'ws-my-workspace-switch-running-mode-auto-stop-description':
    '按小时计费。当您登录时，WorkSpace 会自动启动。',
  'ws-my-workspace-switch-running-mode-always-on': '始终启用',
  'ws-my-workspace-switch-running-mode-always-on-description':
    '按月计费。即时访问一直在运行的 WorkSpace。',
  'ws-my-workspace-switch-running-mode-description':
    '请注意，更改运行模式会影响 WorkSpace 的成本。',
  'ws-my-workspace-rebuild-description':
    '您是否确定要重建您的 WorkSpace？ 我们建议您先重新启动您的 WorkSpace，看看能否解决潜在问题。重建您的 WorkSpace 会造成以下影响',
  'ws-my-workspace-rebuild-data-lost-warning-label':
    '用最近备份的数据替换您当前 D: 驱动器上的内容。',
  'ws-my-workspace-rebuild-data-lost-warning-content': '您当前的数据将会丢失。',
  'ws-my-workspace-rebuild-user-drive-recreate-warning-label':
    '重建您的 WorkSpace 最多可能需要一个小时。',
  'ws-my-workspace-rebuild-user-drive-recreate-warning-content':
    '在完成重建 WorkSpace 之前，您无法使用或更改 WorkSpace。',
  'ws-region-name-iad': 'US 东部（北弗吉尼亚）',
  'ws-region-name-pdx': '美国西部（俄勒冈）',
  'ws-region-name-dub': '欧盟（爱尔兰）',
  'ws-region-name-syd': '亚太地区（悉尼）',
  'ws-region-name-nrt': '亚太地区（东京）',
  'ws-region-name-sin': '亚太地区（新加坡）',
  'ws-region-name-fra': '欧盟（法兰克福）',
  'ws-region-name-lhr': '欧盟（伦敦）',
  'ws-region-name-yul': '加拿大（中部）',
  'ws-region-name-gru': '南美洲（圣保罗）',
  'ws-region-name-icn': '亚太地区（首尔）',
  'ws-region-name-cpt': '非洲（开普敦）',
  'ws-region-name-pdt': 'AWS GovCloud（美国西部）',
  'ws-region-name-bom': '亚太地区（孟买）',
  'ws-region-name-osu': 'AWS GovCloud（美国东部）',
  'ws-region-name-tlv': '以色列（特拉维夫）',
  'ws-region-name-cdg': '欧洲（巴黎）',
  'ws-unauthorized-action-error':
    '您未获授权，无法执行此操作。请联系您的管理员。',
  'ws-support': '支持',
  'ws-logo-alt': '亚马逊 WorkSpaces 徽标',
  'ws-disconnect-icon-alt': '用于断开连接的虚线云图标',
  'ws-menubar-logo-alt': '菜单栏上的亚马逊 WorkSpaces 徽标',
  'ws-menubar-full-screen-toggle-icon-alt': '菜单栏上的全屏切换图标',
  'ws-menubar-send-cad-icon-alt': '在菜单栏上发送 CTRL-ALT-DEL 的图标',
  'ws-menubar-disconnect-icon-alt': '菜单栏上断开连接按钮的图标',
  'ws-menubar-pin-icon-alt': '用于固定或取消固定菜单栏的图标',
  'ws-connect-status-label': 'WorkSpace 状态',
  'ws-connect-status-negotiation': '正在初始化',
  'ws-connect-status-registration': '正在注册 WorkSpaces',
  'ws-connect-status-hello': '正在检索 身份验证功能',
  'ws-connect-status-customization': '正在检索品牌详情',
  'ws-connect-status-warp-drive': '重定向至身份验证门户',
  'ws-connect-status-warp-drive-complete': '身份验证门户重定向已完成',
  'ws-connect-status-saml': '重定向到外部身份验证提供程序',
  'ws-connect-status-authenticate': '正在验证身份',
  'ws-connect-status-get_resource': '正在获取资源',
  'ws-connect-status-allocate-resource': '正在分配资源',
  'ws-connect-status-connect': '正在连接',
  'ws-connect-status-resume': '初始化 WorkSpaces',
  'ws-connect-status-resume-pending': '启动 WorkSpace',
  'ws-connect-status-streaming': '流媒体会话',
  'ws-connect-status-disconnect': '会话已断开连接',
  'ws-connect-status-stream-context-received': '正在生成流媒体需求...',
  'ws-footer-aws-link-label': '链接到 AWS 主页',
  'ws-language-label': '语言',
  'ws-brand-description': '完全托管的远程桌面服务',
  'ws-registration-page-title': '开始使用',
  'ws-login-description': '登录以访问您的工作区。',
  'ws-solo-saml-login-desc':
    '我们会将您转至浏览器，然后引导您回到此处完成登录。',
  'ws-progress-bar': '正在加载的进度条',
  'ws-close-label': '关闭模态',
  'ws-spinner-no-error-message': '未发现错误。正重定回注册页面',
  'ws-spinner-login-error-message': '登录错误。',
  'ws-spinner-redirecting-message': '正在重定向...',
  'ws-spinner-connecting-message': '正在连接',
  'ws-loglevel-label-info': '标准日志（默认）',
  'ws-loglevel-label-debug': '高级日志',
  'ws-log-settings-title': '诊断日志',
  'ws-device-id': '设备 ID',
  'ws-log-setting-preference': '诊断日志',
  'ws-log-preference-title': '日志级别',
  'ws-log-preference-description':
    '根据您要在会话日志中提供的数据量选择日志首选级别。',
  'ws-info-loglevel-description':
    '生成日志文件，其中包含有关您的设备、互联网连接和在线播放绩效指标的诊断信息。',
  'ws-debug-loglevel-description':
    '生成日志文件，其中包含与标准日志文件相同的信息，并添加了调试级别的详情和详细的性能数据。 注意：只有 DCV Workspaces 支持高级日志记录。',
  'ws-loguploader-description':
    '启用诊断日志记录以允许 WorkSpaces 客户端自动上传 WorkSpaces 日志。日志中的信息可以帮助解决问题。这些日志将包含有关您的设备和互联网连接的信息。',
  'ws-loguploader-enabled': '已启用',
  'ws-loguploader-disabled': '已禁用',
  'ws-modal-close': '关闭',
  'ws-loguploader-copy': '复制',
  'ws-settings': '设置',
  'ws-close-settings-button-label': '关闭模态',
  'ws-close-settings-buuton-name': '关闭',
  'ws-save-reg-code': '保存注册码',
  'ws-save-reg-code-toggle-description':
    '   启用或禁用 WorkSpaces 保存注册码。',
  'ws-enabled-save': '已启用',
  'ws-disabled-save': '已禁用',
  'ws-rename-description': '重命名您的 WorkSpace。名称将取代注册码作为标识符。',
  'ws-custom-name-input-title': 'WorkSpaces 名称',
  'ws-custom-name-input-placeholder': 'WorkSpaces 名称',
  'ws-input-char-limit-description': '不超过 20 个字符',
  'ws-remember-me-settings': '在我退出或会话过期之前保持登录状态',
  'ws-settings-save-button': '保存',
  'ws-saved-reg-codes-title': '已保存的注册码',
  'ws-default-title': '（默认）',
  'ws-reg-code-settings-dropdown-label': '注册码设置',
  'ws-reg-code-delete-name': '删除',
  'ws-set-as-default-name': '设置为默认选项',
  'ws-disable-save-modal-message':
    '通过禁用 WorkSpaces 保存注册码，当前的所有注册码都将被删除，以后也不会保存任何注册码。确定要禁用保存注册码吗？',
  'ws-disable-save-modal-title': '禁用保存注册码',
  'ws-disable-save-modal-aria-label': '关闭禁用保存模式',
  'ws-disable-alret-message':
    '启用 WorkSpaces 保存注册码，可避免每次登录 WorkSpace 时都输入注册码。',
  'ws-delete-reg-code-modal-text': '是否确定要删除',
  'ws-delete-reg-code-modal-aria-text': '关闭删除注册码模式',
  'ws-reg-code-input-placeholder': '输入注册码',
  'ws-reg-code-alias-error': '已存在使用该名称的 WorkSpace。请输入不同的名称。',
  'ws-autosuggest-no-mtaches-found': '未找到匹配项',
  'ws-ok-button': '确定',
  'ws-delete-button': '删除',
  'ws-disable-button': '禁用',
  'ws-cancel-button': '取消',
  'ws-warning': '警告',
  'ws-alert-text-keep-me-logged-in': ' 下次登录后将启用 “让我保持登录状态”',
  'ws-no-reg-code-saved-description':
    '未保存注册码。使用注册码登录后，它将保存在此处。',
  'ws-healthcheck-tcp-fail': 'TCP 端口连接失败。请检查您的设置，然后重试。',
  'ws-healthcheck-udp-fail': 'UDP 端口连接失败。请检查您的设置，然后重试。',
  'ws-healthcheck-tcp-partial':
    '已建立通过 UDP 端口 {{connectedPort}} 的连接。无法建立到 UDP 端口 {{failedPort}} 的出站连接。',
  'ws-healthcheck-udp-partial':
    '已建立通过 UDP 端口 {{connectedPort}} 的连接。无法建立到 UDP 端口 {{failedPort}} 的出站连接。',
  'ws-healthcheck-tcp-roundtrip':
    'TCP 的往返时延为 {{tcpRoundTripTime}} 毫秒。您的网络速度较慢，预计会有延迟。',
  'ws-healthcheck-udp-roundtrip':
    'UDP 的往返时延为 {{udpRoundTripTime}} 毫秒。您的网络速度较慢，预计会有延迟。',
  'ws-healthcheck-connection-failed':
    '您所在地区为 {{healthcheckRegionName}}，HTTPS 连接无法连接至该地区的 WorkSpaces 服务。请联系您的 IT 管理员',
  'ws-unsupported-pcoip-for-saml':
    'Web 客户端中的 SAML 不支持 WorkSpaces PCoIP。下载本地 WorkSpaces 应用并登录。',
  'ws-unsupported-pcoip-for-idc':
    'Web 客户端中的 IDC 不支持 WorkSpaces PCoIP。下载本机 WorkSpaces 应用程序并登录。',
  'ws-streaming-failed-generic-error': '在线播放期间遇到意外错误。请稍后再试。',
  'ws-warp-drive-load-timeout':
    '无法重定向到身份验证门户。由于超时，操作失败。请稍后重试，如果问题仍然存在，请联系您的 IT 管理员',
  'ws-warp-drive-unavailable':
    '无法重定向到身份验证门户。检查您的网络和防火墙设置，然后重试。如果问题仍然存在，请联系您的 IT 管理员',
  'ws-warp-drive-unknown-error':
    '无法重定向到身份验证门户。出现未知错误。请稍后重试，如果问题仍然存在，请联系您的 IT 管理员',
  'ws-solo-system-browser-error':
    '无法启动您的网页浏览器并重定向到身份验证门户网站。请联系您的 IT 管理员，确保您安装的浏览器与 WorkSpaces URI Access 兼容。',
  'ws-registration-page-main-title': '开始使用 - 亚马逊 WorkSpaces',
  'ws-login-page-main-title': '登录 - 亚马逊 WorkSpaces',
  'ws-disconnect-page-main-title': '已断开连接 - 亚马逊 WorkSpaces',
  'ws-name': '亚马逊工作空间',
  'ws-web-maxibon-streaming-failed-auth-denied':
    '在线播放期间出现意外错误。服务器拒绝了当前会话的授权，因此关闭了 WorkSpaces 在线播放连接',
  'ws-web-maxibon-streaming-failed-auth-revoked':
    '在线播放期间出现意外错误。由于授权对当前会话不再有效，服务器关闭了 WorkSpaces 在线播放连接',
  'ws-web-maxibon-streaming-failed-access-rejected':
    '在线播放期间遇到意外错误。请稍后再试。',
  'ws-web-maxibon-streaming-failed-idle-timeout': '您因不活动，已断开连接。',
  'ws-web-maxibon-streaming-failed-owner-disconnect':
    '服务器关闭了 WorkSpace 在线播放连接。',
  'ws-web-maxibon-streaming-failed-user-disconnect':
    '由于 WorkSpace 正在另一个客户端会话中播放，服务器关闭了 WorkSpace 在线播放连接。',
  'ws-web-maxibon-streaming-failed-streaming-evicted':
    '服务器开始建立新的连接，因此关闭了 WorkSpace 在线播放连接。',
  'ws-oem-overlay-title': '开始...',
  'ws-oem-overlay-description': '继续进行身份验证',
  'ws-web-maxibon-streaming-failed-streaming-unsupported-credential':
    '授权您的工作区时出现问题。使用您的企业凭证登录',
  'ws-web-maxibon-streaming-failed-streaming-fallback-login-required':
    '启动您的工作区时出现意外错误。使用您的企业凭证登录。',
  'ws-authenticate-invalid-fallback-method':
    '回退方法无效。尝试回退到使用您的公司凭据登录失败了。',
  'ws-error-alert-icon': '错误警报图标',
  'ws-save-reg-codes-enabled': '保存注册码，已启用',
  'ws-save-reg-code-disabled': '保存注册码，已禁用',
  'ws-pool-insufficient-capacity':
    '您的会话没有可用的流媒体资源。请在几分钟后重试。',
  'ws-session-cleanup-in-progress':
    '您之前的会话尚未结束。请在几分钟后选择“重试”，以再次尝试连接。',
  'ws-region-unsupported-by-stack':
    '此 Workspaces Web 客户端端点不支持所使用的注册码。使用生产端点来流式传输生产资源。',
  'ws-pcoip-access-denied-description':
    '要继续连接此 WorkSpace，请下载 WorkSpaces 客户端应用程序或联系管理员以更新您的 WorkSpace。',
  'ws-pcoip-access-denied-title':
    '您无法再使用 Web Access 连接此 PCoIP WorkSpace。',
  'ws-pcoip-access-denied-header': 'Connection method not supported',
};

export default translation;
