export enum ClientErrorCode {
  // Range 001 - 0999 = Registration
  RegCodeFormatInvalid = 1,
  RegCodeFormatUnsupportedByPlatform = 2,
  RegCodeRegionNotFound = 3,
  RegCodeRegionUnsupportedByStack = 4,

  // Range 1000 - 1999 = GetAuthInfo
  AuthInfoNotFound = 1001,
  AuthInfoServerError = 1002,
  AuthInfoUnknown = 1003,
  AuthInfoPlatformDenied = 1004,
  AuthInfoServiceUnavailable = 1005,
  AuthInfoRegCodeInvalid = 1006,
  AuthInfoInvalidResponse = 1007,

  // Range 2000 - 2999 = BeginPresessionUsecase
  NoRegistrationFound = 2000,
  NoAuthMethodsFound = 2001,

  // Range 3000-3999 = HttpClientErrors
  HttpClient3xxNetworkError = 3000,
  HttpClient4xxNetworkError = 3001,
  HttpClient5xxNetworkError = 3002,
  HttpClientUnknownNetworkError = 3003,
  HttpClientJsonSerializationError = 3004,
  HttpClientSocketNetworkError = 3005,
  HttpClientTimeoutError = 3006,

  // Range 4000-4999 = Session Provision Errors
  PcoipSessionProvisionUnknownError = 4000,
  PcoipSessionProvisionInvalidServerResponse = 4001,
  PcoipSessionProvisionNoTargets = 4002,
  PcoipSessionProvisionServerError = 4003,
  PcoipSessionProvisionUnableToConnect = 4004,
  PcoipSessionProvisionNotSuccessful = 4005,
  PcoipSessionProvisionWorkspaceUnreachable = 4006,
  PcoipSessionProvisionWorkspaceInoperable = 4007,
  PcoipSessionProvisionWorkspaceTargetsExhausted = 4008,
  PcoipSessionProvisionAuthFailed = 4009,

  // Range 5000-5999
  NotAuthenticated = 5000,
  PresessionGenericError = 5001,
  SmartcardAuthFailure = 5002,

  // Range 6000-6200 - Auth failures
  AuthServerFailure = 6000,
  AuthUserAuthFailure = 6001,
  AuthDeviceAuthFailure = 6002,
  AuthServiceUnavailable = 6003,
  AuthAccessDenied = 6004,
  AuthUnknown = 6005,
  AuthNoSessionToContinue = 6006,
  AuthNoDeviceCertificateFound = 6007,
  AuthDeviceCertificateChainCannotBeBuilt = 6008,
  AuthDeviceCannotSignNonceWithCert = 6009,
  AuthNoSigningCertificateFound = 6010,
  AuthTokenExpired = 6011,
  AuthUnauthorizedRequest = 6012,
  AuthWDPageTimeOut = 6013,
  WebBrowserFailure = 6014,
  AuthNullResponse = 6015,
  AuthEmptyResultCode = 6016,
  SSONotSupportedError = 6017,
  InvalidWarpDriveUrl = 6018,
  AuthWDPageRedirectMissingAuthCode = 6019,
  AuthNoRegCodeFound = 6020,
  AuthNoSessionIdFound = 6021,

  AuthWDUnknownError = 6022,
  AuthExternalRedirectUnknownError = 6023,
  AuthExternalWDRedirectUnknownError = 6024,
  AuthExternalSamlRedirectUnknownError = 6025,
  AuthExternalWDRedirectLoadTimeOutError = 6026,
  AuthExternalWDRedirectUnavailableError = 6027,
  AuthInvalidFallbackMethod = 6028,
  AuthExternalSamlRedirectSystemBrowserError = 6029,
  AuthExternalIdcRedirectUnknownError = 6030,

  GetResourceServerFailure = 7000,
  GetResourceNoResourceFound = 7001,
  GetResourceTerminatedError = 7002,
  GetResourceUnavailableError = 7003,
  GetResourceUnhealthyError = 7004,
  GetResourceAdminMaintenanceError = 7005,
  GetResourceMaintenanceError = 7006,
  GetResourceImagingError = 7007,

  // Range 8000-8099 - Allocate resources
  AllocateResourceServerFailure = 8000,
  AllocateResourceTimeOut = 8001,
  AllocateResourceHostVersionMismatched = 8002,
  AllocateResourceNoTargets = 8003,
  AllocateResourceUnknown = 8004,
  /**
   * For Individual WS - i.e. host in state that are not RUNNING or ADMIN_MAINTENANCE.
   * For WS Pool it has new meaning now:
   *   If customer is trying to connect to a session for pool is in failed state or pending_start state.
   *   When FMS could not reserve session and return sessionState = EXPIRED
   */
  AllocateResourceInvalidState = 8005,
  AllocateResourceInvalidArgument = 8006, // request to WsBroker is invalid. Likely malformed request caused by client bug.
  AllocateResourceOldClientVersion = 8007,
  AllocateResourceOldHostVersion = 8008,
  AllocateResourceFallbackLoginRequired = 8009,
  AllocateResourcePoolInsufficientCapacityFailure = 8010, // Customer connection sessions exceed the capacity
  AllocateResourceSessionCleanupInprogressFailure = 8011, // If customer is trying to reconnect to a session which is in pending_cleanup
  AllocateResourceUnsupportedTransportType = 8012,
  AllocateResourcePcoipWebAcessDenied = 8013,

  // Range 8100-8199 - Perform resource action
  PerformResourceActionServerFailure = 8100, // self service call failed server-side
  SwitchRunningModeRefreshResourceTimeOutFailure = 8101,
  PerformResourceActionRetryLater = 8102,

  MissingData = 9000,
  WspTimeout = 10000,
  WspUnsupported = 10001,
  WspUnknownMessageType = 10002,
  WspWrongMessageFormat = 10003,
  WspProvisionUnknownError = 10004,

  DnsGenericError = 11000,
  DnsNoDnsEntries = 11001,
  DnsNoTxtEntries = 11002,
  DnsInvalidEntries = 11003,
  DnsLookUpError = 11004,
  AuthReuseRegionChanged = 11005,

  // Range 12001 - 12999 RTC Communication
  RTCChannelTornDown = 12001,
  RTCChannelInvalidInput = 12002,
  RTCChannelUnsupportedChannel = 12003,
  PlatformDetailsInvalidInput = 12004,
  PlatformDetailsUnKnown = 12005,
  StreamContextInvalidInput = 12006,
  StreamContextUnKnown = 12007,
  StreamResponseInvalidInput = 12008,
  StreamResponseUnKnown = 12009,
  AuthContextInvalidInput = 6020,
  AuthContextUnknown = 6021,
  MetricPublishInvalidInput = 6022,
  SettingsUpdateInvalidInput = 6023,
  SettingsUpdateUnKnown = 6024,
  HealthCheckInvalidInput = 6025,
  HealthCheckResponseUnKnown = 6026,
  ResolveDNSDomainLookUPInvalidInput = 6027,
  ResolveDNSDomainLookUPUnknown = 6028,
  MigrateRegistrationCodesInvalidInput = 6029,
  DisplayWSUrl = 6030,

  // Range 13001 - 13999 Stream errors
  StreamingUnKnownError = 13001,
  PcoIPStreamingUnKnownError = 13002,
  MaxibonStreamingUnKnownError = 13003,
  HighlanderStreamingUnKnownError = 13004,

  MaxibonWebStreamingTransportError = 13005,
  MaxibonWebStreamingInternalServerError = 13006,
  MaxibonWebStreamingProtocolError = 13007,
  MaxibonWebStreamingAuthDeniedError = 13008,
  MaxibonWebStreamingAuthRevokedError = 13009,
  MaxibonWebStreamingAccessRejected = 13010,
  MaxibonWebStreamingIdleTimeoutExpired = 13011,
  MaxibonWebStreamingDisconnectByOwner = 13012,
  MaxibonWebStreamingDisconnectByUser = 13013,
  MaxibonWebStreamingEvicted = 13014,
  MaxibonWebStreamingFallbackLoginRequired = 13015,
  MaxibonWebStreamingUnsupportedCredential = 13016,

  // Range 14000 - 14999 Heartbeat errors
  ReportHeartbeatGenericError = 14001,
  ReportHeartbeatRetriableError = 14002,
  ReportHeartbeatRequestTimeout = 14003,
  ReportHeartbeatMissingEndpoint = 14004,

  // Generic Errors 15000 - 15999
  ProtocolUnsupportedInRegion = 15000,
  WspProtocolUnsupportedOnPlatform = 15001,
  GenericProtocolUnsupportedOnPlatform = 15002,

  // Range 16000 - 16999 Log Uploader errors
  LogUploaderInvalidInputError = 16000,
}
