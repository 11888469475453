import { HttpRequestHeader } from '@core/http/HttpClient';
import {
  IAllocateResourceRequest,
  IAuthInfoRequest,
  IGetHeartBeatInfoRequest,
  IGetResourceRequest,
  IResourceType,
} from '@/core/wsbroker/types';
import { ClientErrorCode } from '@bridge/types/ErrorTypes';
import { WsErrorTypes } from '@core/error/WsErrorTypes';
import { WsErrorCodes } from '@core/error/WsErrorCodes';

export enum SERVER_ERROR_CODE {
  InvalidInput = 'INVALID_INPUT',
  ServiceUnavailable = 'SERVICE_UNAVAILABLE',
  DeviceAuthFailure = 'DEVICE_AUTH_FAILURE',
  UserAuthFailure = 'USER_AUTH_FAILURE',
  AccessDenied = 'ACCESS_DENIED',
  UnauthorizedAction = 'UNAUTHORIZED_ACTION',
  UnauthorizedRequest = 'UNAUTHORIZED_REQUEST',
  ResourceNotFound = 'RESOURCE_NOT_FOUND',
  UnauthorizedActionMinTime = 'UNAUTHORIZED_ACTION_MIN_TIME',
  UnauthorizedActionMinTimeCreate = 'UNAUTHORIZED_ACTION_MIN_TIME_CREATE',
  OldClientVersion = 'OLD_CLIENT_VERSION',
  OldHostVersion = 'OLD_HOST_VERSION',
  InvalidClientAndHostVersion = 'INVALID_CLIENT_AND_HOST_VERSION',
  WorkSpaceInvalidState = 'WORKSPACE_INVALID_STATE',
  RateExceeded = 'RATE_EXCEEDED',
  FallbackLoginRequired = 'FALLBACK_LOGIN_REQUIRED',
  PcoipWebAcessDenied = 'PCOIP_WEB_ACCESS_DENIED',
  PoolInsufficientCapacityFailure = 'POOL_INSUFFICIENT_CAPACITY_FAILURE',
  SessionCleanupInprogressFailure = 'SESSION_CLEANUP_INPROGRESS_FAILURE',
}

export const GetAuthInfoExceptionToClientErrorCodeMap = new Map<
  SERVER_ERROR_CODE,
  ClientErrorCode
>([
  [SERVER_ERROR_CODE.AccessDenied, ClientErrorCode.AuthInfoPlatformDenied],
  [SERVER_ERROR_CODE.InvalidInput, ClientErrorCode.AuthInfoRegCodeInvalid],
  [
    SERVER_ERROR_CODE.ServiceUnavailable,
    ClientErrorCode.AuthInfoServiceUnavailable,
  ],
]);

export const AuthenticateExceptionToClientErrorCodeMap = new Map<
  SERVER_ERROR_CODE,
  ClientErrorCode
>([
  [SERVER_ERROR_CODE.UserAuthFailure, ClientErrorCode.AuthUserAuthFailure],
  [SERVER_ERROR_CODE.DeviceAuthFailure, ClientErrorCode.AuthDeviceAuthFailure],
  [
    SERVER_ERROR_CODE.ServiceUnavailable,
    ClientErrorCode.AuthServiceUnavailable,
  ],
  [SERVER_ERROR_CODE.AccessDenied, ClientErrorCode.AuthAccessDenied],
  [
    SERVER_ERROR_CODE.UnauthorizedRequest,
    ClientErrorCode.AuthUnauthorizedRequest,
  ],
]);

/*
  ToDo: Check with broker team on UnauthorizedAction* errors and when they are thrown. These errors are not
  mapped correctly in singularity
*/
export const AllocateResourceToClientErrorCodeMap = new Map<
  SERVER_ERROR_CODE,
  ClientErrorCode
>([
  [
    SERVER_ERROR_CODE.InvalidInput,
    ClientErrorCode.AllocateResourceInvalidArgument,
  ],
  [
    SERVER_ERROR_CODE.WorkSpaceInvalidState,
    ClientErrorCode.AllocateResourceInvalidState,
  ],
  [
    SERVER_ERROR_CODE.ResourceNotFound,
    ClientErrorCode.AllocateResourceInvalidState,
  ],
  [
    SERVER_ERROR_CODE.OldClientVersion,
    ClientErrorCode.AllocateResourceOldClientVersion,
  ],
  [
    SERVER_ERROR_CODE.OldHostVersion,
    ClientErrorCode.AllocateResourceOldHostVersion,
  ],
  [
    SERVER_ERROR_CODE.FallbackLoginRequired,
    ClientErrorCode.AllocateResourceFallbackLoginRequired,
  ],
  [
    SERVER_ERROR_CODE.UnauthorizedActionMinTime,
    ClientErrorCode.AllocateResourceUnknown,
  ],
  [
    SERVER_ERROR_CODE.UnauthorizedActionMinTimeCreate,
    ClientErrorCode.AllocateResourceUnknown,
  ],
  [
    SERVER_ERROR_CODE.PoolInsufficientCapacityFailure,
    ClientErrorCode.AllocateResourcePoolInsufficientCapacityFailure,
  ],
  [
    SERVER_ERROR_CODE.SessionCleanupInprogressFailure,
    ClientErrorCode.AllocateResourceSessionCleanupInprogressFailure,
  ],
  [
    SERVER_ERROR_CODE.PcoipWebAcessDenied,
    ClientErrorCode.AllocateResourcePcoipWebAcessDenied,
  ],
]);

export const PerformResourceActionToClientErrorCodeMap = new Map<
  SERVER_ERROR_CODE,
  ClientErrorCode
>([
  [
    SERVER_ERROR_CODE.UnauthorizedActionMinTime,
    ClientErrorCode.PerformResourceActionRetryLater,
  ],
]);

export enum WSBrokerSessionProtocols {
  PCOIP_WEB = 'WEBRTC',
  PCOIP = 'PCOIP',
  WSP_WEB = 'Highlander_Web',
  WSP = 'Highlander',
  MAXIBON = 'DCV',
}

export enum WsBrokerAPI {
  GetAuthInfo = 'GetAuthInfo',
  Authenticate = 'Authenticate',
  GetResources = 'GetResources',
  AllocateResource = 'AllocateResource',
  PerformResourceAction = 'PerformResourceAction',
  GetHeartBeatInfo = 'GetHeartBeatInfo',
}

export class WSBrokerServiceConstants {
  static readonly ApiVersion = '1.0';

  static readonly DEFAULT_CONNECTION_MANAGER_URL =
    'https://ws-broker-service-beta-iad.iad.proxy.amazon.com';

  static readonly WS_BROKER_ALLOCATE_RESOURCE_API_WSP_PROTOCOL_VERSION =
    '2.0.0.11';

  static readonly WS_BROKER_API_TARGET_PREFIX =
    'com.amazonaws.wsbrokerservice.WSBrokerService.';

  static readonly WS_BROKER_SERVICE_API_HEADERS_TEMPLATE: HttpRequestHeader = {
    Accept: 'application/json, text/javascript, */*',
    'Cache-Control': 'no-cache',
    'Content-Encoding': 'amz-1.0',
    'X-Amz-Target': '',
  };

  static readonly WS_BROKER_SERVICE_GET_AUTH_INFO_REQ_BODY_TEMPLATE: IAuthInfoRequest =
    {
      Version: '1.0',
      AuthIdentifiers: {},
      ClientInfo: {
        Platform: '',
        AuthCapabilities: [],
        Name: '',
        Version: '',
        Identifier: '',
        PKCECodeChallenge: '',
      },
    };

  static readonly WS_BROKER_GET_RESOURCES_API_REQUEST_BODY_TEMPLATE: IGetResourceRequest =
    {
      Version: '1.0',
      SessionContext: {
        SessionId: '',
        AuthToken: {
          Value: '',
        },
      },
      AuthToken: {
        Value: '',
      },
    };

  static readonly WS_BROKER_GET_HEARTBEAT_INFO_API_REQUEST_BODY_TEMPLATE: IGetHeartBeatInfoRequest =
    {
      SessionId: '',
      AuthToken: {
        Value: '',
      },
    };

  static readonly WS_BROKER_ALLOCATE_RESOURCE_API_REQUEST_BODY_TEMPLATE: IAllocateResourceRequest =
    {
      Version: '1.0',
      SessionContext: {
        SessionId: '',
        AuthToken: {
          Value: '',
        },
      },
      AuthToken: {
        Value: '',
      },
      Resource: {
        ResourceType: IResourceType.WORKSPACE,
        ResourceId: '',
      },
      Action: {
        ActionType: 'CONNECT',
        ActionClientInput: {
          ClientIp: '127.0.0.1',
          ClientMacAddress: '00:00:00:00:00:00',
          Timezone: 'UTC',
        },
        ActionUserInput: {
          Protocol: '',
        },
      },
      WSPClientMetadata: [],
    };

  static readonly WSP_RESOURCE_ALLOCATION_CONFIG_SETTINGS =
    'Globals::LogToConsole=1\n' +
    'Globals::TheLogLevel=2\n' +
    'Globals::HostDomain=.\n' +
    'Globals::UseGatewaySecurity=0\n' +
    'Globals::UseCghSpa=1\n' +
    'Globals::UseGateway=0\n' +
    'Globals::UseWebRTC=1\n' +
    'Globals::ClientAudioSize=32\n' +
    'Globals::EnableAudioOut=1\n' +
    'Globals::VK_TYPE=0\n';

  static readonly API_TO_WS_ERROR_TYPE = new Map<WsBrokerAPI, WsErrorTypes>([
    [WsBrokerAPI.GetAuthInfo, WsErrorTypes.ERROR_TYPE_GETAUTHINFO],
    [WsBrokerAPI.Authenticate, WsErrorTypes.ERROR_TYPE_AUTHENTICATION],
    [WsBrokerAPI.GetResources, WsErrorTypes.ERROR_TYPE_GETRESOURCES],
    [WsBrokerAPI.AllocateResource, WsErrorTypes.ERROR_TYPE_ALLOCATE_RESOURCE],
    [
      WsBrokerAPI.PerformResourceAction,
      WsErrorTypes.ERROR_TYPE_SELF_SERVICE_ACTIONS,
    ],
  ]);

  static readonly SERVER_ERROR_TO_WS_ERROR_CODE = new Map<
    SERVER_ERROR_CODE,
    WsErrorCodes
  >([
    [SERVER_ERROR_CODE.InvalidInput, WsErrorCodes.ERROR_INVALID_INPUT],
    [
      SERVER_ERROR_CODE.ServiceUnavailable,
      WsErrorCodes.ERROR_SERVICE_UNAVAILABLE,
    ],
    [SERVER_ERROR_CODE.AccessDenied, WsErrorCodes.ERROR_PLATFORM_DENIED],
    [SERVER_ERROR_CODE.UserAuthFailure, WsErrorCodes.ERROR_USER_AUTH_FAILURE],
    [
      SERVER_ERROR_CODE.DeviceAuthFailure,
      WsErrorCodes.ERROR_DEVICE_AUTH_FAILURE,
    ],
    [
      SERVER_ERROR_CODE.UnauthorizedRequest,
      WsErrorCodes.ERROR_CM_SESSION_TIMEOUT,
    ],
    [SERVER_ERROR_CODE.ResourceNotFound, WsErrorCodes.ERROR_RESOURCE_NOT_FOUND],
    [
      SERVER_ERROR_CODE.UnauthorizedAction,
      WsErrorCodes.ERROR_UNAUTHORIZED_ACTION,
    ],
    [
      SERVER_ERROR_CODE.OldClientVersion,
      WsErrorCodes.ERROR_WSP_UNSUPPORTED_PROTOCOL,
    ],
    [
      SERVER_ERROR_CODE.FallbackLoginRequired,
      WsErrorCodes.ERROR_FALLBACK_LOGIN_REQUIRED,
    ],
    [
      SERVER_ERROR_CODE.WorkSpaceInvalidState,
      WsErrorCodes.ERROR_WORKSPACE_INVALID_STATE,
    ],
    [
      SERVER_ERROR_CODE.PoolInsufficientCapacityFailure,
      WsErrorCodes.ERROR_WS_POOL,
    ],
    [
      SERVER_ERROR_CODE.SessionCleanupInprogressFailure,
      WsErrorCodes.ERROR_WS_POOL,
    ],
  ]);
}
