import { WsError, WsErrorCodes, WsErrorTypes } from '@/bridge/WsError';
import { ClientErrorCode } from '@/bridge/types/ErrorTypes';
import { I18nKey } from '@/i18n/types';

type IErrorTypeToDetailsMap = {
  [key in WsErrorTypes]: I18nKey[];
};

type IErrorCodeToDetailsMap = {
  [key in WsErrorCodes]: I18nKey[];
};

type IClientErrorCodeToDetailsMap = {
  [key in ClientErrorCode]: I18nKey[];
};

// ToDo: Update Negotiation error once DeviceAuth support is complete
const ErrorTypeToDetailsMap: IErrorTypeToDetailsMap = {
  [WsErrorTypes.ERROR_TYPE_NEGOTIATION]: ['ws-neg-error'],
  [WsErrorTypes.ERROR_TYPE_REGISTRATION]: ['ws-reg-error'],
  [WsErrorTypes.ERROR_TYPE_WARPDRIVE]: ['ws-loading-error'],
  [WsErrorTypes.ERROR_TYPE_AUTHENTICATION]: ['ws-auth-error'],
  [WsErrorTypes.ERROR_TYPE_GETRESOURCES]: ['ws-get-resources-error'],
  [WsErrorTypes.ERROR_TYPE_GETAUTHINFO]: ['ws-get-auth-info-error'],
  [WsErrorTypes.ERROR_TYPE_UNSUPPORTED_PLATFORM]: [],
  [WsErrorTypes.ERROR_TYPE_CONNECTION]: [],
  [WsErrorTypes.ERROR_TYPE_STREAMING]: [],
  [WsErrorTypes.ERROR_TYPE_WSP]: [],
  [WsErrorTypes.ERROR_TYPE_UNKNOWN]: [],
  [WsErrorTypes.ERROR_TYPE_SESSION_PROVISION]: [],
  [WsErrorTypes.ERROR_TYPE_ALLOCATE_RESOURCE]: [],
  [WsErrorTypes.ERROR_TYPE_CUSTOMIZATION]: [],
  [WsErrorTypes.ERROR_TYPE_SELF_SERVICE_ACTIONS]: [],
  [WsErrorTypes.ERROR_TYPE_UNSUPPORTED_PROTOCOL]: [],
  [WsErrorTypes.ERROR_TYPE_HEARTBEAT]: [],
};

export const ClientErrorCodeToDetailsMap: IClientErrorCodeToDetailsMap = {
  [ClientErrorCode.RegCodeFormatInvalid]: [],
  [ClientErrorCode.RegCodeFormatUnsupportedByPlatform]: [],
  [ClientErrorCode.RegCodeRegionNotFound]: [],
  [ClientErrorCode.RegCodeRegionUnsupportedByStack]: [
    'ws-region-unsupported-by-stack',
  ],
  [ClientErrorCode.AuthInfoNotFound]: [],
  [ClientErrorCode.AuthInfoServerError]: [],
  [ClientErrorCode.AuthInfoUnknown]: [],
  [ClientErrorCode.AuthInfoPlatformDenied]: [],
  [ClientErrorCode.AuthInfoServiceUnavailable]: [],
  [ClientErrorCode.AuthInfoRegCodeInvalid]: [],
  [ClientErrorCode.AuthInfoInvalidResponse]: [],
  [ClientErrorCode.NoRegistrationFound]: [],
  [ClientErrorCode.NoAuthMethodsFound]: [],
  [ClientErrorCode.HttpClient3xxNetworkError]: [],
  [ClientErrorCode.HttpClient4xxNetworkError]: [],
  [ClientErrorCode.HttpClient5xxNetworkError]: [],
  [ClientErrorCode.HttpClientUnknownNetworkError]: [],
  [ClientErrorCode.HttpClientJsonSerializationError]: [],
  [ClientErrorCode.HttpClientSocketNetworkError]: [],
  [ClientErrorCode.HttpClientTimeoutError]: [],
  [ClientErrorCode.PcoipSessionProvisionUnknownError]: [],
  [ClientErrorCode.PcoipSessionProvisionInvalidServerResponse]: [],
  [ClientErrorCode.PcoipSessionProvisionNoTargets]: [],
  [ClientErrorCode.PcoipSessionProvisionServerError]: [],
  [ClientErrorCode.PcoipSessionProvisionUnableToConnect]: [],
  [ClientErrorCode.PcoipSessionProvisionNotSuccessful]: [],
  [ClientErrorCode.PcoipSessionProvisionWorkspaceUnreachable]: [],
  [ClientErrorCode.PcoipSessionProvisionWorkspaceInoperable]: [],
  [ClientErrorCode.PcoipSessionProvisionWorkspaceTargetsExhausted]: [],
  [ClientErrorCode.PcoipSessionProvisionAuthFailed]: [],
  [ClientErrorCode.NotAuthenticated]: [],
  [ClientErrorCode.PresessionGenericError]: [],
  [ClientErrorCode.SmartcardAuthFailure]: [],
  [ClientErrorCode.AuthServerFailure]: [],
  [ClientErrorCode.AuthUserAuthFailure]: [],
  [ClientErrorCode.AuthDeviceAuthFailure]: [],
  [ClientErrorCode.AuthServiceUnavailable]: [],
  [ClientErrorCode.AuthAccessDenied]: [],
  [ClientErrorCode.AuthUnknown]: [],
  [ClientErrorCode.AuthNoSessionToContinue]: [],
  [ClientErrorCode.AuthNoDeviceCertificateFound]: [],
  [ClientErrorCode.AuthDeviceCertificateChainCannotBeBuilt]: [],
  [ClientErrorCode.AuthDeviceCannotSignNonceWithCert]: [],
  [ClientErrorCode.AuthNoSigningCertificateFound]: [],
  [ClientErrorCode.AuthTokenExpired]: [],
  [ClientErrorCode.AuthUnauthorizedRequest]: [],
  [ClientErrorCode.AuthWDPageTimeOut]: [],
  [ClientErrorCode.WebBrowserFailure]: [],
  [ClientErrorCode.AuthNullResponse]: [],
  [ClientErrorCode.AuthEmptyResultCode]: [],
  [ClientErrorCode.SSONotSupportedError]: [],
  [ClientErrorCode.InvalidWarpDriveUrl]: [],
  [ClientErrorCode.AuthWDPageRedirectMissingAuthCode]: [],
  [ClientErrorCode.AuthNoRegCodeFound]: [],
  [ClientErrorCode.AuthNoSessionIdFound]: [],
  [ClientErrorCode.AuthWDUnknownError]: [],
  [ClientErrorCode.AuthExternalRedirectUnknownError]: [],
  [ClientErrorCode.AuthExternalWDRedirectUnknownError]: [],
  [ClientErrorCode.AuthExternalSamlRedirectUnknownError]: [],
  [ClientErrorCode.AuthExternalWDRedirectLoadTimeOutError]: [],
  [ClientErrorCode.AuthExternalWDRedirectUnavailableError]: [],
  [ClientErrorCode.AuthInvalidFallbackMethod]: [],
  [ClientErrorCode.GetResourceServerFailure]: [],
  [ClientErrorCode.GetResourceNoResourceFound]: [],
  [ClientErrorCode.GetResourceTerminatedError]: [
    'ws-resource-terminated-error',
  ],
  [ClientErrorCode.GetResourceUnavailableError]: [
    'ws-resource-unavailable-error',
  ],
  [ClientErrorCode.GetResourceUnhealthyError]: ['ws-resource-unhealthy-error'],
  [ClientErrorCode.GetResourceAdminMaintenanceError]: [
    'ws-resource-maintenance-error',
  ],
  [ClientErrorCode.GetResourceMaintenanceError]: [
    'ws-resource-maintenance-error',
  ],
  [ClientErrorCode.GetResourceImagingError]: ['ws-resource-imaging-error'],
  [ClientErrorCode.AllocateResourceServerFailure]: [],
  [ClientErrorCode.AllocateResourceTimeOut]: [],
  [ClientErrorCode.AllocateResourceHostVersionMismatched]: [],
  [ClientErrorCode.AllocateResourceNoTargets]: [],
  [ClientErrorCode.AllocateResourceUnknown]: [],
  [ClientErrorCode.AllocateResourceInvalidState]: [],
  [ClientErrorCode.AllocateResourceInvalidArgument]: [],
  [ClientErrorCode.AllocateResourceOldClientVersion]: [],
  [ClientErrorCode.AllocateResourceOldHostVersion]: [],
  [ClientErrorCode.AllocateResourceFallbackLoginRequired]: [],
  [ClientErrorCode.AllocateResourcePoolInsufficientCapacityFailure]: [
    'ws-pool-insufficient-capacity',
  ],
  [ClientErrorCode.AllocateResourceSessionCleanupInprogressFailure]: [
    'ws-session-cleanup-in-progress',
  ],
  [ClientErrorCode.AllocateResourcePcoipWebAcessDenied]: [],
  // TODO: see if can output time remaining to retry or change message
  [ClientErrorCode.PerformResourceActionRetryLater]: [
    'ws-my-workspace-change-compute-type-time-window-error-message',
  ],
  [ClientErrorCode.PerformResourceActionServerFailure]: [],
  [ClientErrorCode.SwitchRunningModeRefreshResourceTimeOutFailure]: [],
  [ClientErrorCode.MissingData]: [],
  [ClientErrorCode.WspTimeout]: [],
  [ClientErrorCode.WspUnsupported]: [],
  [ClientErrorCode.WspUnknownMessageType]: [],
  [ClientErrorCode.WspWrongMessageFormat]: [],
  [ClientErrorCode.WspProvisionUnknownError]: [],
  [ClientErrorCode.DnsGenericError]: [],
  [ClientErrorCode.DnsNoDnsEntries]: [],
  [ClientErrorCode.DnsNoTxtEntries]: [],
  [ClientErrorCode.DnsInvalidEntries]: [],
  [ClientErrorCode.DnsLookUpError]: [],
  [ClientErrorCode.AuthReuseRegionChanged]: [],
  [ClientErrorCode.RTCChannelTornDown]: [],
  [ClientErrorCode.RTCChannelInvalidInput]: [],
  [ClientErrorCode.RTCChannelUnsupportedChannel]: [],
  [ClientErrorCode.PlatformDetailsInvalidInput]: [],
  [ClientErrorCode.PlatformDetailsUnKnown]: [],
  [ClientErrorCode.StreamContextInvalidInput]: [],
  [ClientErrorCode.StreamContextUnKnown]: [],
  [ClientErrorCode.StreamResponseInvalidInput]: [],
  [ClientErrorCode.StreamResponseUnKnown]: [],
  [ClientErrorCode.MigrateRegistrationCodesInvalidInput]: [],
  [ClientErrorCode.DisplayWSUrl]: [],
  [ClientErrorCode.StreamingUnKnownError]: [],
  [ClientErrorCode.PcoIPStreamingUnKnownError]: [],
  [ClientErrorCode.MaxibonStreamingUnKnownError]: [],
  [ClientErrorCode.HighlanderStreamingUnKnownError]: [],
  [ClientErrorCode.MaxibonWebStreamingTransportError]: [],
  [ClientErrorCode.MaxibonWebStreamingInternalServerError]: [],
  [ClientErrorCode.MaxibonWebStreamingProtocolError]: [],
  [ClientErrorCode.MaxibonWebStreamingAuthDeniedError]: [],
  [ClientErrorCode.MaxibonWebStreamingAuthRevokedError]: [],
  [ClientErrorCode.MaxibonWebStreamingAccessRejected]: [],
  [ClientErrorCode.MaxibonWebStreamingIdleTimeoutExpired]: [],
  [ClientErrorCode.MaxibonWebStreamingDisconnectByOwner]: [],
  [ClientErrorCode.MaxibonWebStreamingDisconnectByUser]: [],
  [ClientErrorCode.MaxibonWebStreamingEvicted]: [],
  [ClientErrorCode.MaxibonWebStreamingFallbackLoginRequired]: [],
  [ClientErrorCode.MaxibonWebStreamingUnsupportedCredential]: [],
  [ClientErrorCode.ReportHeartbeatGenericError]: [],
  [ClientErrorCode.ReportHeartbeatRetriableError]: [],
  [ClientErrorCode.ReportHeartbeatRequestTimeout]: [],
  [ClientErrorCode.ReportHeartbeatMissingEndpoint]: [],
  [ClientErrorCode.ProtocolUnsupportedInRegion]: [],
  [ClientErrorCode.WspProtocolUnsupportedOnPlatform]: [],
  [ClientErrorCode.GenericProtocolUnsupportedOnPlatform]: [],
  [ClientErrorCode.LogUploaderInvalidInputError]: [],
  [ClientErrorCode.AllocateResourceUnsupportedTransportType]: [
    'ws-streaming-failed-generic-error',
    'ws-contact-admin-error',
  ],
};

const ErrorCodeToDetailsMap: IErrorCodeToDetailsMap = {
  [WsErrorCodes.ERROR_FORMAT]: ['ws-reg-format-error'],
  [WsErrorCodes.ERROR_PREFIX]: ['ws-reg-invalid-error'],
  [WsErrorCodes.ERROR_PREFIX_UNSUPPORTED_BY_PLATFORM]: [
    'ws-reg-unsupported-by-platform',
  ],
  [WsErrorCodes.ERROR_INVALID_INPUT]: ['ws-reg-invalid-error'],
  [WsErrorCodes.ERROR_INVALID_REG_CODE]: ['ws-reg-invalid-error'],
  [WsErrorCodes.ERROR_USER_AUTH_FAILURE]: ['ws-user-auth-failure'],
  [WsErrorCodes.ERROR_DEVICE_AUTH_FAILURE]: [
    'ws-device-auth-failure',
    'ws-try-again-error',
  ],
  [WsErrorCodes.ERROR_CM_ALLOC_RETRY_TIMEOUT]: ['ws-suspended-error'],
  [WsErrorCodes.ERROR_RESOURCE_NOT_FOUND]: ['ws-resource-not-found'],
  [WsErrorCodes.ERROR_TOKEN_EXPIRED]: [],
  [WsErrorCodes.ERROR_SERVICE_UNAVAILABLE]: ['ws-service-unavailable'],
  [WsErrorCodes.ERROR_CM_SESSION_TIMEOUT]: ['ws-cm-session-timeout'],
  [WsErrorCodes.ERROR_CM_REQUEST_FAILED]: [],
  [WsErrorCodes.ERROR_DEVICE_ACCESS_DENIED]: ['ws-os-error'],
  [WsErrorCodes.ERROR_PLATFORM_DENIED]: ['ws-os-error'],
  [WsErrorCodes.ERROR_UNSUPPORTED_PLATFORM]: [
    'ws-not-supported-browser-error',
    'ws-try-chrome-error',
  ],
  [WsErrorCodes.ERROR_PROTOCOL_UNSUPPORTED_PLATFORM]: [
    'ws-protocol-not-supported-browser-error',
    'ws-try-chrome-error',
  ],
  [WsErrorCodes.ERROR_PROTOCOL_UNSUPPORTED_PLATFORM_VERSION]: [
    'ws-protocol-not-supported-browser-version-error',
    'ws-try-chrome-error',
  ],
  [WsErrorCodes.ERROR_GET_LOCAL_REGCODE_FAILED]: ['ws-reg-unknown-error'],
  [WsErrorCodes.ERROR_UNSUPPORTED_PROTOCOL]: ['ws-not-supported-error'],
  [WsErrorCodes.ERROR_WSP_UNSUPPORTED_PROTOCOL]: ['ws-wsp-not-supported-error'],
  [WsErrorCodes.ERROR_PCOIP_UNSUPPORTED_PROTOCOL_IN_REGION]: [
    'ws-pcoip-unsupported-in-region',
  ],
  [WsErrorCodes.ERROR_PCOIP_UNSUPPORTED_ON_PLATFORM]: ['ws-pcoip-unsupported'],
  [WsErrorCodes.ERROR_UNSUPPORTED_RESULT_TYPE]: [],
  [WsErrorCodes.ERROR_UNSUPPORTED_AUTH_TYPE]: [
    'ws-auth-type-not-supported-error',
  ],
  [WsErrorCodes.ERROR_MISSING_PROTOCOL]: [],
  [WsErrorCodes.ERROR_WSP_SERVER_UNREACHABLE]: ['ws-workspace-unavailable'],
  [WsErrorCodes.ERROR_WSP_INVALID_SESSION_ID]: [
    'ws-workspace-connection-closed',
  ],
  [WsErrorCodes.ERROR_INVALID_SESSION_ID]: [],
  [WsErrorCodes.ERROR_WSP_AUTHENTICATION_FAILED]: ['ws-auth-error'],
  [WsErrorCodes.ERROR_PC_SETUP_FAILED]: [],
  [WsErrorCodes.ERROR_SIG_CREATE_FAILED]: [],
  [WsErrorCodes.ERROR_PC_CREATE_FAILED]: [],
  [WsErrorCodes.ERROR_SIG_START_FAILED]: [],
  [WsErrorCodes.ERROR_SIG_FAILED]: [],
  [WsErrorCodes.ERROR_SIG_TIMEOUT]: [],
  [WsErrorCodes.ERROR_PCOIP_LOADING_TIMEOUT]: [],
  [WsErrorCodes.ERROR_PC_FAILED]: [],
  [WsErrorCodes.ERROR_CAPEX_BAD_MSG]: [],
  [WsErrorCodes.ERROR_CAPEX_NO_VER]: [],
  [WsErrorCodes.ERROR_CAPEX_BAD_VER]: [],
  [WsErrorCodes.ERROR_CAPEX_NO_CONFIG]: [],
  [WsErrorCodes.ERROR_CAPEX_BAD_PEER]: [],
  [WsErrorCodes.ERROR_CAPEX_SELECT_FAILED]: [],
  [WsErrorCodes.ERROR_CAPEX_APPLY_FAILED]: [],
  [WsErrorCodes.ERROR_CAPEX_VERIFY_FAILED]: [],
  [WsErrorCodes.ERROR_UNKNOWN]: [],
  [WsErrorCodes.ERROR_UNAUTHORIZED_ACTION]: ['ws-unauthorized-action-error'],
  [WsErrorCodes.ERROR_SELF_SERVICE_RESTART]: [
    'ws-my-workspace-restart-error-message',
  ],
  [WsErrorCodes.ERROR_SELF_SERVICE_REBUILD]: [
    'ws-my-workspace-rebuild-error-message',
  ],
  [WsErrorCodes.ERROR_SELF_SERVICE_CHANGE_COMPUTE_TYPE]: [
    'ws-my-workspace-change-compute-type-error-message',
  ],
  [WsErrorCodes.ERROR_SELF_SERVICE_INCREASE_VOLUME_SIZE]: [
    'ws-my-workspace-increase-disk-size-error-message',
  ],
  [WsErrorCodes.ERROR_SELF_SERVICE_SWITCH_RUNNING_MODE]: [
    'ws-my-workspace-switch-running-mode-error-message',
  ],
  [WsErrorCodes.ERROR_REQUEST_TIMEOUT]: [],
  [WsErrorCodes.ERROR_HEARTBEAT_RETRIABLE]: [],
  [WsErrorCodes.ERROR_MISSING_HEARTBEAT_ENDPOINT]: [],
  [WsErrorCodes.ERROR_UNSUPPORTED_PCOIP_FOR_SAML]: [
    'ws-unsupported-pcoip-for-saml',
  ],
  [WsErrorCodes.ERROR_UNSUPPORTED_PCOIP_FOR_IDC]: [
    'ws-unsupported-pcoip-for-idc',
  ],
  [WsErrorCodes.ERROR_STREAMING_FAILED]: ['ws-streaming-failed-generic-error'],
  [WsErrorCodes.ERROR_WEB_MAXIBON_STREAMING_AUTH_DENIED]: [
    'ws-web-maxibon-streaming-failed-auth-denied',
  ],
  [WsErrorCodes.ERROR_WEB_MAXIBON_STREAMING_AUTH_REVOKED]: [
    'ws-web-maxibon-streaming-failed-auth-revoked',
  ],
  [WsErrorCodes.ERROR_WEB_MAXIBON_STREAMING_ACCESS_REJECTED]: [
    'ws-web-maxibon-streaming-failed-access-rejected',
  ],
  [WsErrorCodes.ERROR_WEB_MAXIBON_STREAMING_IDLE_TIMEOUT]: [
    'ws-web-maxibon-streaming-failed-idle-timeout',
  ],
  [WsErrorCodes.ERROR_WEB_MAXIBON_STREAMING_OWNER_DISCONNECT]: [
    'ws-web-maxibon-streaming-failed-owner-disconnect',
  ],
  [WsErrorCodes.ERROR_WEB_MAXIBON_STREAMING_USER_DISCONNECT]: [
    'ws-web-maxibon-streaming-failed-user-disconnect',
  ],
  [WsErrorCodes.ERROR_WEB_MAXIBON_STREAMING_EVICTED]: [
    'ws-web-maxibon-streaming-failed-streaming-evicted',
  ],
  [WsErrorCodes.ERROR_WEB_MAXIBON_STREAMING_UNSUPPORTED_CREDENTIAL]: [
    'ws-web-maxibon-streaming-failed-streaming-unsupported-credential',
  ],
  [WsErrorCodes.ERROR_FALLBACK_LOGIN_REQUIRED]: [
    'ws-web-maxibon-streaming-failed-streaming-fallback-login-required',
  ],
  [WsErrorCodes.ERROR_WARP_DRIVE_LOAD_TIMEOUT]: ['ws-warp-drive-load-timeout'],
  [WsErrorCodes.ERROR_WARP_DRIVE_UNAVAILABLE]: ['ws-warp-drive-unavailable'],
  [WsErrorCodes.ERROR_WARP_DRIVE_UNKNOWN]: ['ws-warp-drive-unknown-error'],
  [WsErrorCodes.ERROR_INVALID_FALLBACK_METHOD]: [
    'ws-authenticate-invalid-fallback-method',
  ],
  [WsErrorCodes.ERROR_WORKSPACE_INVALID_STATE]: ['ws-service-unavailable'],
  [WsErrorCodes.ERROR_WS_POOL]: [],
  [WsErrorCodes.ERROR_SOLO_SYSTEM_BROWSER_UNAVAILABLE]: [
    'ws-solo-system-browser-error',
  ],
};

interface II18nError {
  type: WsErrorTypes;
  code: WsErrorCodes;
  clientCode?: ClientErrorCode;
  localeKeys: I18nKey[];
}

export class I18nError implements II18nError {
  type: WsErrorTypes;
  code: WsErrorCodes;
  clientCode?: ClientErrorCode;
  localeKeys: I18nKey[];
  localeData: Record<string, Record<string, string>>;

  private static readonly _unknownError = new I18nError(
    WsError.getUnknownError()
  );

  // ToDo: Remove dependence on WsError code and use separate string for each error type.
  constructor(
    wsError: WsError,
    localeData?: Record<string, Record<string, string>>
  ) {
    this.type = wsError.errorType;
    this.code = wsError.errorCode;
    this.clientCode = wsError.clientErrorCode;
    this.localeData = localeData ?? {};

    if (
      wsError.wsErrorOptions?.useClientErrorCodeForLocalesOnly &&
      this.clientCode
    ) {
      this.localeKeys = [...ClientErrorCodeToDetailsMap[this.clientCode]];
    } else {
      this.localeKeys = [
        ...ErrorTypeToDetailsMap[this.type],
        ...ErrorCodeToDetailsMap[this.code],
      ];

      if (this.type === WsErrorTypes.ERROR_TYPE_CONNECTION) {
        if (this.code === WsErrorCodes.ERROR_PC_SETUP_FAILED) {
          this.localeKeys.push(
            'ws-starting-error',
            'ws-try-again-few-error',
            'ws-contact-admin-error'
          );
        } else if (this.code !== WsErrorCodes.ERROR_WSP_UNSUPPORTED_PROTOCOL) {
          this.localeKeys.push('ws-connecting-error', 'ws-contact-admin-error');
        }
      } else {
        // parse additional client error for self service only in order to avoid more changes
        // Since self-service is only available in Solo, limit it to only Solo.
        // TODO: revisit and see we can print more errors for other cases
        if (
          this.clientCode &&
          (this.type === WsErrorTypes.ERROR_TYPE_SELF_SERVICE_ACTIONS ||
            this.code === WsErrorCodes.ERROR_WS_POOL)
        ) {
          this.localeKeys.push(...ClientErrorCodeToDetailsMap[this.clientCode]);
        }
        this.localeKeys.push('ws-contact-admin-error');
      }
    }
  }

  /**
   * After passing through route state, instanceof type check is not safe anymore.
   * Duck type check is what we need here
   *
   * @param rawError
   * @param localeData
   * @returns I18nError
   */
  static getI18nError(
    rawError: any,
    localeData?: Record<string, Record<string, string>>
  ): I18nError {
    if (!rawError) {
      return this._unknownError;
    }

    if (rawError.type && rawError.code && rawError.localeKeys) {
      // I18nError
      return rawError;
    } else if (rawError.errorType && rawError.errorCode) {
      // WsError
      return new I18nError(rawError, localeData);
    } else {
      return this._unknownError;
    }
  }

  getConciseLocaleKeys(): I18nKey[] {
    const localeKeys = ErrorCodeToDetailsMap[this.code];
    if (localeKeys.length === 0) {
      localeKeys.push('ws-unknown-error', 'ws-contact-admin-error');
    }
    return localeKeys;
  }
}
