import { I18nResource } from '@/i18n/types';

const translation: I18nResource = {
  'ws-welcome': '아마존 WorkSpaces에 오신 것을 환영합니다.',
  'ws-options': '아래 옵션 중 하나를 선택하세요.',
  'ws-login': '로그인',
  'ws-remember-me': '로그인 유지',
  'ws-new-reg-code': '등록 코드 변경',
  'ws-back-login': '로그인 페이지로 돌아가기',
  'ws-fallback-login': '자격 증명으로 로그인',
  'ws-auth-reconnect': '재연결',
  'ws-disconnected-error':
    '문제가 발생했습니다. 도움이 필요하면 WorkSpaces 관리자에게 문의하세요.',
  'ws-get-started': '시작하려면 관리자가 제공한 등록 코드를 입력하세요.',
  'ws-register': '등록하기',
  'ws-pin': '고정',
  'ws-disconnect': '연결 해제',
  'ws-disconnected': '연결 해제됨',
  'ws-send-cad': 'CTRL-ALT-DEL 전송',
  'ws-enter-full-screen': '전체 화면 입력',
  'ws-exit-full-screen': '전체 화면 종료',
  'ws-error': '오류',
  'ws-console': 'WorkSpaces 콘솔',
  'ws-privacy': '개인 정보 보호',
  'ws-site-terms': '사이트 약관',
  'ws-cookie-pref': '쿠키 기본 설정',
  'ws-docs': '문서',
  'ws-reserved': '또는 그 계열사. 판권 소유.',
  'ws-neg-error': '시작하는 중에 오류가 발생했습니다.',
  'ws-reg-error': '등록하는 동안 오류가 발생했습니다.',
  'ws-reg-format-error': '등록 코드 형식이 잘못되었습니다.',
  'ws-reg-invalid-error': '등록 코드가 잘못되었습니다.',
  'ws-reg-unsupported-by-platform':
    '제공된 등록 코드는 이 클라이언트 버전에서 지원되지 않습니다. 자세한 내용은 관리자에게 문의하세요. ',
  'ws-reg-unknown-error': '알 수 없는 등록 코드입니다.',
  'ws-unknown-error': '알 수 없는 오류',
  'ws-loading-error':
    '인증 페이지 로딩 중 오류가 발생했습니다. WorkSpace가 포함된 디렉터리에 있는 이 OS/플랫폼의 활성화 여부를 확인하세요.',
  'ws-get-auth-info-error':
    '잘못된 등록 코드로 인해 인증 페이지 로딩 중 오류가 발생했습니다.',
  'ws-auth-error': '인증하는 동안 오류가 발생했습니다.',
  'ws-auth-type-not-supported-error': '인증 유형은 지원되지 않음',
  'ws-user-auth-failure':
    '오랫동안 사용하지 않았거나 새 비밀번호가 있을 수 있습니다. 다시 시도하세요.',
  'ws-device-auth-failure': '이 기기는 WorkSpaces에 액세스할 권한이 없습니다.',
  'ws-get-resources-error': '리소스를 가져오는 동안 오류가 발생했습니다.',
  'ws-resource-not-found':
    '사용자 이름으로 WorkSpace를 찾을 수 없습니다. 관리자에게 문의하여 요청하세요.',
  'ws-not-supported-error':
    '선택한 WorkSpace에 대한 웹 연결이 지원되지 않습니다.',
  'ws-suspended-error': '선택한 WorkSpace가 일시 중단된 것 같습니다.',
  'ws-resource-maintenance-error':
    'WorkSpace가 점검 중입니다. 여기에는 몇 시간이 소요될 수 있습니다. 자세한 내용은 관리자에게 문의하세요.',
  'ws-resource-terminated-error':
    '관리자가 WorkSpace를 종료했습니다. 자세한 내용은 관리자에게 문의하세요.',
  'ws-resource-unavailable-error':
    '사용자 이름으로 WorkSpace를 찾을 수 없습니다. 관리자에게 문의하여 요청하세요.',
  'ws-resource-unhealthy-error':
    '지금은 WorkSpace에 연결할 수 없습니다. 이는 현재 업데이트로 인해 발생했을 수 있습니다. 나중에 다시 시도하세요. 이 문제가 다시 발생하면 IT 지원팀에 문의하세요.',
  'ws-resource-imaging-error':
    'WorkSpace를 시작하는 동안 오류가 발생했습니다. 잠시 후 다시 연결해 보세요. 문제가 계속되면 관리자에게 문의하여 도움을 받으세요.',
  'ws-try-again-error': '나중에 다시 시도하세요.',
  'ws-starting-error':
    '지금은 WorkSpace에 연결할 수 없습니다.  WorkSpace를 시작할 때 이런 일이 발생할 수 있습니다.',
  'ws-try-again-few-error': '몇 분 후에 다시 시도하세요.',
  'ws-connecting-error': 'WorkSpace 연결 중에 오류가 발생했습니다.',
  'ws-service-unavailable':
    'WorkSpace를 시작할 수 없습니다. 다시 시도하세요. 도움이 필요한 경우 관리자에게 문의하세요.',
  'ws-workspace-unavailable':
    'WorkSpace를 현재 사용할 수 없습니다. 잠시 후 다시 시도하세요. 도움이 필요한 경우 관리자에게 문의하세요.',
  'ws-workspace-connection-closed':
    '비활성 상태로 인해 WorkSpace 연결이 종료되었습니다. 다시 연결해 주세요.',
  'ws-cm-session-timeout': '세션 시간이 초과되었습니다.',
  'ws-not-supported-browser-error':
    '이 기기/브라우저는 현재 WorkSpaces 웹 액세스가 지원되지 않습니다.',
  'ws-wsp-not-supported-error':
    '연결 중인 DCV 워크스페이스가 구형 에이전트 버전을 사용하고 있습니다. 해당 워크스페이스를 재부팅하거나 관리자에게 문의하여 소프트웨어 버전을 업데이트하세요.',
  'ws-pcoip-unsupported-in-region':
    '해당 WorkSpaces는 현재 이 플랫폼에서 PCoIP 프로토콜을 사용한 스트리밍을 지원하지 않는 지역에 속합니다. 대신 윈도우/맥OS/리눅스의 WorkSpaces 클라이언트를 사용하여 스트리밍하세요. 자세한 내용은 관리자에게 문의하세요.',
  'ws-pcoip-unsupported':
    '이 플랫폼에서는 PCoIP WorkSpaces 스트리밍이 지원되지 않습니다. 이 클라이언트를 Ubuntu 20에 설치하여 PCoIP WorkSpaces를 스트리밍하세요.',
  'ws-protocol-not-supported-browser-error':
    '사용 중인 브라우저 {{browser}}(은)는 {{protocol}}에 지원되지 않습니다.',
  'ws-protocol-not-supported-browser-version-error':
    '사용 중인 {{browser}} 버전 {{version}}(은)는 {{protocol}}에 지원되는 최저 버전 {{lowestSupportVersion}}보다 낮습니다.',
  'ws-try-chrome-error':
    '윈도우, OSX 또는 리눅스의 크롬(v55 이상) 또는 파이어폭스(v48 이상)에서 다시 시도하세요.',
  'ws-unhealthy-error': '선택한 WorkSpace가 비정상 상태입니다.',
  'ws-try-reboot-error': '먼저 WorkSpace를 재부팅해 보세요.',
  'ws-not-auth-device-error':
    '이 기기는 WorkSpace에 액세스할 수 있는 권한이 없습니다.',
  'ws-contact-admin-error': '문제가 지속되면 WorkSpaces 관리자에게 문의하세요.',
  'ws-resumed':
    'WorkSpace가 다시 시작되고 있습니다. 잠시 후에 사용할 수 있습니다.',
  'ws-not-optimized':
    '웹 클라이언트가 하드웨어에 완전히 최적화되지 않았습니다. 더 나은 스트리밍 경험을 위해 다음을 사용하세요.',
  'ws-native': 'WorkSpaces의 네이티브 클라이언트.',
  'ws-reg-code': '등록 코드',
  'ws-reg-codes': '등록 코드',
  'ws-os-error': '이 OS/플랫폼은 WorkSpaces에 액세스할 권한이 없습니다.',
  'ws-connecting': '연결하는 중...',
  'ws-authenticating': '인증 진행 중...',
  'ws-getting-resource': '리소스 가져오는 중...',
  'ws-allocating-resource': '리소스 할당 중...',
  'ws-sal-text': '로그인하여 액세스 권한 부여',
  'ws-play-header': '로그인 성공',
  'ws-play-description': '로그인 성공. 작업 공간으로 계속 이동합니다.',
  'ws-play-link': '계속하려면 클릭',
  'ws-my-workspace': '내 WorkSpace',
  'ws-my-workspace-about': '요약',
  'ws-my-workspace-restart': '다시 시작',
  'ws-my-workspace-change-compute-type': '컴퓨팅 유형 변경',
  'ws-my-workspace-increase-disk-size': '디스크 크기 늘리기',
  'ws-my-workspace-switch-running-mode': '실행 모드 변경',
  'ws-my-workspace-rebuild': '재구축',
  'ws-my-workspace-change-compute-type-title': '컴퓨팅 유형',
  'ws-my-workspace-increase-disk-size-title': '디스크 크기',
  'ws-my-workspace-switch-running-mode-title': '실행 모드',
  'ws-my-workspace-restart-error-message':
    'WorkSpace를 재시작하지 못했습니다. 나중에 다시 시도하세요.',
  'ws-my-workspace-rebuild-error-message':
    'WorkSpace를 재구축하지 못했습니다. 나중에 다시 시도하세요.',
  'ws-my-workspace-change-compute-type-error-message':
    'WorkSpace에서 컴퓨팅 유형을 변경하지 못했습니다. 나중에 다시 시도하세요.',
  'ws-my-workspace-change-compute-type-time-window-error-message':
    '컴퓨팅 유형은 규모 확대의 경우 6시간, 규모 축소의 경우 30일 이내에 한 번만 변경할 수 있습니다.',
  'ws-my-workspace-increase-disk-size-error-message':
    'WorkSpace가 볼륨 크기를 늘리지 못했습니다. 나중에 다시 시도하세요.',
  'ws-my-workspace-switch-running-mode-error-message':
    'WorkSpace가 실행 모드를 전환하지 못했습니다. 나중에 다시 시도하세요.',
  'ws-my-workspace-increase-disk-size-success-message':
    '디스크 크기를 성공적으로 늘렸습니다.',
  'ws-my-workspace-switch-running-mode-success-message':
    '실행 모드를 성공적으로 전환했습니다.',
  'ws-my-workspace-restart-disconnect-confirm':
    'WorkSpace를 재시작하면 현재 세션에서 사용자 연결이 끊기는 것을 알고 있습니다.',
  'ws-my-workspace-rebuild-disconnect-confirm':
    '재구축하면 내 데이터가 지워지고 가장 최신 백업으로 대체되며 현재 세션에서 모든 사용자의 연결이 끊어진다는 것을 알고 있습니다. 이 작업은 취소할 수 없습니다.',
  'ws-my-workspace-change-compute-type-disconnect-confirm':
    '컴퓨팅 유형을 변경하면 현재 세션에서 사용자 연결이 끊기는 것을 알고 있습니다.',
  'ws-my-workspace-about-registration-code': '등록 코드',
  'ws-my-workspace-about-region': '지역',
  'ws-my-workspace-about-workspace-name': 'WorkSpace 이름',
  'ws-my-workspace-about-id': 'ID',
  'ws-my-workspace-about-compute-type': '컴퓨팅 유형',
  'ws-my-workspace-about-status': '상태',
  'ws-my-workspace-about-workspace-ip-address': 'WorkSpace IP 주소',
  'ws-my-workspace-about-protocol': '프로토콜',
  'ws-my-workspace-restart-confirm': 'WorkSpace를 정말 재시작할까요?',
  'ws-my-workspace-restart-confirm-description':
    '데이터 손실을 방지하려면 WorkSpace를 재부팅하기 전에 열려 있는 모든 문서와 애플리케이션 파일을 저장하세요.',
  'ws-my-workspace-change-compute-type-current-key': '현재 컴퓨팅 유형',
  'ws-my-workspace-change-compute-type-in-use': '사용 중',
  'ws-my-workspace-change-compute-type-description':
    '컴퓨팅 유형을 변경하면 WorkSpace의 비용에 영향을 미친다는 점에 유의하세요.',
  'ws-my-workspace-change-compute-type-aria-label': '산출 유형 선택',
  'ws-my-workspace-increase-disk-size-current-key': '현재 디스크 크기',
  'ws-my-workspace-increase-disk-size-description':
    '디스크 크기를 변경하면 WorkSpace의 비용이 영향을 받는다는 점에 유의하세요.',
  'ws-my-workspace-increase-disk-size-info':
    '{{driveToIncrease}} 늘리기: 드라이브 크기를 {{currentVolumeSizeInGib}} GIB에서 {{newVolumeSizeInGib}} GIB로 확장',
  'ws-my-workspace-increase-disk-size-max-reached':
    '최대 디스크 크기에 도달했습니다.',
  'ws-my-workspace-switch-running-mode-current-key': '현재 실행 모드',
  'ws-my-workspace-switch-running-mode-auto-stop': 'AutoStop',
  'ws-my-workspace-switch-running-mode-auto-stop-description':
    '시간당 요금이 청구됩니다. WorkSpace는 로그인할 때 자동으로 시작됩니다.',
  'ws-my-workspace-switch-running-mode-always-on': 'AlwaysOn',
  'ws-my-workspace-switch-running-mode-always-on-description':
    '매월 청구됩니다. 항상 실행 중인 WorkSpace에 즉시 액세스할 수 있습니다.',
  'ws-my-workspace-switch-running-mode-description':
    '실행 모드를 변경하면 WorkSpace의 비용이 영향을 받는다는 점에 유의하세요.',
  'ws-my-workspace-rebuild-description':
    'WorkSpace를 재구축하시겠습니까? 먼저 내 WorkSpace를 재시작하여 잠재적 문제가 해결되는지 확인하는 것이 좋습니다. WorkSpace를 재구축하면 다음과 같은 작업이 수행됩니다.',
  'ws-my-workspace-rebuild-data-lost-warning-label':
    '현재 D: 드라이브에 있는 데이터를 가장 최근 백업의 데이터로 바꿉니다.',
  'ws-my-workspace-rebuild-data-lost-warning-content':
    '현재 데이터가 손실됩니다.',
  'ws-my-workspace-rebuild-user-drive-recreate-warning-label':
    'WorkSpace를 재구축하는 데 최대 한 시간이 걸립니다.',
  'ws-my-workspace-rebuild-user-drive-recreate-warning-content':
    '재구축이 완료될 때까지 내 WorkSpace를 사용하거나 변경할 수 없습니다.',
  'ws-region-name-iad': '미국 동부 (버지니아 북부)',
  'ws-region-name-pdx': '미국 서부 (오리건)',
  'ws-region-name-dub': 'EU (아일랜드)',
  'ws-region-name-syd': '아시아 태평양(시드니)',
  'ws-region-name-nrt': '아시아 태평양(도쿄)',
  'ws-region-name-sin': '아시아 태평양(싱가포르)',
  'ws-region-name-fra': 'EU(프랑크푸르트)',
  'ws-region-name-lhr': 'EU(런던)',
  'ws-region-name-yul': '캐나다(중부)',
  'ws-region-name-gru': '남미(상파울루)',
  'ws-region-name-icn': '아시아 태평양(서울)',
  'ws-region-name-cpt': '아프리카(케이프타운)',
  'ws-region-name-pdt': 'AWS GovCloud(미국 서부)',
  'ws-region-name-bom': '아시아 태평양(뭄바이)',
  'ws-region-name-osu': 'AWS GovCloud(미국 동부)',
  'ws-region-name-tlv': '이스라엘(텔아비브)',
  'ws-region-name-cdg': '유럽(파리)',
  'ws-unauthorized-action-error':
    '이 작업을 수행할 권한이 없습니다. 관리자에게 문의하세요.',
  'ws-support': '지원',
  'ws-logo-alt': '아마존 WorkSpaces 로고',
  'ws-disconnect-icon-alt': '연결 해제를 나타내는 점선 구름 아이콘',
  'ws-menubar-logo-alt': '메뉴바의 아마존 WorkSpaces 로고',
  'ws-menubar-full-screen-toggle-icon-alt': '메뉴바의 전체 화면 전환 아이콘',
  'ws-menubar-send-cad-icon-alt': '메뉴바의 CTRL-ALT-DEL 전송 아이콘',
  'ws-menubar-disconnect-icon-alt': '메뉴바의 연결 해제 버튼 아이콘',
  'ws-menubar-pin-icon-alt': '메뉴바 고정 또는 고정 해제 아이콘',
  'ws-connect-status-label': 'WorkSpace 상태',
  'ws-connect-status-negotiation': '초기화 중',
  'ws-connect-status-registration': '작업 공간 등록',
  'ws-connect-status-hello': '인증 기능 검색',
  'ws-connect-status-customization': '브랜딩 세부 정보 검색',
  'ws-connect-status-warp-drive': '인증 포털로 리디렉션',
  'ws-connect-status-warp-drive-complete': '인증 포털 리디렉션 완료',
  'ws-connect-status-saml': '외부 인증 공급자로 리디렉션',
  'ws-connect-status-authenticate': '인증 진행 중',
  'ws-connect-status-get_resource': '리소스 가져오기',
  'ws-connect-status-allocate-resource': '리소스 할당',
  'ws-connect-status-connect': '연결 중',
  'ws-connect-status-resume': 'WorkSpaces 초기화',
  'ws-connect-status-resume-pending': 'WorkSpaces 시작',
  'ws-connect-status-streaming': '스트리밍 세션',
  'ws-connect-status-disconnect': '세션 연결 해제됨',
  'ws-connect-status-stream-context-received': '스트리밍 요구 사항 생성 중...',
  'ws-footer-aws-link-label': 'AWS 메인 페이지 링크',
  'ws-language-label': '언어',
  'ws-brand-description': '완전 관리형 원격 데스크톱 서비스',
  'ws-registration-page-title': '시작하기',
  'ws-login-description': '로그인하여 작업 공간에 액세스하세요.',
  'ws-solo-saml-login-desc':
    '브라우저로 이동한 후 로그인을 완료할 수 있도록 여기로 다시 안내해 드립니다.',
  'ws-progress-bar': '로딩 진행률 표시줄',
  'ws-close-label': '모달 닫기',
  'ws-spinner-no-error-message':
    '오류가 발견되지 않았습니다. 등록 페이지로 다시 리디렉션 중입니다.',
  'ws-spinner-login-error-message': '로그인 오류.',
  'ws-spinner-redirecting-message': '리디렉션 중...',
  'ws-spinner-connecting-message': '연결 중',
  'ws-loglevel-label-info': '표준 로깅(기본값)',
  'ws-loglevel-label-debug': '고급 로깅',
  'ws-log-settings-title': '진단 로깅',
  'ws-device-id': '기기 ID',
  'ws-log-setting-preference': '진단 로깅',
  'ws-log-preference-title': '로그 수준',
  'ws-log-preference-description':
    '세션 로그에 제공하려는 데이터 양에 따라 로깅 기본 설정 수준을 선택합니다.',
  'ws-info-loglevel-description':
    '기기, 인터넷 연결 및 스트리밍 성능 메트릭에 대한 진단 정보가 포함된 로그 파일을 생성합니다.',
  'ws-debug-loglevel-description':
    '추가된 디버깅 수준의 세부 정보와 자세한 성능 데이터와 함께 표준 로깅 파일과 동일한 정보를 포함하는 로그 파일을 생성합니다. 참고: 고급 로깅은 DCV 워크스페이스에서만 지원됩니다.',
  'ws-loguploader-description':
    '진단 로깅을 활성화하면 WorkSpaces 클라이언트가 WorkSpaces 로그를 자동으로 업로드할 수 있습니다. 로그의 정보는 문제를 해결하는 데 도움이 될 수 있습니다. 이러한 로그에는 기기 및 인터넷 연결에 대한 정보가 포함됩니다.',
  'ws-loguploader-enabled': '활성화됨',
  'ws-loguploader-disabled': '비활성화됨',
  'ws-modal-close': '닫기',
  'ws-loguploader-copy': '복사',
  'ws-settings': '설정',
  'ws-close-settings-button-label': '모달 닫기',
  'ws-close-settings-buuton-name': '닫기',
  'ws-save-reg-code': '등록 코드 저장',
  'ws-save-reg-code-toggle-description':
    '   WorkSpaces를 활성화 또는 비활성화하여 등록 코드를 저장합니다.',
  'ws-enabled-save': '활성화됨',
  'ws-disabled-save': '비활성화됨',
  'ws-rename-description':
    'WorkSpaces 이름을 변경합니다. 이름은 식별자로 등록 코드를 대체합니다.',
  'ws-custom-name-input-title': 'WorkSpaces 이름',
  'ws-custom-name-input-placeholder': 'WorkSpaces 이름',
  'ws-input-char-limit-description': '최대 20자',
  'ws-remember-me-settings': '종료하거나 세션이 만료될 때까지 로그인 유지',
  'ws-settings-save-button': '저장',
  'ws-saved-reg-codes-title': '저장된 등록 코드',
  'ws-default-title': '(기본 설정)',
  'ws-reg-code-settings-dropdown-label': '등록 코드 설정',
  'ws-reg-code-delete-name': '삭제',
  'ws-set-as-default-name': '기본으로 설정',
  'ws-disable-save-modal-message':
    '등록 코드를 저장하지 않도록 WorkSpaces를 비활성화하면 현재 등록 코드가 모두 삭제되고 이후 등록 코드가 저장되지 않습니다. 등록 코드 저장을 비활성화하시겠습니까?',
  'ws-disable-save-modal-title': '등록 코드 저장 비활성화',
  'ws-disable-save-modal-aria-label': '저장 비활성화 모드 닫기',
  'ws-disable-alret-message':
    '등록 코드를 저장하도록 WorkSpaces를 활성화하면 WorkSpace에 로그인할 때마다 등록 코드를 입력할 필요가 없습니다.',
  'ws-delete-reg-code-modal-text': '다음을 삭제하시겠습니까?',
  'ws-delete-reg-code-modal-aria-text': '등록 코드 삭제 모달 닫기',
  'ws-reg-code-input-placeholder': '등록 코드 입력',
  'ws-reg-code-alias-error':
    '해당 이름의 WorkSpaces가 이미 있습니다. 다른 이름을 입력하세요.',
  'ws-autosuggest-no-mtaches-found': '일치하는 항목 없음',
  'ws-ok-button': '확인',
  'ws-delete-button': '삭제',
  'ws-disable-button': '비활성화',
  'ws-cancel-button': '취소',
  'ws-warning': '경고',
  'ws-alert-text-keep-me-logged-in':
    ' 다음 로그인 후부터 ‘로그인 유지’가 활성화됨',
  'ws-no-reg-code-saved-description':
    '등록 코드가 저장되지 않았습니다. 등록 코드로 로그인하면 여기에 저장됩니다.',
  'ws-healthcheck-tcp-fail':
    'TCP 포트를 통한 연결에 실패했습니다. 설정 확인 후 다시 시도하세요.',
  'ws-healthcheck-udp-fail':
    'UDP 포트를 통한 연결에 실패했습니다. 설정 확인 후 다시 시도하세요.',
  'ws-healthcheck-tcp-partial':
    'UDP 포트 {{connectedPort}}을(를) 통한 연결이 설정되었습니다. UDP 포트 {{failedPort}}에 대한 아웃바운드 연결을 설정할 수 없습니다.',
  'ws-healthcheck-udp-partial':
    'UDP 포트 {{connectedPort}}을(를) 통한 연결이 설정되었습니다. UDP 포트 {{failedPort}}에 대한 아웃바운드 연결을 설정할 수 없습니다.',
  'ws-healthcheck-tcp-roundtrip':
    'TCP의 왕복 트립 시간은 {{tcpRoundTripTime}}ms입니다. 네트워크 속도가 느려서 지연이 예상됩니다.',
  'ws-healthcheck-udp-roundtrip':
    'UDP의 왕복 트립 시간은 {{udpRoundTripTime}}ms입니다. 네트워크 속도가 느려서 지연이 예상됩니다.',
  'ws-healthcheck-connection-failed':
    '{{healthcheckRegionName}} 지역 워크스페이스 서비스 HTTPS 연결에 실패했습니다. IT 관리자에게 문의하세요.',
  'ws-unsupported-pcoip-for-saml':
    '웹 클라이언트의 SAML에는 WorkSpaces PCoIP이 지원되지 않습니다. 기본 WorkSpaces 애플리케이션을 다운로드하고 로그인합니다.',
  'ws-unsupported-pcoip-for-idc':
    '웹 클라이언트의 IDC에서는 WorkSpaces PCoIP가 지원되지 않습니다. 기본 WorkSpaces 애플리케이션을 다운로드하고 로그인하세요.',
  'ws-streaming-failed-generic-error':
    '스트리밍 중에 예상치 못한 오류가 발생했습니다. 잠시 후 다시 시도하세요.',
  'ws-warp-drive-load-timeout':
    '인증 포털로 리디렉션할 수 없습니다. 시간 초과로 인해 작업에 실패했습니다. 잠시 후 다시 시도하고 문제가 지속되면 IT 관리자에게 문의하세요.',
  'ws-warp-drive-unavailable':
    '인증 포털로 리디렉션할 수 없습니다. 네트워크 및 방화벽 설정을 확인하고 다시 시도하세요. 문제가 지속되면 IT 관리자에게 문의하세요.',
  'ws-warp-drive-unknown-error':
    '인증 포털로 리디렉션할 수 없습니다. 알 수 없는 오류가 발생했습니다. 잠시 후 다시 시도하고 문제가 지속되면 IT 관리자에게 문의하세요.',
  'ws-solo-system-browser-error':
    '인증 포털로 리디렉션하기 위해 웹 브라우저를 시작할 수 없습니다. IT 관리자에게 문의하여 WorkSpaces URI 액세스와 호환되는 브라우저가 설치되어 있는지 확인하세요.',
  'ws-registration-page-main-title': '시작하기 - 아마존 WorkSpace',
  'ws-login-page-main-title': '로그인 - 아마존 워크스페이스',
  'ws-disconnect-page-main-title': '연결 끊김 - 아마존 워크스페이스',
  'ws-name': '아마존 워크스페이스',
  'ws-web-maxibon-streaming-failed-auth-denied':
    '스트리밍 중에 예상치 못한 오류가 발생했습니다. 현재 세션에 대한 권한 부여가 거부되어 서버에서 WorkSpaces 스트리밍 연결을 종료했습니다.',
  'ws-web-maxibon-streaming-failed-auth-revoked':
    '스트리밍 중에 예상치 못한 오류가 발생했습니다. 현재 세션에 대한 권한 부여가 더 이상 유효하지 않아 서버에서 WorkSpaces 스트리밍 연결을 종료했습니다.',
  'ws-web-maxibon-streaming-failed-access-rejected':
    '스트리밍 중에 예상치 못한 오류가 발생했습니다. 잠시 후 다시 시도하세요.',
  'ws-web-maxibon-streaming-failed-idle-timeout':
    '활동이 없어서 연결이 끊겼습니다.',
  'ws-web-maxibon-streaming-failed-owner-disconnect':
    '서버에서 WorkSpaces 스트리밍 연결을 종료했습니다.',
  'ws-web-maxibon-streaming-failed-user-disconnect':
    'WorkSpace가 다른 클라이언트 세션에서 스트리밍되고 있기 때문에 WorkSpaces 스트리밍 연결이 닫혔습니다.',
  'ws-web-maxibon-streaming-failed-streaming-evicted':
    '새 연결 시작으로 인해 WorkSpace 스트리밍 연결이 닫혔습니다.',
  'ws-oem-overlay-title': '시작 중...',
  'ws-oem-overlay-description': '인증 계속 진행',
  'ws-web-maxibon-streaming-failed-streaming-unsupported-credential':
    'WorkSpaces를 승인하는 중 문제가 발생했습니다. 기업 자격 증명으로 로그인',
  'ws-web-maxibon-streaming-failed-streaming-fallback-login-required':
    'WorkSpace를 시작하는 동안 예상치 못한 오류가 발생했습니다. 기업 자격 증명으로 로그인하세요.',
  'ws-authenticate-invalid-fallback-method':
    '대체 방법이 유효하지 않습니다. 회사 자격 증명 로그인으로 대체하려는 시도가 실패했습니다.',
  'ws-error-alert-icon': '오류 알림 아이콘',
  'ws-save-reg-codes-enabled': '등록 코드 저장, 활성화됨',
  'ws-save-reg-code-disabled': '등록 코드 저장, 비활성화됨',
  'ws-pool-insufficient-capacity':
    '세션에 사용할 수 있는 스트리밍 리소스가 없습니다. 몇 분 후에 다시 시도하세요.',
  'ws-session-cleanup-in-progress':
    "이전 세션이 아직 종료되지 않았습니다. 몇 분 후에 ‘다시 시도'를 선택하여 다시 연결해 보세요.",
  'ws-region-unsupported-by-stack':
    '사용된 등록 코드는 이 Workspaces 웹 클라이언트 엔드포인트에서 지원되지 않습니다. 프로덕션 엔드포인트를 사용하여 프로덕션 리소스를 스트리밍합니다.',
  'ws-pcoip-access-denied-description':
    '이 WorkSpaces에 계속 연결하려면 WorkSpaces 클라이언트 애플리케이션을 다운로드하거나 관리자에게 문의하여 WorkSpace를 업데이트하세요.',
  'ws-pcoip-access-denied-title':
    '더 이상 웹 액세스를 사용하여 이 PCoIP WorkSpace에 연결할 수 없습니다.',
  'ws-pcoip-access-denied-header': 'Connection method not supported',
};

export default translation;
