import { WsError, WsErrorCodes, WsErrorTypes } from '@/bridge/WsError';
import { ExternalAuthUrlBuilder } from './ExternalAuthUrlBuilder';
import { SoloNativeRTCChannel } from '@/core/rtcChannel/SoloNativeRTCChannel';
import { WarpDriveExternalNativeAuthUrlBuilder } from '@/presession/authentication/authUrls/WarpDriveExternalNativeAuthUrlBuilder';
import { ILogger } from '@bridge/ILogger';

export class ExternalAuthUrlDirector {
  static ADFS_SAML_BASE_URL_REGEX = /RPID=.*?=(.*)$/;

  private readonly logger: ILogger;

  constructor(logger: ILogger) {
    this.logger = logger;
  }

  buildWarpDriveUrl(baseUrl: string) {
    let builder;
    if (SoloNativeRTCChannel.isChannelAvailable()) {
      builder = new WarpDriveExternalNativeAuthUrlBuilder(baseUrl);
    } else {
      builder = new ExternalAuthUrlBuilder(baseUrl);
    }
    builder.setRedirectUri('warpdrive');
    builder.setLocale();
    builder.setCsrfToken();
    return builder.getUrl();
  }

  buildSamlUrl(baseUrl: string) {
    const IdPUrl = new URL(baseUrl);

    // In Solo SAML we don't need to worry about altering the RelayState
    if (SoloNativeRTCChannel.isChannelAvailable()) {
      return IdPUrl;
    }
    // TODO: remove the fallback and add a check here to make sure we validate this search param.
    const relayStateName = IdPUrl.searchParams.get('RelayStateName');
    if (!relayStateName?.trim()) {
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_AUTHENTICATION,
        WsErrorCodes.ERROR_INVALID_INPUT
      );
    }
    const relayStateUrl = IdPUrl.searchParams.get(relayStateName);
    if (!relayStateUrl) {
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_AUTHENTICATION,
        WsErrorCodes.ERROR_INVALID_INPUT
      );
    }
    /* According to https://docs.aws.amazon.com/workspaces/latest/adminguide/setting-up-saml.html?icmpid=docs_console_unmapped#enable-integration-saml
       The default relayStateName is "RelayState".
       When the customer is using ADFS, manipulating the baseUrl to include web redirection URL is very hacky due to
       the format of baseUrl. Hence web will not manipulate the URL and lean on EUC SSO to handle this case and redirect
       to the correct web url based on service region. This is similar to what we do for IDC.
    */
    if (this.isAdfsSamlScenario(relayStateUrl)) {
      this.logger.info(
        `Identified ADFS SAML scenario. Redirecting to ${baseUrl}`
      );
      return new ExternalAuthUrlBuilder(baseUrl).getUrl();
    }

    // build relay state url
    const relayStateBuilder = new ExternalAuthUrlBuilder(relayStateUrl);
    relayStateBuilder.setRedirectUri('saml');

    // build IdP url
    const IdPBuilder = new ExternalAuthUrlBuilder(baseUrl);
    IdPBuilder.setRelayStateUrl(
      relayStateName,
      relayStateBuilder.getUrl().toString()
    );

    return IdPBuilder.getUrl();
  }

  buildIdcUrl(baseUrl: string) {
    return new ExternalAuthUrlBuilder(baseUrl).getUrl();
  }

  /*
    Example ADFS URL : https://onprem-adfs.local.ad/adfs/ls/idpinitiatedsignon.aspx?RelayState=RPID%3Dhttps%3A%2F%2Fsignin.aws.amazon.com%2Fsaml%26RelayState%3Dhttps%3A%2F%2Fworkspaces.euc-sso.ap-southeast-2.aws.amazon.com%2Fsaml2%2Fstart%3Fstate%3DcGtjZWNvZGU9U1FyMVF6b3JYakxtU25xS2pYR2d2VEpxaVlCSTlxZ0dnUlpHRFFUYjFPQSZzZXNzaW9uaWQ9NTAyYmYzYTA4N2YwYTBlNDRkMWM3ZWM5OGExOTUyYTE%3D&state=cGtjZWNvZGU9U1FyMVF6b3JYakxtU25xS2pYR2d2VEpxaVlCSTlxZ0dnUlpHRFFUYjFPQSZzZXNzaW9uaWQ9NTAyYmYzYTA4N2YwYTBlNDRkMWM3ZWM5OGExOTUyYTE%3D&RelayStateName=RelayState
  */
  isAdfsSamlScenario(relayStateUrl: string): boolean {
    return (
      relayStateUrl.match(ExternalAuthUrlDirector.ADFS_SAML_BASE_URL_REGEX) !==
      null
    );
  }
}
