import { I18nResource } from '@/i18n/types';

const translation: I18nResource = {
  'ws-welcome': 'Welcome to Amazon WorkSpaces.',
  'ws-options': 'Please choose one of the options below:',
  'ws-login': 'Sign in',
  'ws-remember-me': 'Keep me logged in',
  'ws-new-reg-code': 'Change registration code',
  'ws-back-login': 'Return to sign in page',
  'ws-fallback-login': 'Sign in with credentials',
  'ws-auth-reconnect': 'Reconnect',
  'ws-disconnected-error':
    'Something went wrong. Contact your WorkSpaces administrator for help',
  'ws-get-started':
    'To get started enter the registration code provided to you by your administrator.',
  'ws-register': 'Register',
  'ws-pin': 'Pin',
  'ws-disconnect': 'Disconnect',
  'ws-disconnected': 'Disconnected',
  'ws-send-cad': 'Send CTRL-ALT-DEL',
  'ws-enter-full-screen': 'Enter full screen',
  'ws-exit-full-screen': 'Exit full screen',
  'ws-error': 'Error',
  'ws-console': 'WorkSpaces Console',
  'ws-privacy': 'Privacy',
  'ws-site-terms': 'Site terms',
  'ws-cookie-pref': 'Cookie preferences',
  'ws-docs': 'Documentation',
  'ws-reserved': 'or its affiliates. All rights reserved.',
  'ws-neg-error': 'An error occurred while initiating.',
  'ws-reg-error': 'An error occurred while registering.',
  'ws-reg-format-error': 'Invalid registration code format.',
  'ws-reg-invalid-error': 'Invalid registration code.',
  'ws-reg-unsupported-by-platform':
    'Registration code provided is unsupported by this version of client. Contact your administrator for more details. ',
  'ws-reg-unknown-error': 'Unknown registration code.',
  'ws-unknown-error': 'Unknown Error',
  'ws-loading-error':
    'An error occurred while loading the Authentication page. Please make sure this OS/Platform is enabled in the directory containing the workSpaces.',
  'ws-get-auth-info-error':
    'An error occurred while loading the authentication page because of an invalid registration code.',
  'ws-auth-error': 'An error occurred while authenticating.',
  'ws-auth-type-not-supported-error': 'Authentication type is not supported',
  'ws-user-auth-failure':
    'You may have prolonged inactivity or a new password. Please try again.',
  'ws-device-auth-failure':
    'This device is not authorized to access your WorkSpace.',
  'ws-get-resources-error': 'An error occurred while getting resources.',
  'ws-resource-not-found':
    'No WorkSpace found under your username. Contact your administrator to request one.',
  'ws-not-supported-error':
    'Web connection to the selected WorkSpace is not supported.',
  'ws-suspended-error': 'The selected WorkSpace seems to be suspended.',
  'ws-resource-maintenance-error':
    'Your WorkSpace is under maintenance. This may take a few hours. Contact your administrator for details.',
  'ws-resource-terminated-error':
    'Your WorkSpace was terminated by your administrator. Contact your administrator for details.',
  'ws-resource-unavailable-error':
    'No WorkSpace found under your username. Contact your administrator to request one.',
  'ws-resource-unhealthy-error':
    "We can't connect to your WorkSpace right now. This may be caused by current updates. Please try again later. If this happens again, contact IT support.",
  'ws-resource-imaging-error':
    'An error occurred while launching your WorkSpace. Try reconnecting again in a moment. If this problem persists, contact your administrator for help.',
  'ws-try-again-error': 'Please try again later.',
  'ws-starting-error':
    'We cannot connect to your WorkSpace right now.  This can happen if your WorkSpace is starting up.',
  'ws-try-again-few-error': 'Please try again in a few minutes.',
  'ws-connecting-error':
    'An error occurred while connecting to your WorkSpace.',
  'ws-service-unavailable':
    "We couldn't launch your WorkSpace. Please try again. If you need help, contact your administrator.",
  'ws-workspace-unavailable':
    'WorkSpace is unavailable right now. Please try again after some time. If you need help, contact your administrator.',
  'ws-workspace-connection-closed':
    'WorkSpace connection closed due to inactivity. Please reconnect again.',
  'ws-cm-session-timeout': 'Session timed out.',
  'ws-not-supported-browser-error':
    'This device/browser is currently not supported for WorkSpaces web access.',
  'ws-wsp-not-supported-error':
    'The DCV WorkSpace you are connecting to is using an outdated agent version. Please reboot your WorkSpace or contact your administrator to update the software version.',
  'ws-pcoip-unsupported-in-region':
    'The WorkSpace belongs to a region that currently does not support streaming using PCoIP protocol on this platform. Use WorkSpaces client on Windows/MacOS/Linux to stream instead. Contact your administrator for more details',
  'ws-pcoip-unsupported':
    'PCoIP WorkSpace streaming is not supported on this platform. Install this client on Ubuntu 20 to stream PCoIP WorkSpaces',
  'ws-protocol-not-supported-browser-error':
    'Your browser - {{browser}} is not supported for {{protocol}}',
  'ws-protocol-not-supported-browser-version-error':
    'Your {{browser}} version - {{version}} is lower than our lowest support version - {{lowestSupportVersion}} for {{protocol}}.',
  'ws-try-chrome-error':
    'Please try again from Chrome (v55 or later) or Firefox (v48 or later) on Windows, OSX or Linux.',
  'ws-unhealthy-error': 'The selected WorkSpace is in an unhealthy state.',
  'ws-try-reboot-error': 'Please try rebooting your WorkSpace first.',
  'ws-not-auth-device-error':
    'This device is not authorized to access the WorkSpace.',
  'ws-contact-admin-error':
    'If the problem persists, contact your WorkSpaces administrator.',
  'ws-resumed':
    'Your WorkSpace is being resumed. It should be available to you in a moment.',
  'ws-not-optimized':
    'The web client is not fully optimized for your hardware. For a better streaming experience please use',
  'ws-native': 'the WorkSpaces native clients.',
  'ws-reg-code': 'Registration code',
  'ws-reg-codes': 'Registration codes',
  'ws-os-error': 'This OS/platform is not authorized to access your WorkSpace.',
  'ws-connecting': 'Connecting...',
  'ws-authenticating': 'Authenticating...',
  'ws-getting-resource': 'Getting Resource...',
  'ws-allocating-resource': 'Allocating Resource...',
  'ws-sal-text': 'Sign in to authorize access',
  'ws-play-header': 'Sign in successful',
  'ws-play-description': 'Sign in successful. Continue to your WorkSpace.',
  'ws-play-link': 'Click to continue',
  'ws-my-workspace': 'My WorkSpace',
  'ws-my-workspace-about': 'Summary',
  'ws-my-workspace-restart': 'Restart',
  'ws-my-workspace-change-compute-type': 'Change compute type',
  'ws-my-workspace-increase-disk-size': 'Increase disk size',
  'ws-my-workspace-switch-running-mode': 'Change running mode',
  'ws-my-workspace-rebuild': 'Rebuild',
  'ws-my-workspace-change-compute-type-title': 'Compute type',
  'ws-my-workspace-increase-disk-size-title': 'Disk size',
  'ws-my-workspace-switch-running-mode-title': 'Running mode',
  'ws-my-workspace-restart-error-message':
    'Your WorkSpace failed to restart. Please try again later.',
  'ws-my-workspace-rebuild-error-message':
    'Your WorkSpace failed to rebuild. Please try again later.',
  'ws-my-workspace-change-compute-type-error-message':
    'Your WorkSpace failed to change compute type. Please try again later.',
  'ws-my-workspace-change-compute-type-time-window-error-message':
    'You can only change your compute type once within a 6-hour window for scaling up and 30 days for scaling down.',
  'ws-my-workspace-increase-disk-size-error-message':
    'Your WorkSpace failed to increase volume size. Please try again later.',
  'ws-my-workspace-switch-running-mode-error-message':
    'Your WorkSpace failed to switch running mode. Please try again later.',
  'ws-my-workspace-increase-disk-size-success-message':
    'You successfully Increased the Disk Size.',
  'ws-my-workspace-switch-running-mode-success-message':
    'You successfully switched running mode',
  'ws-my-workspace-restart-disconnect-confirm':
    'I understand that restarting the WorkSpace disconnects users from the current session.',
  'ws-my-workspace-rebuild-disconnect-confirm':
    'I understand that rebuilding will erase my data, replace it with my most recent backup, and disconnect all users from the current session. This cannot be undone.',
  'ws-my-workspace-change-compute-type-disconnect-confirm':
    'I understand that changing the compute type disconnects users from the current session.',
  'ws-my-workspace-about-registration-code': 'Registration code',
  'ws-my-workspace-about-region': 'Region',
  'ws-my-workspace-about-workspace-name': 'WorkSpace name',
  'ws-my-workspace-about-id': 'ID',
  'ws-my-workspace-about-compute-type': 'Compute type',
  'ws-my-workspace-about-status': 'Status',
  'ws-my-workspace-about-workspace-ip-address': 'WorkSpace IP address',
  'ws-my-workspace-about-protocol': 'Protocol',
  'ws-my-workspace-restart-confirm':
    'Are you sure you want to restart your WorkSpace?',
  'ws-my-workspace-restart-confirm-description':
    'To avoid data loss, save all open documents and application files before rebooting the WorkSpace.',
  'ws-my-workspace-change-compute-type-current-key': 'Current compute type',
  'ws-my-workspace-change-compute-type-in-use': 'in use',
  'ws-my-workspace-change-compute-type-description':
    'Note that changing the compute type affects the cost of your WorkSpace.',
  'ws-my-workspace-change-compute-type-aria-label': 'Select compute type',
  'ws-my-workspace-increase-disk-size-current-key': 'Current disk size',
  'ws-my-workspace-increase-disk-size-description':
    'Note that changing the disk size affects the cost of your WorkSpace.',
  'ws-my-workspace-increase-disk-size-info':
    'Increase your {{driveToIncrease}}: drive size from {{currentVolumeSizeInGib}} GIB to {{newVolumeSizeInGib}} GIB',
  'ws-my-workspace-increase-disk-size-max-reached':
    'You have reached the maximum disk size.',
  'ws-my-workspace-switch-running-mode-current-key': 'Current running mode',
  'ws-my-workspace-switch-running-mode-auto-stop': 'AutoStop',
  'ws-my-workspace-switch-running-mode-auto-stop-description':
    'Billed by the hour. The WorkSpace starts automatically when you log in.',
  'ws-my-workspace-switch-running-mode-always-on': 'AlwaysOn',
  'ws-my-workspace-switch-running-mode-always-on-description':
    "Billed monthly. Instant access to a WorkSpace that's always running.",
  'ws-my-workspace-switch-running-mode-description':
    'Note that changing the running mode affects the cost of your WorkSpace.',
  'ws-my-workspace-rebuild-description':
    'Are you sure you want to rebuild your WorkSpace? We recommend restarting your WorkSpace first to see if that resolves potential issues. Rebuilding your WorkSpace does the following',
  'ws-my-workspace-rebuild-data-lost-warning-label':
    "Replaces what's currently on your D: drive with the data from your most recent backup.",
  'ws-my-workspace-rebuild-data-lost-warning-content':
    'Your current data will be lost.',
  'ws-my-workspace-rebuild-user-drive-recreate-warning-label':
    'Rebuilding your WorkSpace takes up to an hour.',
  'ws-my-workspace-rebuild-user-drive-recreate-warning-content':
    "You can't use or make changes to your WorkSpace until rebuilding is complete.",
  'ws-region-name-iad': 'US East (N. Virginia)',
  'ws-region-name-pdx': 'US West (Oregon)',
  'ws-region-name-dub': 'EU (Ireland)',
  'ws-region-name-syd': 'Asia Pacific (Sydney)',
  'ws-region-name-nrt': 'Asia Pacific (Tokyo)',
  'ws-region-name-sin': 'Asia Pacific (Singapore)',
  'ws-region-name-fra': 'EU (Frankfurt)',
  'ws-region-name-lhr': 'EU (London)',
  'ws-region-name-yul': 'Canada (Central)',
  'ws-region-name-gru': 'South America (Sao Paulo)',
  'ws-region-name-icn': 'Asia Pacific (Seoul)',
  'ws-region-name-cpt': 'Africa (Cape Town)',
  'ws-region-name-pdt': 'AWS GovCloud (US-West)',
  'ws-region-name-bom': 'Asia Pacific (Mumbai)',
  'ws-region-name-osu': 'AWS GovCloud (US-East)',
  'ws-region-name-tlv': 'Israel (Tel Aviv)',
  'ws-region-name-cdg': 'Europe (Paris)',
  'ws-unauthorized-action-error':
    "You're not authorized to perform this action. Contact your administrator.",
  'ws-support': 'Support',
  'ws-logo-alt': 'Amazon WorkSpaces logo',
  'ws-disconnect-icon-alt': 'A dotted cloud icon for disconnection',
  'ws-menubar-logo-alt': 'Amazon WorkSpaces logo on menubar',
  'ws-menubar-full-screen-toggle-icon-alt':
    'Icon for full screen toggle on menubar',
  'ws-menubar-send-cad-icon-alt': 'Icon for send CTRL-ALT-DEL on menubar',
  'ws-menubar-disconnect-icon-alt': 'Icon for diconnect button on menubar',
  'ws-menubar-pin-icon-alt': 'Icon for pin or unpin menubar',
  'ws-connect-status-label': 'WorkSpace status',
  'ws-connect-status-negotiation': 'Initializing',
  'ws-connect-status-registration': 'Registering WorkSpaces',
  'ws-connect-status-hello': 'Retrieving authcapabilities',
  'ws-connect-status-customization': 'Retrieving branding details',
  'ws-connect-status-warp-drive': 'Redirecting to Auth Portal',
  'ws-connect-status-warp-drive-complete': 'Auth Portal redirection complete',
  'ws-connect-status-saml': 'Redirecting to External Authentication Provider',
  'ws-connect-status-authenticate': 'Authenticating',
  'ws-connect-status-get_resource': 'Getting resource',
  'ws-connect-status-allocate-resource': 'Allocating resource',
  'ws-connect-status-connect': 'Connecting',
  'ws-connect-status-resume': 'Initializing WorkSpaces',
  'ws-connect-status-resume-pending': 'Launching WorkSpaces',
  'ws-connect-status-streaming': 'Streaming session',
  'ws-connect-status-disconnect': 'Session disconnected',
  'ws-connect-status-stream-context-received':
    'Generating streaming requirements...',
  'ws-footer-aws-link-label': 'Link to AWS main page',
  'ws-language-label': 'Language',
  'ws-brand-description': 'Fully managed remote desktop service',
  'ws-registration-page-title': 'Get started',
  'ws-login-description': 'Sign in to access your WorkSpace.',
  'ws-solo-saml-login-desc':
    'We will take you to your browser and bring you back here to complete sign-in.',
  'ws-progress-bar': 'A loading progress bar',
  'ws-close-label': 'Close modal',
  'ws-spinner-no-error-message':
    'No error found. Redirecting back to registration page',
  'ws-spinner-login-error-message': 'Sign in Error.',
  'ws-spinner-redirecting-message': 'Redirecting...',
  'ws-spinner-connecting-message': 'Connecting',
  'ws-loglevel-label-info': 'Standard logging (default)',
  'ws-loglevel-label-debug': 'Advanced logging',
  'ws-log-settings-title': 'Diagnostic logging',
  'ws-device-id': 'Device ID',
  'ws-log-setting-preference': 'Diagnostic logging',
  'ws-log-preference-title': 'Log level',
  'ws-log-preference-description':
    'Choose a logging preference level based on the amount of data you want to provide in your session logs.',
  'ws-info-loglevel-description':
    'Generates log files that contain diagnostic information about your device, internet connections, and streaming performance metrics.',
  'ws-debug-loglevel-description':
    'Generates log files that contain the same information as standard logging files, with added debugging-level details and verbose performance data. Note: Advanced logging is only supported on DCV Workspaces.',
  'ws-loguploader-description':
    'Enable diagnostic logging to allow the WorkSpaces client to automatically upload WorkSpaces logs. The information in the logs can help troubleshoot issues. These logs will contain information about your device and internet connection.',
  'ws-loguploader-enabled': 'Enabled',
  'ws-loguploader-disabled': 'Disabled',
  'ws-modal-close': 'Close',
  'ws-loguploader-copy': 'Copy',
  'ws-settings': 'Settings',
  'ws-close-settings-button-label': 'Close modal',
  'ws-close-settings-buuton-name': 'Close',
  'ws-save-reg-code': 'Save registration codes',
  'ws-save-reg-code-toggle-description':
    '   Enable or disable WorkSpaces to save your registration codes.',
  'ws-enabled-save': 'Enabled',
  'ws-disabled-save': 'Disabled',
  'ws-rename-description':
    'Rename your WorkSpace. Name will replace registration code as identifier.',
  'ws-custom-name-input-title': 'WorkSpaces Name',
  'ws-custom-name-input-placeholder': 'WorkSpaces name',
  'ws-input-char-limit-description': '20 character maximum',
  'ws-remember-me-settings':
    'Keep me logged in until I quit or my session expires',
  'ws-settings-save-button': 'Save',
  'ws-saved-reg-codes-title': 'Saved registration codes',
  'ws-default-title': '(default)',
  'ws-reg-code-settings-dropdown-label': 'Registration Code Settings',
  'ws-reg-code-delete-name': 'Delete',
  'ws-set-as-default-name': 'Set as default',
  'ws-disable-save-modal-message':
    'By disabling WorkSpaces to save your registration codes all current registration codes will be deleted and no future registration codes will be saved. Are you sure you want to disable save registration codes?',
  'ws-disable-save-modal-title': 'Disable save registration codes',
  'ws-disable-save-modal-aria-label': 'Close disable save modal',
  'ws-disable-alret-message':
    'Enable WorkSpaces to save your registration codes to prevent having to type in the registration code each time you want to sign in to your WorkSpace.',
  'ws-delete-reg-code-modal-text': 'Are you sure you want to delete',
  'ws-delete-reg-code-modal-aria-text': 'Close delete registration code modal',
  'ws-reg-code-input-placeholder': 'Enter registration code',
  'ws-reg-code-alias-error':
    'A WorkSpace with the name already exists. Please enter a different name.',
  'ws-autosuggest-no-mtaches-found': 'No matches found',
  'ws-ok-button': 'OK',
  'ws-delete-button': 'Delete',
  'ws-disable-button': 'Disable',
  'ws-cancel-button': 'Cancel',
  'ws-warning': 'Warning',
  'ws-alert-text-keep-me-logged-in':
    ' "Keep me logged in" will be enabled after your next login',
  'ws-no-reg-code-saved-description':
    'No registration code saved. After you log in with a registration code, it is saved here.',
  'ws-healthcheck-tcp-fail':
    'Connection over TCP Ports failed. Check your settings and try again.',
  'ws-healthcheck-udp-fail':
    'Connection over UDP Ports failed. Check your settings and try again.',
  'ws-healthcheck-tcp-partial':
    'Connection over UDP port {{connectedPort}} established. Could not establish an outbound connection to UDP port {{failedPort}}.',
  'ws-healthcheck-udp-partial':
    'Connection over UDP port {{connectedPort}} established. Could not establish an outbound connection to UDP port {{failedPort}}.',
  'ws-healthcheck-tcp-roundtrip':
    'Round trip time for TCP is {{tcpRoundTripTime}}ms. Your network speed is slow, expect delays.',
  'ws-healthcheck-udp-roundtrip':
    'Round trip time for UDP is {{udpRoundTripTime}}ms. Your network speed is slow, expect delays.',
  'ws-healthcheck-connection-failed':
    'HTTPS connection failed to connect to WorkSpaces service in your region, {{healthcheckRegionName}}. Contact your IT administrator',
  'ws-unsupported-pcoip-for-saml':
    'WorkSpaces PCoIP is not supported for SAML in web client. Download the native WorkSpaces applications and sign in.',
  'ws-unsupported-pcoip-for-idc':
    'WorkSpaces PCoIP is not supported for IDC in web client. Download the native WorkSpaces applications and sign in.',
  'ws-streaming-failed-generic-error':
    'Unexpected error encountered during streaming. Please try again after some time.',
  'ws-warp-drive-load-timeout':
    'Unable to redirect to Authentication portal. Operation failed due a time out. Please retry again after some time and contact your IT administrator if the problem persists',
  'ws-warp-drive-unavailable':
    'Unable to redirect to Authentication portal. Check your network and firewall settings and try again. Contact your IT administrator for the problem persists',
  'ws-warp-drive-unknown-error':
    'Unable to redirect to Authentication portal. Unknown error has occurred. Please retry again after some time and contact your IT administrator if the problem persists',
  'ws-solo-system-browser-error':
    'Unable to launch your web browser to redirect to Authentication portal. Contact your IT administrator to ensure you have a browser installed that is compatible with WorkSpaces URI Access.',
  'ws-registration-page-main-title': 'Getting Started - Amazon WorkSpaces',
  'ws-login-page-main-title': 'Sign in - Amazon WorkSpaces',
  'ws-disconnect-page-main-title': 'Disconnected - Amazon WorkSpaces',
  'ws-name': 'Amazon WorkSpaces',
  'ws-web-maxibon-streaming-failed-auth-denied':
    'Unexpected error encountered during streaming. WorkSpaces streaming connection was closed by server as authorization is denied for current session',
  'ws-web-maxibon-streaming-failed-auth-revoked':
    'Unexpected error encountered during streaming. WorkSpaces streaming connection was closed by server as authorization is no longer valid for current session',
  'ws-web-maxibon-streaming-failed-access-rejected':
    'Unexpected error encountered during streaming. Please try again after some time.',
  'ws-web-maxibon-streaming-failed-idle-timeout':
    'You have been disconnected due to inactivity.',
  'ws-web-maxibon-streaming-failed-owner-disconnect':
    'WorkSpace streaming connection was closed by server.',
  'ws-web-maxibon-streaming-failed-user-disconnect':
    'WorkSpace streaming connection was closed because the WorkSpace is being streamed in another client session.',
  'ws-web-maxibon-streaming-failed-streaming-evicted':
    'WorkSpace streaming connection was closed due to the start of a new connection.',
  'ws-oem-overlay-title': 'Starting...',
  'ws-oem-overlay-description': 'Proceed with the authentication',
  'ws-web-maxibon-streaming-failed-streaming-unsupported-credential':
    'There was a problem authorizing your WorkSpace. Sign in with your corporate credentials',
  'ws-web-maxibon-streaming-failed-streaming-fallback-login-required':
    'There was an unexpected error while starting your Workspace. Sign in with your corporate credentials.',
  'ws-authenticate-invalid-fallback-method':
    'The fallback method is invalid. The attempt to fallback to signing in with your corporate credentials was failed.',
  'ws-error-alert-icon': 'Error alert icon',
  'ws-save-reg-codes-enabled': 'Save registration codes, Enabled',
  'ws-save-reg-code-disabled': 'Save registration codes, Disabled',
  'ws-pool-insufficient-capacity':
    'No streaming resources are available for your session. Try again in a few minutes.',
  'ws-session-cleanup-in-progress':
    'Your previous session has not yet ended. Please choose RETRY after a few minutes to try connecting again.',
  'ws-region-unsupported-by-stack':
    'Registration code used is not supported on this Workspaces Web Client end point. Use production end point to stream production resources.',
  'ws-pcoip-access-denied-description':
    'To continue connecting to this WorkSpace, download the WorkSpaces client application or contact your administrator to update your WorkSpace.',
  'ws-pcoip-access-denied-title':
    'You can no longer connect to this PCoIP WorkSpace using Web Access.',
  'ws-pcoip-access-denied-header': 'Connection method not supported',
};

export default translation;
