import { I18nResource } from '@/i18n/types';

const translation: I18nResource = {
  'ws-welcome': 'Bienvenue sur Amazon WorkSpaces.',
  'ws-options': "Sélectionnez l'une des options ci-dessous :",
  'ws-login': "S'identifier",
  'ws-remember-me': 'Maintenir ma connexion active',
  'ws-new-reg-code': 'Changer de code d’enregistrement',
  'ws-back-login': 'Retourner à la page d’identification',
  'ws-fallback-login': "Connectez-vous avec des informations d'identification",
  'ws-auth-reconnect': 'Se reconnecter',
  'ws-disconnected-error':
    "Une erreur s'est produite. Contactez votre administrateur WorkSpaces pour obtenir de l'aide",
  'ws-get-started':
    "Pour commencer, saisissez le code d'enregistrement qui vous a été fourni par votre administrateur.",
  'ws-register': "S'inscrire",
  'ws-pin': 'Épingler',
  'ws-disconnect': 'Déconnexion',
  'ws-disconnected': 'Déconnecté',
  'ws-send-cad': 'Envoyer CTRL-ALT-SUPPR',
  'ws-enter-full-screen': 'Passer en plein écran',
  'ws-exit-full-screen': 'Quitter le plein écran',
  'ws-error': 'Erreur',
  'ws-console': 'Console WorkSpaces',
  'ws-privacy': 'Confidentialité',
  'ws-site-terms': 'Conditions du site',
  'ws-cookie-pref': 'Préférences relatives aux cookies',
  'ws-docs': 'Documentation',
  'ws-reserved': 'ou de ses sociétés affiliées. Tous droits réservés.',
  'ws-neg-error': "Une erreur s'est produite lors du lancement.",
  'ws-reg-error': "Une erreur s'est produite lors de l'enregistrement.",
  'ws-reg-format-error': "Format du code d'enregistrement non valide.",
  'ws-reg-invalid-error': "Code d'enregistrement non valide.",
  'ws-reg-unsupported-by-platform':
    "Le code d'enregistrement fourni n'est pas pris en charge par cette version du client. Contactez votre administrateur pour plus de détails. ",
  'ws-reg-unknown-error': "Code d'enregistrement inconnu.",
  'ws-unknown-error': 'Erreur inconnue',
  'ws-loading-error':
    "Une erreur s'est produite lors du chargement de la page d'authentification. Assurez-vous que ce système d'exploitation/cette plateforme est activé(e) dans le répertoire contenant les espaces de travail.",
  'ws-get-auth-info-error':
    "Une erreur s'est produite lors du chargement de la page d'authentification en raison d'un code d'enregistrement non valide.",
  'ws-auth-error': "Une erreur s'est produite lors de l'authentification.",
  'ws-auth-type-not-supported-error':
    "Le type d'authentification n'est pas pris en charge.",
  'ws-user-auth-failure':
    'Cela peut être dû à une inactivité prolongée ou à un nouveau mot de passe. Merci de réessayer.',
  'ws-device-auth-failure':
    "Cet appareil n'a pas l’autorisation d’accéder à votre WorkSpace.",
  'ws-get-resources-error':
    "Une erreur s'est produite lors de l'obtention des ressources.",
  'ws-resource-not-found':
    "Aucun WorkSpace n'a été trouvé sous votre nom d'utilisateur. Contactez votre administrateur pour en demander un.",
  'ws-not-supported-error':
    "La connexion Web au WorkSpace sélectionné n'est pas prise en charge.",
  'ws-suspended-error': 'Le WorkSpace sélectionné semble suspendu.',
  'ws-resource-maintenance-error':
    'Votre WorkSpace est en cours de maintenance. Cela peut prendre quelques heures. Contactez votre administrateur pour plus de détails.',
  'ws-resource-terminated-error':
    'Votre WorkSpace a été résilié par votre administrateur. Contactez votre administrateur pour plus de détails.',
  'ws-resource-unavailable-error':
    "Aucun WorkSpace n'a été trouvé sous votre nom d'utilisateur. Contactez votre administrateur pour en demander un.",
  'ws-resource-unhealthy-error':
    'Nous ne parvenons pas à nous connecter à votre WorkSpace pour le moment. Cela peut être dû aux mises à jour actuelles. Réessayez ultérieurement. Si cela se reproduit, contactez le support informatique.',
  'ws-resource-imaging-error':
    "Une erreur s'est produite lors du lancement de votre WorkSpace. Essayez de vous reconnecter dans un instant. Si le problème persiste, contactez votre administrateur pour obtenir de l'aide.",
  'ws-try-again-error': 'Réessayez ultérieurement.',
  'ws-starting-error':
    'Nous ne parvenons pas à nous connecter à votre WorkSpace pour le moment.  Cela peut se produire si votre WorkSpace est en cours de démarrage.',
  'ws-try-again-few-error': 'Réessayez dans quelques minutes.',
  'ws-connecting-error':
    "Une erreur s'est produite lors de la connexion à votre WorkSpace.",
  'ws-service-unavailable':
    "Nous n'avons pas pu lancer votre WorkSpace. Merci de réessayer. Si vous avez besoin d'aide, contactez votre administrateur.",
  'ws-workspace-unavailable':
    "WorkSpace n'est pas disponible pour le moment. Réessayez ultérieurement. Si vous avez besoin d'aide, contactez votre administrateur.",
  'ws-workspace-connection-closed':
    "Connexion au WorkSpace fermée pour cause d'inactivité. Merci de vous reconnecter.",
  'ws-cm-session-timeout': 'La session a expiré.',
  'ws-not-supported-browser-error':
    "Cet appareil ou navigateur ne prend actuellement pas en charge l'accès Web aux WorkSpaces.",
  'ws-wsp-not-supported-error':
    'Le DCV WorkSpace auquel vous vous connectez utilise une version d’agent obsolète. Redémarrez votre WorkSpace ou contactez votre administrateur pour mettre à jour la version du logiciel.',
  'ws-pcoip-unsupported-in-region':
    "L'espace de travail appartient à une région qui ne prend actuellement pas en charge le streaming via le protocole PCoIP sur cette plateforme. Utilisez plutôt le client WorkSpaces sous Windows/macOS/Linux pour streamer. Contactez votre administrateur pour plus de détails",
  'ws-pcoip-unsupported':
    "Le streaming de WorkSpace via PCoIP n'est pas pris en charge sur cette plateforme. Installez ce client sur Ubuntu 20 pour streamer des WorkSpaces via PCoIP",
  'ws-protocol-not-supported-browser-error':
    "Votre navigateur ({{browser}}) n'est pas pris en charge pour le {{protocol}}",
  'ws-protocol-not-supported-browser-version-error':
    'La version de votre {{browser}} ({{version}}) est inférieure à la version la plus ancienne ({{lowestSupportVersion}}) prise en charge pour le {{protocol}}.',
  'ws-try-chrome-error':
    'Réessayez depuis Chrome (version 55 ou ultérieure) ou Firefox (version 48 ou ultérieure) sous Windows, OSX ou Linux.',
  'ws-unhealthy-error': 'Le WorkSpace sélectionné est dans un état défectueux.',
  'ws-try-reboot-error': 'Commencez par essayer de redémarrer votre WorkSpace.',
  'ws-not-auth-device-error':
    "Cet appareil n'a pas l’autorisation d’accéder au WorkSpace.",
  'ws-contact-admin-error':
    'Si le problème persiste, contactez votre administrateur WorkSpaces.',
  'ws-resumed':
    'Votre WorkSpace est en cours de reprise. Il devrait être disponible dans un instant.',
  'ws-not-optimized':
    "Le client Web n'est pas entièrement optimisé pour votre matériel. Pour une meilleure expérience de streaming, utilisez",
  'ws-native': 'les clients natifs des WorkSpaces.',
  'ws-reg-code': "Code d'enregistrement",
  'ws-reg-codes': 'Codes d’enregistrement',
  'ws-os-error':
    "Ce système d'exploitation ou cette plateforme n'a pas l’autorisation d’accéder à votre WorkSpace.",
  'ws-connecting': 'Connexion en cours…',
  'ws-authenticating': 'Authentification en cours…',
  'ws-getting-resource': 'Obtention des ressources en cours...',
  'ws-allocating-resource': 'Allocation des ressources en cours...',
  'ws-sal-text': 'Identifiez-vous pour autoriser l’accès',
  'ws-play-header': 'Identification réussie',
  'ws-play-description':
    'Identification réussie. Continuer vers votre WorkSpace.',
  'ws-play-link': 'Cliquez pour continuer',
  'ws-my-workspace': 'Mon WorkSpace',
  'ws-my-workspace-about': 'Résumé',
  'ws-my-workspace-restart': 'Recommencer',
  'ws-my-workspace-change-compute-type': 'Changer de type de calcul',
  'ws-my-workspace-increase-disk-size': 'Augmenter la taille du disque',
  'ws-my-workspace-switch-running-mode': "Changer de mode d'exécution",
  'ws-my-workspace-rebuild': 'Reconstruire',
  'ws-my-workspace-change-compute-type-title': 'Type de calcul',
  'ws-my-workspace-increase-disk-size-title': 'Taille du disque',
  'ws-my-workspace-switch-running-mode-title': "Mode d'exécution",
  'ws-my-workspace-restart-error-message':
    'Le redémarrage de votre WorkSpace a échoué. Réessayez ultérieurement.',
  'ws-my-workspace-rebuild-error-message':
    'La reconstruction de votre WorkSpace a échoué. Réessayez ultérieurement.',
  'ws-my-workspace-change-compute-type-error-message':
    'Le changement de type de calcul de votre WorkSpace a échoué. Réessayez ultérieurement.',
  'ws-my-workspace-change-compute-type-time-window-error-message':
    "Vous ne pouvez modifier votre type de calcul qu'une seule fois dans un délai de 6 heures pour une augmentation et de 30 jours pour une réduction.",
  'ws-my-workspace-increase-disk-size-error-message':
    'L’augmentation de la taille du volume de votre WorkSpace a échoué. Réessayez ultérieurement.',
  'ws-my-workspace-switch-running-mode-error-message':
    "Le changement de mode d'exécution de votre WorkSpace a échoué. Réessayez ultérieurement.",
  'ws-my-workspace-increase-disk-size-success-message':
    'Vous avez bien augmenté la taille du disque.',
  'ws-my-workspace-switch-running-mode-success-message':
    "Vous avez bien changé de mode d'exécution",
  'ws-my-workspace-restart-disconnect-confirm':
    'Je comprends que le redémarrage du WorkSpace déconnectera les utilisateurs de la session en cours.',
  'ws-my-workspace-rebuild-disconnect-confirm':
    'Je comprends que la reconstruction effacera mes données, les remplacera par les données issues de ma dernière sauvegarde et déconnectera tous les utilisateurs de la session en cours. Cette opération ne peut pas être annulée.',
  'ws-my-workspace-change-compute-type-disconnect-confirm':
    'Je comprends que la modification du type de calcul déconnectera les utilisateurs de la session en cours.',
  'ws-my-workspace-about-registration-code': "Code d'enregistrement",
  'ws-my-workspace-about-region': 'Région',
  'ws-my-workspace-about-workspace-name': 'Nom du WorkSpace',
  'ws-my-workspace-about-id': 'ID',
  'ws-my-workspace-about-compute-type': 'Type de calcul',
  'ws-my-workspace-about-status': 'État',
  'ws-my-workspace-about-workspace-ip-address': 'Adresse IP du WorkSpace',
  'ws-my-workspace-about-protocol': 'Protocole',
  'ws-my-workspace-restart-confirm':
    'Voulez-vous vraiment redémarrer votre WorkSpace ?',
  'ws-my-workspace-restart-confirm-description':
    "Pour éviter toute perte de données, enregistrez tous les documents ouverts et les fichiers d'application avant de redémarrer le WorkSpace.",
  'ws-my-workspace-change-compute-type-current-key': 'Type de calcul actuel',
  'ws-my-workspace-change-compute-type-in-use': "en cours d'utilisation",
  'ws-my-workspace-change-compute-type-description':
    'Remarque : modifier le type de calcul a une incidence sur le coût de votre WorkSpace.',
  'ws-my-workspace-change-compute-type-aria-label':
    'Sélectionnez le type de calcul',
  'ws-my-workspace-increase-disk-size-current-key': 'Taille de disque actuelle',
  'ws-my-workspace-increase-disk-size-description':
    'Remarque : modifier le taille du disque a une incidence sur le coût de votre WorkSpace.',
  'ws-my-workspace-increase-disk-size-info':
    'Augmenter la taille de votre lecteur {{driveToIncrease}} de {{currentVolumeSizeInGib}} GIB à {{newVolumeSizeInGib}} GIB',
  'ws-my-workspace-increase-disk-size-max-reached':
    'Vous avez atteint la taille maximale du disque.',
  'ws-my-workspace-switch-running-mode-current-key': "Mode d'exécution actuel",
  'ws-my-workspace-switch-running-mode-auto-stop': 'Arrêt automatique',
  'ws-my-workspace-switch-running-mode-auto-stop-description':
    "Facturation à l'heure. Le WorkSpace démarre automatiquement lorsque vous vous connectez.",
  'ws-my-workspace-switch-running-mode-always-on': 'Toujours activé',
  'ws-my-workspace-switch-running-mode-always-on-description':
    'Facturation mensuelle. Accès instantané à un WorkSpace qui est en exécution permanente.',
  'ws-my-workspace-switch-running-mode-description':
    "Remarque : modifier le mode d'exécution a une incidence sur le coût de votre WorkSpace.",
  'ws-my-workspace-rebuild-description':
    "Voulez-vous vraiment reconstruire votre WorkSpace ? Nous vous recommandons de commencer par redémarrer votre WorkSpace pour voir si cela résout les problèmes potentiels. La reconstruction de votre WorkSpace permet d'effectuer les opérations suivantes",
  'ws-my-workspace-rebuild-data-lost-warning-label':
    'Remplace le contenu actuel de votre lecteur D: par les données issues de votre dernière sauvegarde.',
  'ws-my-workspace-rebuild-data-lost-warning-content':
    'Vos données actuelles seront perdues.',
  'ws-my-workspace-rebuild-user-drive-recreate-warning-label':
    "La reconstruction de votre WorkSpace peut prendre jusqu'à une heure.",
  'ws-my-workspace-rebuild-user-drive-recreate-warning-content':
    "Vous ne pouvez pas utiliser ou modifier votre WorkSpace tant que la reconstruction n'est pas terminée.",
  'ws-region-name-iad': 'Est des États-Unis (Virginie du Nord)',
  'ws-region-name-pdx': 'Ouest des États-Unis (Oregon)',
  'ws-region-name-dub': 'UE (Irlande)',
  'ws-region-name-syd': 'Asie-Pacifique (Sydney)',
  'ws-region-name-nrt': 'Asie-Pacifique (Tokyo)',
  'ws-region-name-sin': 'Asie-Pacifique (Singapour)',
  'ws-region-name-fra': 'UE (Francfort)',
  'ws-region-name-lhr': 'UE (Londres)',
  'ws-region-name-yul': 'Canada (Centre)',
  'ws-region-name-gru': 'Amérique du Sud (Sao Paulo)',
  'ws-region-name-icn': 'Asie-Pacifique (Séoul)',
  'ws-region-name-cpt': 'Afrique (Le Cap)',
  'ws-region-name-pdt': 'AWS GovCloud (ouest des États-Unis)',
  'ws-region-name-bom': 'Asie-Pacifique (Mumbai)',
  'ws-region-name-osu': 'AWS GovCloud (est des États-Unis)',
  'ws-region-name-tlv': 'Israël (Tel Aviv)',
  'ws-region-name-cdg': 'Europe (Paris)',
  'ws-unauthorized-action-error':
    'Vous ne disposez pas de l’autorisation nécessaire pour effectuer cette action. Contactez votre administrateur.',
  'ws-support': 'Assistance',
  'ws-logo-alt': 'Logo d’Amazon WorkSpaces',
  'ws-disconnect-icon-alt':
    'Une icône représentant un nuage en pointillés pour la déconnexion',
  'ws-menubar-logo-alt': 'Logo d’Amazon WorkSpaces dans la barre de menu',
  'ws-menubar-full-screen-toggle-icon-alt':
    'Icône du mode plein écran dans la barre de menu',
  'ws-menubar-send-cad-icon-alt':
    'Icône pour envoyer CTRL-ALT-SUPPR dans la barre de menu',
  'ws-menubar-disconnect-icon-alt':
    'Icône du bouton de déconnexion dans la barre de menu',
  'ws-menubar-pin-icon-alt': 'Icône pour épingler ou détacher la barre de menu',
  'ws-connect-status-label': 'État du WorkSpace',
  'ws-connect-status-negotiation': 'Initialisation en cours',
  'ws-connect-status-registration': 'Enregistrement des WorkSpaces en cours',
  'ws-connect-status-hello':
    'Récupération en cours des fonctionnalités d’authentification',
  'ws-connect-status-customization':
    'Récupération en cours des informations de marque',
  'ws-connect-status-warp-drive':
    "Redirection vers le portail d'authentification",
  'ws-connect-status-warp-drive-complete':
    "Redirection vers le portail d'authentification terminée",
  'ws-connect-status-saml':
    "Redirection vers un fournisseur d'authentification externe",
  'ws-connect-status-authenticate': 'Authentification en cours',
  'ws-connect-status-get_resource': 'Obtention des ressources en cours',
  'ws-connect-status-allocate-resource': 'Allocation des ressources en cours',
  'ws-connect-status-connect': 'Connexion en cours',
  'ws-connect-status-resume': 'Initialisation de WorkSpaces en cours',
  'ws-connect-status-resume-pending': 'Lancement de WorkSpaces',
  'ws-connect-status-streaming': 'Streaming de la session en cours',
  'ws-connect-status-disconnect': 'Session déconnectée',
  'ws-connect-status-stream-context-received':
    'Génération en cours des exigences pour le streaming...',
  'ws-footer-aws-link-label': "Lien vers la page principale d'AWS",
  'ws-language-label': 'Langue',
  'ws-brand-description': 'Service de bureau entièrement géré à distance',
  'ws-registration-page-title': 'Commencer',
  'ws-login-description': 'Identifiez-vous pour accéder à votre WorkSpace.',
  'ws-solo-saml-login-desc':
    'Nous vous redirigerons vers votre navigateur, puis ici pour terminer le processus de connexion.',
  'ws-progress-bar': 'Une barre de progression du chargement',
  'ws-close-label': 'Fermer le modal',
  'ws-spinner-no-error-message':
    "Aucune erreur n'a été trouvée. Redirection en cours vers la page d'enregistrement",
  'ws-spinner-login-error-message': 'Erreur d’identification.',
  'ws-spinner-redirecting-message': 'Redirection en cours...',
  'ws-spinner-connecting-message': 'Connexion en cours',
  'ws-loglevel-label-info': 'Journalisation standard (par défaut)',
  'ws-loglevel-label-debug': 'Journalisation avancée',
  'ws-log-settings-title': 'Journalisation des diagnostics',
  'ws-device-id': "ID de l'appareil",
  'ws-log-setting-preference': 'Journalisation des diagnostics',
  'ws-log-preference-title': 'Niveau de journalisation',
  'ws-log-preference-description':
    'Choisissez un niveau de préférence de journalisation en fonction de la quantité de données que vous souhaitez fournir dans vos journaux de session.',
  'ws-info-loglevel-description':
    'Génère des fichiers journaux contenant des informations de diagnostic concernant votre appareil, les connexions Internet et les indicateurs de performance de streaming.',
  'ws-debug-loglevel-description':
    'Génère des fichiers journaux qui contiennent les mêmes informations que les fichiers de journalisation standard, avec des détails supplémentaires de débogage et des données de performances détaillées. Remarque : la journalisation avancée n’est prise en charge que sur DCV Workspaces.',
  'ws-loguploader-description':
    'Activez la journalisation des diagnostics pour autoriser le client web WorkSpaces à télécharger automatiquement les journaux WorkSpaces. Les informations contenues dans les journaux peuvent aider à résoudre les problèmes. Ces journaux contiendront des informations sur votre appareil et votre connexion Internet.',
  'ws-loguploader-enabled': 'Activé(e)',
  'ws-loguploader-disabled': 'Désactivé',
  'ws-modal-close': 'Fermer',
  'ws-loguploader-copy': 'Copier',
  'ws-settings': 'Paramètres',
  'ws-close-settings-button-label': 'Fermer le modal',
  'ws-close-settings-buuton-name': 'Fermer',
  'ws-save-reg-code': "Sauvegarder les codes d'enregistrement",
  'ws-save-reg-code-toggle-description':
    "   Activez ou désactivez WorkSpaces pour sauvegarder vos codes d'enregistrement.",
  'ws-enabled-save': 'Activé(e)',
  'ws-disabled-save': 'Désactivé',
  'ws-rename-description':
    'Renommez votre WorkSpace. Le nom remplacera le code dʼenregistrement en tant quʼidentifiant.',
  'ws-custom-name-input-title': 'Nom des WorkSpaces',
  'ws-custom-name-input-placeholder': 'Nom des WorkSpaces',
  'ws-input-char-limit-description': '20 caractères maximum',
  'ws-remember-me-settings':
    'Rester connecté jusquʼà ce que je me déconnecte ou que ma session expire',
  'ws-settings-save-button': 'Enregistrer',
  'ws-saved-reg-codes-title': "Codes d'enregistrement sauvegardés",
  'ws-default-title': '(par défaut)',
  'ws-reg-code-settings-dropdown-label': "Paramètres du code d'enregistrement",
  'ws-reg-code-delete-name': 'Supprimer',
  'ws-set-as-default-name': 'Définir par défaut',
  'ws-disable-save-modal-message':
    "En désactivant la sauvegarde de vos codes d'enregistrement dans WorkSpaces, tous les codes d'enregistrement actuels seront supprimés et aucun code d'enregistrement futur ne sera sauvegardé. Voulez-vous vraiment désactiver la sauvegarde des codes d'enregistrement ?",
  'ws-disable-save-modal-title':
    "Désactiver la sauvegarde des codes d'enregistrement",
  'ws-disable-save-modal-aria-label':
    'Fermer et désactiver le modal de sauvegarde',
  'ws-disable-alret-message':
    "Autorisez WorkSpaces à sauvegarder vos codes d'enregistrement afin d'éviter d'avoir à les saisir chaque fois que vous souhaitez vous connecter à votre espace de travail.",
  'ws-delete-reg-code-modal-text': 'Voulez-vous vraiment supprimer',
  'ws-delete-reg-code-modal-aria-text':
    "Fermer et supprimer le modal de code d'enregistrement",
  'ws-reg-code-input-placeholder': "Saisissez le code d'enregistrement",
  'ws-reg-code-alias-error':
    'Un WorkSpace portant ce nom existe déjà. Saisissez un autre nom.',
  'ws-autosuggest-no-mtaches-found': 'Aucune correspondance trouvée',
  'ws-ok-button': 'OK',
  'ws-delete-button': 'Supprimer',
  'ws-disable-button': 'Désactiver',
  'ws-cancel-button': 'Annuler',
  'ws-warning': 'Avertissement',
  'ws-alert-text-keep-me-logged-in':
    ' « Rester connecté » sera activé lors de votre prochaine connexion',
  'ws-no-reg-code-saved-description':
    "Aucun code d'enregistrement enregistré. Lorsque vous vous connectez avec un code d'enregistrement, celui-ci est sauvegardé ici.",
  'ws-healthcheck-tcp-fail':
    'La connexion via les ports TCP a échoué. Vérifiez vos paramètres et réessayez.',
  'ws-healthcheck-udp-fail':
    'La connexion via les ports UDP a échoué. Vérifiez vos paramètres et réessayez.',
  'ws-healthcheck-tcp-partial':
    'Connexion via le port UDP {{connectedPort}} établie. Impossible d’établir une connexion sortante vers le port UDP {{failedPort}}',
  'ws-healthcheck-udp-partial':
    'Connexion via le port UDP {{connectedPort}} établie. Impossible d’établir une connexion sortante vers le port UDP {{failedPort}}',
  'ws-healthcheck-tcp-roundtrip':
    'Le temps de trajet aller-retour pour TCP est de {{tcpRoundTripTime}} ms. Votre réseau est lent, attendez-vous à des retards.',
  'ws-healthcheck-udp-roundtrip':
    'Le temps de trajet aller-retour pour UDP est de {{udpRoundTripTime}} ms. Votre réseau est lent, attendez-vous à des retards.',
  'ws-healthcheck-connection-failed':
    'Échec de la connexion HTTPS au service WorkSpaces dans votre région, {{healthcheckRegionName}}. Contactez votre administrateur informatique',
  'ws-unsupported-pcoip-for-saml':
    "PCoIP pour WorkSpaces n'est pas pris en charge pour SAML dans le client Web. Téléchargez les applications WorkSpaces natives et connectez-vous.",
  'ws-unsupported-pcoip-for-idc':
    "WorkSpaces PCoIP n'est pas pris en charge pour IDC dans le client Web. Téléchargez les applications WorkSpaces natives et identifiez-vous.",
  'ws-streaming-failed-generic-error':
    "Une erreur inattendue s'est produite pendant la diffusion en streaming. Veuillez réessayer ultérieurement.",
  'ws-warp-drive-load-timeout':
    "Impossible de rediriger vers le portail d'authentification. L'opération a échoué en raison d'un délai d'attente. Réessayez après un certain temps et contactez votre administrateur informatique si le problème persiste",
  'ws-warp-drive-unavailable':
    "Impossible de rediriger vers le portail d'authentification. Vérifiez les paramètres de votre réseau et de votre pare-feu, puis réessayez. Contactez votre administrateur informatique car le problème persiste",
  'ws-warp-drive-unknown-error':
    "Impossible de rediriger vers le portail d'authentification. Une erreur inconnue s'est produite. Réessayez après un certain temps et contactez votre administrateur informatique si le problème persiste",
  'ws-solo-system-browser-error':
    "Impossible de lancer votre navigateur Web pour vous rediriger vers le portail d'authentification. Contactez votre administrateur informatique pour vous assurer que votre navigateur est compatible avec WorkSpaces URI Access.",
  'ws-registration-page-main-title': 'Commencer – Amazon WorkSpaces',
  'ws-login-page-main-title': 'S’identifier - Amazon WorkSpaces',
  'ws-disconnect-page-main-title': 'Déconnecté - Amazon WorkSpaces',
  'ws-name': 'Amazon WorkSpaces',
  'ws-web-maxibon-streaming-failed-auth-denied':
    "Une erreur inattendue s'est produite pendant le streaming. La connexion WorkSpaces en streaming a été interrompue par le serveur car l'autorisation est refusée pour la session en cours",
  'ws-web-maxibon-streaming-failed-auth-revoked':
    "Une erreur inattendue s'est produite pendant la diffusion en streaming. La connexion WorkSpaces en streaming a été fermée par le serveur car l'autorisation n'est plus valide pour la session en cours",
  'ws-web-maxibon-streaming-failed-access-rejected':
    "Une erreur inattendue s'est produite pendant la diffusion en streaming. Veuillez réessayer ultérieurement.",
  'ws-web-maxibon-streaming-failed-idle-timeout':
    "Vous avez été déconnecté pour cause d'inactivité.",
  'ws-web-maxibon-streaming-failed-owner-disconnect':
    'La connexion WorkSpace en streaming a été interrompue par le serveur.',
  'ws-web-maxibon-streaming-failed-user-disconnect':
    'La connexion WorkSpace en streaming a été fermée car le WorkSpace est diffusé dans une autre session client.',
  'ws-web-maxibon-streaming-failed-streaming-evicted':
    "La connexion WorkSpace en streaming a été interrompue en raison du début d'une nouvelle connexion.",
  'ws-oem-overlay-title': 'À partir du...',
  'ws-oem-overlay-description': 'Procéder à l’authentification',
  'ws-web-maxibon-streaming-failed-streaming-unsupported-credential':
    "Un problème s'est produit lors de l'autorisation de votre WorkSpace. Connectez-vous avec vos identifiants d'entreprise",
  'ws-web-maxibon-streaming-failed-streaming-fallback-login-required':
    "Une erreur inattendue s'est produite lors du démarrage de votre Workspace. Connectez-vous avec vos identifiants d'entreprise.",
  'ws-authenticate-invalid-fallback-method':
    "La méthode secondaire n'est pas valide. La tentative de connexion secondaire avec les informations d'identification de votre entreprise a échoué.",
  'ws-error-alert-icon': "Icône d'alerte d'erreur",
  'ws-save-reg-codes-enabled': "Enregistrement des codes d'inscription, activé",
  'ws-save-reg-code-disabled':
    "Enregistrement des codes d'inscription, désactivé",
  'ws-pool-insufficient-capacity':
    'Aucune ressource de streaming n’est disponible pour votre session. Merci de réessayer dans quelques minutes.',
  'ws-session-cleanup-in-progress':
    'Votre session précédente n’est pas encore terminée. Choisissez RÉESSAYER après quelques minutes pour essayer à nouveau de vous connecter.',
  'ws-region-unsupported-by-stack':
    "Le code d'enregistrement utilisé n'est pas pris en charge sur ce point de terminaison Workspaces Web Client. Utilisez le point de terminaison de production pour diffuser les ressources de production.",
  'ws-pcoip-access-denied-description':
    "Pour continuer à vous connecter à ce WorkSpace, téléchargez l'application cliente WorkSpaces ou contactez votre administrateur pour mettre à jour votre WorkSpace.",
  'ws-pcoip-access-denied-title':
    'Vous ne pouvez plus vous connecter à cet espace de travail PCoIP via l’accès web.',
  'ws-pcoip-access-denied-header': 'Connection method not supported',
};

export default translation;
