import { HttpClientConfig, HttpRequestHeader } from '@core/http/HttpClient';
import {
  AllocateResourceToClientErrorCodeMap,
  AuthenticateExceptionToClientErrorCodeMap,
  GetAuthInfoExceptionToClientErrorCodeMap,
  PerformResourceActionToClientErrorCodeMap,
  WsBrokerAPI,
  WSBrokerServiceConstants,
  WSBrokerSessionProtocols,
} from './Constants';
import {
  ClientCapability,
  ClientInfoPlatform,
  IAllocateResourceRequest,
  IAuthInfoRequest,
  IGetHeartBeatInfoRequest,
  IGetResourceRequest,
  IKeyValuePair,
  GetAuthInfoRequestBodyProps,
} from '@/core/wsbroker/types';
import { WsError, WsErrorCodes, WsErrorTypes } from '@/bridge/WsError';
import {
  AuthCapability,
  AuthStrategy,
  AuthType,
  PlatformType,
} from '@bridge/types/SoloRTCChannelTypes';
import { ClientErrorCode } from '@bridge/types/ErrorTypes';
import { generateUUID } from '@/bridge/utility';
import { IRegion } from '@bridge/types/RegionTypes';
import { HttpsSettings } from '@bridge/types/SessionTypes';
import {
  WorkSpaceState,
  WorkSpaceStateMap,
  WsBrokerOperationState,
} from '@bridge/types/WorkSpaceStateTypes';

export class ServiceUtility {
  static getWsBrokerHttpHeaders(apiName: string): HttpRequestHeader {
    const requestHeaders: HttpRequestHeader = {
      ...WSBrokerServiceConstants.WS_BROKER_SERVICE_API_HEADERS_TEMPLATE,
    };
    requestHeaders['X-Amz-Target'] =
      WSBrokerServiceConstants.WS_BROKER_API_TARGET_PREFIX + apiName;
    return requestHeaders;
  }

  static getWsBrokerHttpConfig(
    region: IRegion,
    requestHeaders: HttpRequestHeader,
    httpsSettings: HttpsSettings
  ): HttpClientConfig {
    const endPointType = httpsSettings.brokerEndpointType;
    const brokerEndpoint = region.wsBrokerEndpoint.find(
      (ep) => ep.type === endPointType
    );

    return {
      baseURL: brokerEndpoint?.url,
      headers: requestHeaders,
    };
  }

  static getResourcesRequestBody(authToken: string, sessionId: string) {
    const requestBody: IGetResourceRequest = {
      ...WSBrokerServiceConstants.WS_BROKER_GET_RESOURCES_API_REQUEST_BODY_TEMPLATE,
    };
    requestBody.SessionContext.SessionId = sessionId;
    requestBody.AuthToken.Value = authToken;
    requestBody.SessionContext.AuthToken.Value = authToken;
    return requestBody;
  }

  static getHeartBeatInfoRequestBody(authToken: string, sessionId: string) {
    const requestBody: IGetHeartBeatInfoRequest = {
      ...WSBrokerServiceConstants.WS_BROKER_GET_HEARTBEAT_INFO_API_REQUEST_BODY_TEMPLATE,
    };
    requestBody.SessionId = sessionId;
    requestBody.AuthToken.Value = authToken;

    return requestBody;
  }

  static getAuthInfoRequestBody({
    regCode,
    isConnectionAlias,
    clientVersion,
    clientName,
    platformType,
    authCapabilities,
    vendorName,
    modelNumber,
    deviceUUID,
    codeChallenge,
  }: GetAuthInfoRequestBodyProps): IAuthInfoRequest {
    const template =
      WSBrokerServiceConstants.WS_BROKER_SERVICE_GET_AUTH_INFO_REQ_BODY_TEMPLATE;
    let authIdentifiers;
    if (isConnectionAlias) {
      authIdentifiers = { WS_CONNECTION_ALIAS: [regCode] };
    } else {
      authIdentifiers = { WS_REGISTRATION_CODE: [regCode] };
    }

    let body = {
      ...template,
      AuthIdentifiers: {
        ...authIdentifiers,
      },
      ClientInfo: {
        ...template.ClientInfo,
        Version: clientVersion,
        Name: clientName,
        Platform: ServiceUtility.platformTypeToWSBrokerInput(platformType),
        AuthCapabilities:
          ServiceUtility.authCapabilitiesToWSBrokerInput(authCapabilities),
        Identifier: generateUUID(),
        PKCECodeChallenge: codeChallenge,
      },
    };
    if (vendorName ?? modelNumber ?? deviceUUID) {
      body = {
        ...body,
        AuthIdentifiers: {
          ...body.AuthIdentifiers,
        },
        ClientInfo: {
          ...body.ClientInfo,
          VendorName: vendorName,
          ModelNumber: modelNumber,
          DeviceUUID: deviceUUID,
        },
      };
    }
    return body;
  }

  // Based on PlatformType & Protocol WSBroker expects different inputs for protocol & version
  static getAllocateResourceRequestBody(
    sessionId: string,
    authToken: string,
    resourceId: string,
    protocol: string,
    platformType: string,
    deviceUUID: string | undefined
  ) {
    const requestBody: IAllocateResourceRequest = {
      ...WSBrokerServiceConstants.WS_BROKER_ALLOCATE_RESOURCE_API_REQUEST_BODY_TEMPLATE,
    };
    requestBody.SessionContext.SessionId = sessionId;
    requestBody.SessionContext.AuthToken.Value = authToken;
    requestBody.AuthToken.Value = authToken;
    requestBody.Resource.ResourceId = resourceId;
    let clientVersion;

    // ToDo: This can be removed and SoloNativeRTCChannel.isChannelAvailable() can be used. But keeping change simple
    // for now to reduce risk before deployment.
    const browserPlatformType =
      platformType === PlatformType.WEB ||
      platformType === PlatformType.THINCLIENT;

    if (browserPlatformType && protocol === WSBrokerSessionProtocols.WSP) {
      const wspMetadataItem: IKeyValuePair = {
        Key: 'ClientDeviceId',
        Value: deviceUUID ?? '',
      };
      requestBody.WSPClientMetadata = [wspMetadataItem];

      protocol = WSBrokerSessionProtocols.WSP_WEB;
      clientVersion =
        WSBrokerServiceConstants.WS_BROKER_ALLOCATE_RESOURCE_API_WSP_PROTOCOL_VERSION;
    } else if (protocol === WSBrokerSessionProtocols.WSP) {
      protocol = 'HIGHLANDER';
      clientVersion =
        WSBrokerServiceConstants.WS_BROKER_ALLOCATE_RESOURCE_API_WSP_PROTOCOL_VERSION;
      const wspMetadataItem: IKeyValuePair = {
        Key: 'ClientDeviceId',
        Value: deviceUUID ?? '',
      };
      requestBody.WSPClientMetadata = [wspMetadataItem];
    }
    requestBody.Action.ActionUserInput.Protocol = protocol;
    clientVersion &&
      (requestBody.Action.ActionClientInput.ClientVersion = clientVersion);

    return requestBody;
  }

  static transformServiceError(error: any, api: WsBrokerAPI) {
    const serverErrorCode = error?.response?.data?.code;
    const serverErrorMessage = error?.response?.data?.message;
    const errorType =
      WSBrokerServiceConstants.API_TO_WS_ERROR_TYPE.get(api) ??
      WsErrorTypes.ERROR_TYPE_UNKNOWN;
    const errorCode =
      WSBrokerServiceConstants.SERVER_ERROR_TO_WS_ERROR_CODE.get(
        serverErrorCode
      ) ?? WsErrorCodes.ERROR_UNKNOWN;
    let clientErrorCode;

    switch (api) {
      case WsBrokerAPI.GetAuthInfo:
        clientErrorCode =
          GetAuthInfoExceptionToClientErrorCodeMap.get(serverErrorCode) ??
          ClientErrorCode.AuthInfoUnknown;
        break;
      case WsBrokerAPI.Authenticate:
        clientErrorCode =
          AuthenticateExceptionToClientErrorCodeMap.get(serverErrorCode) ??
          ClientErrorCode.AuthUnknown;
        break;
      case WsBrokerAPI.GetResources:
        clientErrorCode = ClientErrorCode.GetResourceServerFailure;
        break;
      case WsBrokerAPI.AllocateResource:
        clientErrorCode =
          AllocateResourceToClientErrorCodeMap.get(serverErrorCode) ??
          ClientErrorCode.AllocateResourceUnknown;
        break;
      case WsBrokerAPI.PerformResourceAction:
        clientErrorCode =
          PerformResourceActionToClientErrorCodeMap.get(serverErrorCode) ??
          ClientErrorCode.PerformResourceActionServerFailure;
        break;
      default:
        clientErrorCode = ClientErrorCode.PresessionGenericError;
    }
    const wsError = new WsError(
      errorType,
      errorCode,
      clientErrorCode,
      serverErrorMessage
    );
    wsError.setInnerException(error);
    return wsError;
  }

  static handleError(error: any): WsError {
    if (error instanceof WsError) {
      return error;
    } else {
      return WsError.getUnknownError();
    }
  }

  static platformTypeToWSBrokerInput(platformType: PlatformType): string {
    switch (platformType) {
      case PlatformType.LINUX:
        return ClientInfoPlatform.Linux;
      case PlatformType.WINDOWS:
        return ClientInfoPlatform.Windows;
      case PlatformType.IOS:
        return ClientInfoPlatform.iOS;
      case PlatformType.MACOS:
        return ClientInfoPlatform.OSX;
      case PlatformType.WEB:
        return ClientInfoPlatform.Web;
      case PlatformType.THINCLIENT:
        return ClientInfoPlatform.ThinClient;
    }
  }

  static authCapabilitiesToWSBrokerInput(
    authCapabilities: AuthCapability[]
  ): string[] {
    const clientCapabilities: string[] = [];
    authCapabilities?.forEach((capability) => {
      if (
        capability.AuthType === AuthType.WARP_DRIVE &&
        capability.AuthStrategy === AuthStrategy.CREDENTIAL
      ) {
        clientCapabilities.push(ClientCapability.TOKEN);
      } else if (
        capability.AuthType === AuthType.WARP_DRIVE &&
        capability.AuthStrategy === AuthStrategy.MFA
      ) {
        clientCapabilities.push(ClientCapability.OAUTH);
      } else if (
        capability.AuthType === AuthType.WARP_DRIVE &&
        capability.AuthStrategy === AuthStrategy.CREDENTIAL_BROKER_PROTOCOL_V2
      ) {
        clientCapabilities.push(ClientCapability.BROKER_PROTOCOL_V2);
      } else if (
        capability.AuthType === AuthType.DEVICE_AUTH &&
        capability.AuthStrategy === AuthStrategy.CERTIFICATE
      ) {
        clientCapabilities.push(ClientCapability.DEVICE_VIA_CERTIFICATE);
      } else if (
        capability.AuthType === AuthType.SAML &&
        capability.AuthStrategy === AuthStrategy.SAML
      ) {
        clientCapabilities.push(ClientCapability.SAML_IAM_AUTH_PROVIDER);
      } else if (
        capability.AuthType === AuthType.IDC &&
        capability.AuthStrategy === AuthStrategy.IDC
      ) {
        clientCapabilities.push(ClientCapability.IDC_AUTH_PROVIDER);
        clientCapabilities.push(ClientCapability.LOGIN_TYPE_FIXED_AUTHBLOB);
      } else if (
        capability.AuthType === AuthType.SAML &&
        capability.AuthStrategy === AuthStrategy.SEAMLESS
      ) {
        clientCapabilities.push(
          ClientCapability.VIRTUAL_SMART_CARD_CERTIFICATE
        );
      }
    });
    return clientCapabilities;
  }

  static mapWorkSpaceState(
    wsBrokerOperationState?: WsBrokerOperationState
  ): WorkSpaceState {
    return wsBrokerOperationState
      ? WorkSpaceStateMap[wsBrokerOperationState]
      : WorkSpaceState.WORKSPACE_STATE_UNKNOWN;
  }
}
